import { SharedIconModule } from './shared-icon/shared-icon.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedGlobalModule } from '@front/app/modules/shared-global/shared-global.module';

import { ClickOutsideModule } from 'ng-click-outside';

import { InputComponent } from './input/generic-input/generic-input.component';
import { ToggleComponent } from './toggle/generic-toggle/generic-toggle.component';
import { ToggleSwitchComponent } from './toggle/toggle-switch/generic-toggle-switch.component';
import { ToggleSwitchBoxComponent } from './toggle/toggle-switch-box/generic-toggle-switch-box.component';
import { EkimetricsLogoComponent } from './logo/logo.component';
import { TextareaComponent } from './textarea/generic-textarea/generic-textarea.component';
import { FileUploadComponent } from './file-upload/button/generic-file-upload.component';
import { ZoneFileUploadComponent } from './file-upload/zone/zone-file-upload.component';
import { SingleDatePickerComponent } from './date-picker/single-date-picker/single-date-picker.component';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { InputNumberComponent, InputTextComponent } from '@front/app/modules/shared/input/specific-inputs/input.components';
import { NumberInputComponent } from './input/number-input/generic-number-input.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { RadioComponent } from './radio/generic-radio.component';
import { PerfFiltersComponent } from './perf-filters/perf-filters.component';
import { TagsComponent } from './tags/tags.component';

import { GridContainerDirective, GridItemDirective } from './grid/grid-layout.directives';

// Pipes
import { LoaderModule } from '@front/app/modules/shared/loader/loader.module';
import { TreeItemModule } from '@front/app/modules/shared/tree-item/tree-item.module';
import { NotificationModule } from './notification/notification.module';
import { IntervalDatePickerModule } from './date-picker/interval-date-picker/interval-date-picker.module';
import { IconDirectiveModule } from '@front/app/modules/shared-global/directives/icon/icon.directive.module';
import { SelectModule } from './select/generic-select/generic-select.module';
import { ProgressComponent } from './progress/progress.component';
import { PictureNameModule } from './picture-name/picture-name.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { AggregationStructureModalComponent } from './aggregation-structure-modal/aggregation-structure-modal.component';
import { AggregationStructureComponent } from '~/app/modules/home/components/aggregation-structure/aggregation-structure.component';
import { CrossSectionFiltersModule } from '~/app/modules/shared/cross-section-filters/cross-section-filters.module';
import { MultiSelectDropdownComponent } from './select/multi-select-dropdown/multi-select-dropdown.component';
import { PipesModule } from '~/app/pipes/pipes.module';
import { HeadwayWidgetComponent } from '@front/app/modules/shared/headway-widget/headway-widget.component';
import { ControlAccessorDirectiveModule, CoreAngularModule } from '@eki-ui-kit/core-angular';
import { TallyWidgetComponent } from './tally-widget/tally-widget.component';

const MODULES = [
  CoreAngularModule,
  ControlAccessorDirectiveModule,
  CommonModule,
  SharedGlobalModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ClickOutsideModule,
  NgbDatepickerModule,
  NgbTooltipModule,
  LoaderModule,
  TreeItemModule,
  ColorTwitterModule,
  SharedIconModule,
  NotificationModule,
  IntervalDatePickerModule,
  IconDirectiveModule,
  SelectModule,
  PictureNameModule,
  CrossSectionFiltersModule,
  PipesModule
];

const COMPONENTS = [
  InputComponent,
  EkimetricsLogoComponent,
  ToggleComponent,
  ToggleSwitchComponent,
  ToggleSwitchBoxComponent,
  TextareaComponent,
  FileUploadComponent,
  InputTextComponent,
  InputNumberComponent,
  GridContainerDirective,
  GridItemDirective,
  SingleDatePickerComponent,
  NumberInputComponent,
  SearchBarComponent,
  MultiSelectDropdownComponent,
  PaginatorComponent,
  ColorPickerComponent,
  RadioComponent,
  PerfFiltersComponent,
  ProgressComponent,
  TagsComponent,
  ZoneFileUploadComponent,
  AggregationStructureModalComponent,
  AggregationStructureComponent,
  BreadcrumbsComponent,
  HeadwayWidgetComponent,
  TallyWidgetComponent
];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class SharedModule {}
