import { Component, ChangeDetectionStrategy, Input, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { IAggregationStructure } from '@shared';
import { BreadcrumbService, IBreadcrumbPath } from '@front/services/breadcrumb.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AuthService } from '@front/services/auth.service';
import { NotificationService } from '@front/app/modules/shared/notification/notification.service';
import { AggregationStructureService } from '@front/services/api/aggregation-structure.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { fadeInAnimation } from '@front/app/modules/shared/animations/fade-in.animation';
import { fadeOutAnimation } from '@front/app/modules/shared/animations/fade-out.animation';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation('150ms'), fadeOutAnimation('250ms')]
})
export class BreadcrumbsComponent implements OnDestroy {
  private subs = new SubSink();

  @Input() currentUrl = '';
  @Input() clientConfig?: IClientConfig;

  aggregationRoots$: Observable<IAggregationStructure[] | null>;
  pathSubject: BehaviorSubject<IBreadcrumbPath[] | null> = new BehaviorSubject<IBreadcrumbPath[] | null>(null);
  path$: Observable<IBreadcrumbPath[] | null>;
  path: IBreadcrumbPath[] | null = null;
  showRoots = false;

  constructor(
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private aggregationStructureService: AggregationStructureService,
    public breadcrumbService: BreadcrumbService,
    private dropdownRef: ElementRef
  ) {
    this.aggregationRoots$ = this.authService.user$.pipe(
      filter((user) => !!user),
      tap(() => {
        this.notificationService.launchNotificationAutomaticPooler();
      }),
      switchMap(() => this.aggregationStructureService.getRootAggregationStructures())
    );

    this.path$ = this.breadcrumbService.path$.pipe(
      switchMap((path) => {
        this.pathSubject.next(path);
        return this.pathSubject.asObservable();
      }),
      tap((path) => {
        this.path = path;
      })
    );
  }

  onSelectBreadcrumb(breadcrumb?: IBreadcrumbPath, event?: Event) {
    event?.stopPropagation();
    if (this.path && this.dropdownRef.nativeElement.contains(event?.target)) {
      this.pathSubject.next(
        this.path.map((path) => ({
          ...path,
          dropdown: path.id !== breadcrumb?.id ? false : !breadcrumb.dropdown
        }))
      );
    }
    if (breadcrumb) {
      this.showRoots = false;
    } else {
      this.showRoots = !this.showRoots;
    }
  }

  getBreadcrumbsPath(aggStructId: string) {
    if (this.clientConfig?.moduleAccess.promo) {
      return `promo/post-eval/${aggStructId}`;
    }
    return `performance/${aggStructId}`;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.dropdownRef.nativeElement.contains(event.target)) {
      this.showRoots = false;
      if (this.path) {
        this.pathSubject.next(
          this.path.map((path) => ({
            ...path,
            dropdown: false
          }))
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.notificationService.stopNotificationAutomaticPooler();
    this.subs.unsubscribe();
  }
}
