import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @Input() placeholder = '';

  public disabled = false;
  public focused = false;
  public searchValue = '';
  private lastSearch = '';

  @Output() onSearch: EventEmitter<string> = new EventEmitter();

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
  }

  public search(): void {
    if (this.searchValue !== this.lastSearch) {
      this.lastSearch = this.searchValue;
      this.onSearch.emit(this.searchValue.trim().toLowerCase());
    }
  }
}
