import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VarDirective } from './ng-var.directive';

const MODULES = [CommonModule, RouterModule, FormsModule];

const COMPONENTS = [VarDirective];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class NgVarDirectiveModule {}
