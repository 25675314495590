import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IntervalDatePickerComponent } from './interval-date-picker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedIconModule } from '../../shared-icon/shared-icon.module';

const MODULES = [CommonModule, RouterModule, FormsModule, NgbDatepickerModule, SharedIconModule];

const COMPONENTS = [IntervalDatePickerComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class IntervalDatePickerModule {}
