import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const promoDemoConfig: IClientConfig = {
  ...defaultConfig,
  platformName: 'Promo Platform',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: false,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: false,
    channelPerformance: false,
    scenarios: false,
    promo: {
      postEval: true,
      preEval: true,
      efficiencyLevers: true,
      optimization: true
    }
  },
  sso: {
    azure: true
  },
  completeConfigurationPage: false
};
