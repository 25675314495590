export enum Roles {
  appManager = 'app manager',
  admin = 'admin',
  consultant = 'consultant',
  client = 'client'
}

export function getRoleIcon(role: string): string {
  switch (role) {
    case Roles.appManager:
      return 'tenancy';
    case Roles.admin:
      return 'admin_panel_settings';
    case Roles.consultant:
      return 'manage-accounts';
    default:
      return 'person';
  }
}
