import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const nestlePromoConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Nestlé',
  logo: 'assets/images/logo-nestle.svg',
  platformName: 'Promo Platform',
  platformLogo: 'assets/images/ap_platform_nestle_logo.svg',
  plateformHelpImage: 'assets/images/glossary-nestle-promo.png',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: false,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: false,
    channelPerformance: false,
    scenarios: false,
    promo: {
      postEval: true,
      preEval: true,
      efficiencyLevers: true,
      optimization: true
    }
  },
  completeConfigurationPage: false,
  userManualUrl:
    'https://nestle.sharepoint.com/teams/DNAFrance-DataandAnalytics-AandPcockpit/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FDNAFrance%2DDataandAnalytics%2DAandPcockpit%2FShared%20Documents%2FA%20and%20P%20cockpit%2F10%20Pilote%20Caf%C3%A9s%2DChocolats%2F100%20Platform%20Adoption%2F2%2E%20PROMO%2FRelease%20RME%20Choco%20V2%200722%2FEkimetrics%20x%20Nestl%C3%A9%20%2D%20User%20Guide%20Promo%20Module%20%2D%20v0112%2Epdf&parent=%2Fteams%2FDNAFrance%2DDataandAnalytics%2DAandPcockpit%2FShared%20Documents%2FA%20and%20P%20cockpit%2F10%20Pilote%20Caf%C3%A9s%2DChocolats%2F100%20Platform%20Adoption%2F2%2E%20PROMO%2FRelease%20RME%20Choco%20V2%200722&p=true&ga=1'
};
