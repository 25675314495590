import { IClientConfig } from '@front/interfaces/client-config.interface';
import { gskConfig } from './gsk.config';

export const ppdGskConfig: IClientConfig = {
  ...gskConfig,
  moduleAccess: {
    channelPerformance: true,
    investment: true,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    scenarios: true
  }
};
