import { Injectable } from '@angular/core';
import { ApplicationModules } from '@shared';
import { AppStateService } from '@front/services/state/app-state.service';
import { AuthService } from '@front/services/auth.service';
import { IApplicationPermissionsModule } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class ApplicationPermissionsService {
  constructor(private appStateService: AppStateService, private readonly authService: AuthService) {}

  isPermitted(moduleName: ApplicationModules | string): boolean {
    const currentConfig = this.appStateService.getConfigs();
    const currentUser = this.authService.user$$.getValue();
    const applicationPermissions = currentConfig?.applicationPermissions ?? [];
    const module = this.findModule(applicationPermissions, moduleName);
    return !module || !currentUser || module.permittedRoles.includes(currentUser.role);
  }

  findModule(modules: IApplicationPermissionsModule[], moduleName?: string): IApplicationPermissionsModule | null {
    let found: IApplicationPermissionsModule | null = null;
    modules.some((m) => (found = m.name === moduleName ? m : this.findModule(m.children || [], moduleName)));
    return found;
  }
}
