export enum MechanicSMP {
  'ri 8%' = 'ri 8%',
  'ri 10%' = 'ri 10%',
  'ri 15%' = 'ri 15%',
  'ri 20%' = 'ri 20%',
  'ri 24%' = 'ri 24%',
  'ri 25%' = 'ri 25%',
  'ri 30%' = 'ri 30%',
  'ri 33%' = 'ri 33%',
  'ri 34%' = 'ri 34%',
  'ri 35%' = 'ri 35%',
  'ri 40%' = 'ri 40%',
  'ri 50%' = 'ri 50%',
  'ri 68%' = 'ri 68%',
  'ri 100%' = 'ri 100%',
  '2 + 1' = '2 + 1',
  '4 + 2' = '4 + 2',
  '1 + 1' = '1 + 1',
  '3 + 1' = '3 + 1',
  'lv 2e -25%' = 'lv 2e -25%',
  'lv 2e -40%' = 'lv 2e -40%',
  'lv 2e -50%' = 'lv 2e -50%',
  'lv 2e -60%' = 'lv 2e -60%',
  'lv 2e -66%' = 'lv 2e -66%',
  'lv 2e -68%' = 'lv 2e -68%',
  'lv 2e -70%' = 'lv 2e -70%',
  'carte 20%' = 'carte 20%',
  'carte 25%' = 'carte 25%',
  'carte 30%' = 'carte 30%',
  'carte 34%' = 'carte 34%',
  'carte 35%' = 'carte 35%',
  'carte 40%' = 'carte 40%',
  'carte 50%' = 'carte 50%',
  'autre' = 'autre',
  'gratuité' = 'gratuité'
}
