export enum MMOModelStatus {
  draft = 'draft',
  complete = 'complete',
  ongoing = 'ongoing',
  queued = 'queued',
  updateOngoing = 'update ongoing',
  error = 'error',
  archived = 'archived'
}

export enum MMOModelTotalType {
  sum = 'sum',
  mean = 'mean'
}

export enum MMOModelFrequencyGranularity {
  week = 'week',
  month = 'month'
}

export enum MMOModelUnitType {
  profit = 'profit',
  volume = 'volume',
  turnover = 'turnover',
  marketShare = 'market share',
  website = 'website',
  pdm = 'pdm',
  decisions = 'decisions'
}

export enum ModelActionType {
  pooled = 'pooled',
  normal = 'normal',
  shared = 'shared',
  none = 'none'
}

export const MMO_MODEL_UNIT_LOGO: { [key in MMOModelUnitType]: string } = {
  profit: 'paid',
  volume: 'package',
  turnover: 'sell',
  'market share': '',
  website: '',
  pdm: '',
  decisions: ''
};
