export enum TransformationsFunctionName {
  diminishingreturn = 'diminishingreturn',
  identity = 'identity',
  multilinear = 'multilinear',
  multiplication = 'multiplication',
  multilinear_multiplication = 'multilinear_multiplication',
  adstock = 'adstock',
  conditional_adstock = 'conditional_adstock',
  conditional_decay = 'conditional_decay',
  decay = 'decay',
  lag = 'lag',
  affine = 'affine',
  scale = 'scale',
  substract = 'substract',
  substract_constant = 'substract_constant',
  log = 'log',
  logistic = 'logistic',
  power = 'power',
  tanh = 'tanh',
  diminishing_atan2 = 'diminishing_atan2',
  diminishing_exponential = 'diminishing_exponential',
  diminishing_adbudg = 'diminishing_adbudg',
  diminishing_hill = 'diminishing_hill',
  replace = 'replace',

  capfloor = 'capfloor',
  coefficient = 'coefficient',
  product = 'product',
  squared = 'squared',
  normalisation = 'normalisation',
  min_max_normaliser = 'min_max_normaliser',
  standard_normaliser = 'standard_normaliser',
  movingaveragescma = 'movingaveragescma',
  multiply = 'multiply',
  window = 'window',
  spliter = 'spliter',
  edit = 'edit', // FIXME: Check it
  conditionaldecay = 'conditionaldecay',
  timeconstant = 'time_constant'
}

export enum DiminishingReturnFunctionName {
  atan2 = 'atan2',
  exponential = 'exponential',
  sqrt = 'sqrt',
  ln = 'ln',
  logi = 'logi',
  htan = 'htan',
  aff = 'aff',
  adbudg = 'adbudg',
  hill = 'hill'
}

export enum NormalisationFunctionName {
  minMax = 'min_max',
  standard = 'standard',
  dividebymeandep = 'dividebymeandep'
}

export const SOURCE_TRANSFORMATIONS = [
  TransformationsFunctionName.identity,
  TransformationsFunctionName.multiplication,
  TransformationsFunctionName.multilinear,
  TransformationsFunctionName.multilinear_multiplication
];

export const toFunctionName = (str: string): TransformationsFunctionName | null => {
  const cleanStr = `${str}`?.toLowerCase()?.trim();
  // If string matches either key or value of enum, returns that enum
  const res = Object.entries(TransformationsFunctionName).find((kv) => kv.includes(cleanStr));
  return res ? res[1] : null;
};

export const toDiminishingReturnFunctionName = (str: string): DiminishingReturnFunctionName | null => {
  const cleanStr = `${str}`?.toLowerCase()?.trim();
  switch (cleanStr) {
    case 'atan2':
      return DiminishingReturnFunctionName.atan2;
    case 'adbudg':
      return DiminishingReturnFunctionName.adbudg;
    case 'hill':
      return DiminishingReturnFunctionName.hill;
    case 'exponential':
      return DiminishingReturnFunctionName.exponential;
    default:
      return null;
  }
};

// FIXME: WARNING These associations are completely arbitrary, they need to be checked by business
export const toNormalisationFunctionName = (str: string): NormalisationFunctionName | null => {
  const cleanStr = `${str}`?.toLowerCase()?.trim();
  switch (cleanStr) {
    case 'zm':
      return NormalisationFunctionName.standard;
    case 'um':
      return NormalisationFunctionName.minMax;
    case 'dividebymeandep':
      return NormalisationFunctionName.dividebymeandep;
    default:
      return null;
  }
};
