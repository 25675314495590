import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Interval } from 'luxon';
import moment from 'moment';

export type IDate = Date | DateTime;

// Generic Date that both JSDate and luxon's date satisfy
export interface ISerializableDate {
  toJSON(): string;
}

export interface IDateRange {
  from: Date;
  to: Date;
}

export interface IDateTimeRange {
  from: DateTime;
  to: DateTime;
}

export interface IComparedPeriods {
  current: Interval;
  last: Interval;
}
export interface IComparedPeriodsWithNullableLastPeriod {
  current: Interval;
  last: Interval | null;
}

export enum PeriodGranularity {
  ALL = 'all',
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  CUSTOM = 'custom',
  YEAR_TO_DATE = 'ytd'
}

export interface IIntervalNgbDateStruct {
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct | null;
}

export const PeriodGranularityToDate: { [key in PeriodGranularity]: (date: Date) => { date: Interval; comparison: Interval } } = {
  [PeriodGranularity.YEAR]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(moment(date).subtract(1, 'year').startOf('day').toDate(), moment(date).startOf('day').toDate()),
    comparison: Interval.fromDateTimes(
      moment(date).subtract(2, 'year').startOf('day').toDate(),
      moment(date).subtract(1, 'year').startOf('day').toDate()
    )
  }),
  [PeriodGranularity.QUARTER]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(moment(date).subtract(4, 'months').startOf('day').toDate(), moment(date).startOf('day').toDate()),
    comparison: Interval.fromDateTimes(
      moment(date).subtract(1, 'year').subtract(4, 'months').startOf('day').toDate(),
      moment(date).subtract(1, 'year').startOf('day').toDate()
    )
  }),
  [PeriodGranularity.MONTH]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(moment(date).subtract(1, 'month').startOf('day').toDate(), moment(date).startOf('day').toDate()),
    comparison: Interval.fromDateTimes(
      moment(date).subtract(1, 'year').subtract(1, 'month').startOf('day').toDate(),
      moment(date).subtract(1, 'year').startOf('day').toDate()
    )
  }),
  [PeriodGranularity.YEAR_TO_DATE]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(moment(date).startOf('year').startOf('day').toDate(), moment(date).startOf('day').toDate()),
    comparison: Interval.fromDateTimes(
      moment(date).startOf('year').startOf('day').subtract(1, 'year').startOf('day').toDate(),
      moment(date).subtract(1, 'year').startOf('day').toDate()
    )
  }),
  [PeriodGranularity.CUSTOM]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(date, date),
    comparison: Interval.fromDateTimes(date, date)
  }),
  [PeriodGranularity.ALL]: (date: Date = new Date()) => ({
    date: Interval.fromDateTimes(date, date),
    comparison: Interval.fromDateTimes(date, date)
  })
};
