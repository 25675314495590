import { Injectable } from '@angular/core';
import { Endpoint, IApiNotification, ICreateNotification, INotification, IUpdateNotification } from '@shared';
import { DateApiDataConverter } from '@front/services/api-data-converters/date.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { IApiRestEndpointService } from './api-endpoint-service.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService implements IApiRestEndpointService<INotification> {
  readonly endpoint = Endpoint.NOTIFICATION;
  private dateConverter = new DateApiDataConverter();
  constructor(readonly apiService: ApiService) {}

  getById(id: string): Observable<INotification> {
    return this.apiService.get<IApiNotification>(`${this.endpoint}/${id}`).pipe(map((data) => this.fromApi(data)));
  }

  getAll(): Observable<INotification[]> {
    return this.apiService.getAll<IApiNotification>(this.endpoint).pipe(map((elements) => elements.map((data) => this.fromApi(data))));
  }

  delete(id: string): Observable<boolean> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  update(id: string, updateData: IUpdateNotification): Observable<INotification> {
    return this.apiService
      .put<IApiNotification, IUpdateNotification>(`${this.endpoint}/${id}`, updateData)
      .pipe(map((data) => this.fromApi(data)));
  }

  create(createData: ICreateNotification): Observable<INotification> {
    return this.apiService.post<IApiNotification, ICreateNotification>(this.endpoint, createData).pipe(map((data) => this.fromApi(data)));
  }

  private fromApi(apiData: IApiNotification): INotification {
    const notification: IApiNotification = apiData;

    return {
      ...notification,
      createdAt: this.dateConverter.fromApi(apiData.createdAt)
    };
  }
}
