import { Component, forwardRef, Input, Output, EventEmitter, ViewEncapsulation, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-single-date-picker',
  templateUrl: './single-date-picker.component.html',
  styleUrls: ['./single-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleDatePickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SingleDatePickerComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() date: NgbDateStruct | null = null;
  @Input() defaultDate: boolean = true;

  @Output() dateChange = new EventEmitter<NgbDateStruct>();

  formattedDate = '';

  openDatePicker = false;

  @Input() public label = '';

  @Input() public optionalText = '';

  @Input() public streamChanges: boolean | 'true' = false;

  @Input()
  public disabled = false;
  public focused = false;

  constructor(private calendar: NgbCalendar) {}

  ngOnInit(): void {
    if (this.defaultDate) {
      this.date = this.calendar.getToday();
    }

    if (this.date) this.formatDate(this.date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      if (!this.date) {
        this.formattedDate = '';
      } else {
        this.formatDate(this.date);
      }
    }
  }

  formatDate(date: NgbDateStruct): void {
    if (date) {
      this.formattedDate = DateTime.local(date.year, date.month, date.day).toFormat('LLL. dd yyyy');
      this.innerValue = date;
      this.dateChange.emit(date);

      this.onChange();
    }
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: NgbDateStruct | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  public toggleDatePickerDisplay() {
    this.openDatePicker = !this.openDatePicker;
  }

  /* CVA */
  writeValue(value: NgbDateStruct): void {
    this.date = value;
    this.formatDate(this.date);
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
