import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */

@Directive({
  selector: '[ekiGrid]'
})
export class GridContainerDirective {
  @Input()
  columns?: string | string[];

  @Input()
  @HostBinding('style.grid-auto-flow')
  autoFlow?: string;

  @HostBinding('style.grid-template-columns')
  get gridTemplateColumns(): string | undefined {
    if (this.columns) {
      return this.columns instanceof Array ? this.columns.join(' ') : this.columns;
    }
    return undefined;
  }

  constructor(private readonly host: ElementRef, private readonly renderer: Renderer2) {
    this.renderer.setStyle(this.host.nativeElement, 'display', 'grid');
  }
}

@Directive({
  selector: '[ekiGridItem]'
})
export class GridItemDirective {
  @Input()
  colStart?: string | number;

  @Input()
  colEnd?: string | number;

  @Input()
  colSpan?: string | number;

  @HostBinding('style.grid-column-start')
  get gridColumnStart(): string | undefined {
    return this.colStart ? `${this.colStart}` : undefined;
  }

  @HostBinding('style.grid-column-end')
  get gridColumnEnd(): string | undefined {
    return this.colEnd ? `${this.colEnd}` : this.colSpan ? `span ${this.colSpan}` : undefined;
  }

  @Input()
  rowStart?: string | number;

  @Input()
  rowEnd?: string | number;

  @Input()
  rowSpan?: string | number;

  @HostBinding('style.grid-row-start')
  get gridRowStart(): string | undefined {
    return this.rowStart ? `${this.rowStart}` : undefined;
  }

  @HostBinding('style.grid-row-end')
  get gridRowEnd(): string | undefined {
    return this.rowEnd ? `${this.rowEnd}` : this.rowSpan ? `span ${this.rowSpan}` : undefined;
  }
}
