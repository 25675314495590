import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';

export const defaultConfig: IClientConfig = {
  name: 'Ekimetrics',
  logo: 'assets/images/logo-Eki-noir.svg',
  platformName: 'MMO Platform',
  platformLogo: 'assets/images/one-vision-logo.svg',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: true,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    turnover: 'Sell Out',
    brand: 'Brand',
    model: 'Model',
    'pooled model': 'Pooled Model',
    volume: 'Volume',
    'other invests': 'Other Investments',
    roi: 'ROI',
    profit: 'Profit'
  },
  aggregationWording: ['Division', 'Zone', 'Country', 'Brand'],
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume],
  modelTypeHideRoi: {
    [MMOModelUnitType.turnover]: false
  },
  roiPerKpi: {
    [MMOModelUnitType.turnover]: 'ROI',
    [MMOModelUnitType.volume]: 'ROI'
  },
  startingMonthOfTheYear: 1,
  GATrackingID: '',
  completeConfigurationPage: true,
  powerBiSendBMC: false,
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD'],
  variableMetrics: ['grp', 'clicks', 'impressions', 'sample', 'none'],
  pooledModeling: false,
  sso: {
    azure: false
  }
};
