import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientWordingPipe } from './client-wording.pipe';
import { CrossSectionsPipe } from './cross-sections.pipe';
import { EnsureDatePipe } from './ensure-date.pipe';
import { MemoizePipe } from './memoize.pipe';
import { NumeralPipe } from './numeral.pipe';

const PIPES = [ClientWordingPipe, CrossSectionsPipe, EnsureDatePipe, MemoizePipe, NumeralPipe];

@NgModule({
  providers: PIPES,
  declarations: PIPES,
  exports: PIPES,
  imports: [CommonModule]
})
export class PipesModule {}
