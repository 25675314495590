import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ITreeItemData } from '~/interfaces/tree-item.interface';
import { TypedSimpleChange } from '~/types/changes';
import { countChildren } from '@shared';
import { ITreeNode } from '@shared';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface IComponentChanges<T> extends SimpleChanges {
  title: TypedSimpleChange<string>;
  data: TypedSimpleChange<ITreeItemData<T>>;
  activeChild: TypedSimpleChange<T | null>;
}

@Component({
  selector: 'app-tree-item',
  templateUrl: './tree-item.component.html',
  styleUrls: ['./tree-item.component.scss'],
  animations: [
    trigger('expandableState', [
      state('open', style({ height: '*', opacity: 1 })),
      state('close', style({ height: '0', opacity: 0 })),
      transition('open <=> close', animate('500ms ease'))
    ])
  ]
})
export class TreeItemComponent<T> implements OnChanges, OnInit {
  @Input() data: ITreeItemData<T> | null = null;
  @Input() activeChild: T | null = null;
  @Input() active = false;
  @Input() frozen = false;
  @Input() minimalized = false;
  @Input() compareFn: (a: T | undefined, b: T | undefined) => boolean = (a, b) => a === b;

  @Output() activeChildChange = new EventEmitter<T | null>();
  @Output() childrenCountChange = new EventEmitter<void>();

  childrenCount = 0;
  childrenHeight = 0;

  constructor(private cdref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.refreshChildrenCount();
  }

  // Event from parent
  ngOnChanges(changes: IComponentChanges<T>): void {
    const child = changes.activeChild?.currentValue;
    if (child && this.data) {
      this.active = this.compareFn(child, this.data.target);
    }
  }

  // Internal. Event from child
  onActiveChildChange(child: T | null): void {
    this.active = false;

    // This will propagate to other children
    this.activeChild = child;

    // This will propagate to parent
    this.activeChildChange.emit(child);
  }

  onNodeClick(node: ITreeItemData<ITreeNode>) {
    if (!this.minimalized && !this.frozen && !node.frozen) {
      if (this.data?.target) {
        this.swapActive();
      }
    }
  }

  onCollapseClick(node: ITreeItemData<ITreeNode>) {
    if (!this.minimalized && !this.frozen && !node.frozen) {
      if (this.data?.children?.length) {
        this.swapExpand();
      }
    }
  }

  // Internal
  swapActive(): void {
    this.activeChildChange.emit(this.data?.target || null);
  }

  // Internal
  swapExpand(): void {
    if (this.data) {
      this.data.expand = !this.data?.expand;
      this.refreshChildrenCount();
    }
  }

  refreshChildrenCount(): void {
    if (this.data) {
      this.childrenCount = countChildren(this.data, 0);
      this.childrenHeight = this.childrenCount * 43.6;
      this.cdref.detectChanges();
      this.childrenCountChange.emit();
    }
  }
}
