import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApplicationModules } from '@shared';
import { ApplicationPermissionsService } from '~/services/application-permissions.service';
import { assertDefined } from '@shared';

@Directive({
  selector: '[showIfPermitted]'
})
export class ShowIfPermittedDirective {
  private isModulePermitted?: boolean;
  private hasView = false;
  private elseTemplateRef: TemplateRef<any> | null = null;

  @Input() set showIfPermitted(module: ApplicationModules | string | ApplicationModules[]) {
    if (Array.isArray(module)) {
      this.isModulePermitted = module.some((m) => this.applicationPermissionsService.isPermitted(m));
    } else {
      this.isModulePermitted = this.applicationPermissionsService.isPermitted(module);
    }

    this.updateView();
  }

  @Input() set showIfPermittedElse(templateRef: TemplateRef<any> | null) {
    assertDefined(templateRef);
    this.elseTemplateRef = templateRef;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private applicationPermissionsService: ApplicationPermissionsService
  ) {}

  private updateView(): void {
    if (this.isModulePermitted && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.isModulePermitted) {
      if (this.elseTemplateRef) {
        this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
        this.hasView = true;
      } else if (this.hasView) {
        this.viewContainerRef.clear();
        this.hasView = false;
      }
    }
  }
}
