import { IApiCreateMMOModel, IApiUpdatePooledMMOModel, ICrossSection, MMOModelStatus, MMOModelTotalType, MMOModelUnitType } from '@shared';
import { BusinessObject, CreateBusinessObject, UpdateBusinessObject } from './business.interface';

export type IMMOModelAggregationOrigin = Pick<
  IMMOModel,
  'id' | 'name' | 'totalType' | 'unitType' | 'unitLabel' | 'crossSections' | 'historyStartDate' | 'historyEndDate' | 'nestedParentsIds'
>;

export interface IMMOModel extends BusinessObject {
  name: string;
  status: MMOModelStatus;
  totalType: MMOModelTotalType;
  unitType: MMOModelUnitType;
  unitLabel: string;
  modelFileLink: string;
  roiMultiplier: number;
  effectivenessMultiplier: number;
  costPerMetricMultiplier: number;

  crossSections?: ICrossSection[] | null;

  nestedParentsIds?: string[];
  nestedParents?: IMMOModel[];
  disableNestedFunnelCard?: boolean;
  funnelOrder?: number;

  creatorId?: string;
  historyStartDate: Date;
  historyEndDate: Date;
  createdAt: Date;
  updatedAt: Date;

  aggregationOrigins?: IMMOModelAggregationOrigin[];

  dateGranularity?: IDateGranularity;
  originalDateGranularity?: IDateGranularity;
}

type ReadOnlyProperties = Pick<IMMOModel, 'updatedAt' | 'createdAt'>;

export type ICreateMMOModel = CreateBusinessObject<IMMOModel, ReadOnlyProperties>;

export type IUpdateMMOModel = UpdateBusinessObject<
  IMMOModel,
  ReadOnlyProperties &
    Pick<
      IMMOModel,
      | 'modelFileLink'
      | 'creatorId'
      | 'historyEndDate'
      | 'historyStartDate'
      | 'unitType'
      | 'roiMultiplier'
      | 'status'
      | 'costPerMetricMultiplier'
      | 'effectivenessMultiplier'
    >
>;

export type IGenerateLegacyModelQueueMessageData = {
  id: string;
  aggregationId: string;
  sharedVariables: string[];
  scenariosToArchive?: string[];
  aggregationWordings?: string[];
};

export type IPooledModelMessageData = {
  id: string;
  model: IApiCreateMMOModel;
  file: Buffer;
  creatorId: string;
  oldModelId?: string;
  aggregationWordings?: string[];
  scenariosToArchive?: string[];
};

export interface IUpdatePooledModelMessageData {
  modelId: string;
  data: IApiUpdatePooledMMOModel;
  unitType: string;
}

export interface IInvestmentAggregationConfig {
  investmentName: string;
  aggregationStructureIds: string[];
  sourceAggregationStructureIds: string[];
}

export interface IGenerateAggregatedModelQueueMessageData {
  id: string;
  aggregationId: string;
  aggregatedInvestments: IInvestmentAggregationConfig[];
}

export interface IDateGranularity {
  period?: IDateGranularityPeriodEnum;
  days?: number;
}

export enum IDateGranularityPeriodEnum {
  CUSTOM = 'custom',
  MONTH = 'month',
  WEEK = 'week'
}
