import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { isDebugToolsActive } from '~/utils/debug';

@Injectable({
  providedIn: 'root'
})
export class CanLoadDebugToolsGuard {
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return isDebugToolsActive();
  }
}
