// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

:host {
  display: block;
}

label {
  cursor: pointer;
  font-weight: 500;
  position: relative;
}
label input {
  visibility: hidden;
}
label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4em #5a7396;
}
label span {
  border-radius: 99em;
  transition: 0.25s ease;
}
label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.08em #344256;
}

.disabled {
  opacity: 0.5;
}
.disabled label {
  cursor: default;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
