import { ChangeDetectorRef, Component, ChangeDetectionStrategy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IAggregationStructure } from '@shared';
import { Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AggregationStructureComponent } from '@front/app/modules/home/components/aggregation-structure/aggregation-structure.component';

@Component({
  selector: 'app-aggregation-structure-modal',
  templateUrl: './aggregation-structure-modal.component.html',
  styleUrls: ['./aggregation-structure-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AggregationStructureModalComponent implements OnInit {
  @ViewChildren(AggregationStructureComponent) childrenAggregationStructure!: QueryList<AggregationStructureComponent>;

  @Input() aggregationStructures: IAggregationStructure[] = [];
  isExpanded = false;
  isTreeDirty = false;
  public displayAggregationStructures: IAggregationStructure[] | null = null;
  private searchValue: string = '';

  constructor(public activeModal: NgbActiveModal, private cdref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.displayAggregationStructures = this.aggregationStructures;
  }

  resizeTree(): void {
    this.updateChildrenAggStruct(this.childrenAggregationStructure);
    this.isTreeDirty = !this.isTreeDirty;
  }

  updateChildrenAggStruct(children: QueryList<AggregationStructureComponent>) {
    children.forEach((child) => {
      child.isExpanded =
        this.isTreeDirty ||
        (this.searchValue !== '' && Boolean((child.aggregationStructure?.name.toLowerCase() ?? '').match(this.searchValue.toLowerCase())));
      child.swapExpand();
      this.updateChildrenAggStruct(child.childAggregationStructures);
    });
  }
  filteredAggregationStructures(aggregationStructures: IAggregationStructure[], searchText: string): IAggregationStructure[] {
    const result: IAggregationStructure[] = [];
    aggregationStructures.forEach((aggregationStructure) => {
      if (aggregationStructure.name.toLowerCase().match(searchText)) {
        result.push(aggregationStructure);
      } else if (aggregationStructure.children && aggregationStructure.children.length > 0) {
        const children = this.filteredAggregationStructures(aggregationStructure.children, searchText);
        if (children.length > 0) {
          result.push({ ...aggregationStructure, children });
        }
      }
    });
    return result;
  }

  updateSearch(): void {
    this.displayAggregationStructures = this.filteredAggregationStructures(this.aggregationStructures, this.searchValue.toLowerCase());
    this.cdref.detectChanges();
    if (this.searchValue === '') {
      this.isTreeDirty = true;
      this.updateChildrenAggStruct(this.childrenAggregationStructure);
      this.isTreeDirty = !this.isTreeDirty;
    } else {
      this.updateChildrenAggStruct(this.childrenAggregationStructure);
    }
  }
  onSearchInput(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.searchValue = target.value;
    this.updateSearch();
  }
  closeModal() {
    this.activeModal.close();
  }

  expandMatchAggregationStructure(aggregationStructure: IAggregationStructure): boolean {
    if (this.searchValue === '') {
      return false;
    } else {
      return !Boolean(aggregationStructure.name.toLowerCase().match(this.searchValue.toLowerCase()));
    }
  }
}
