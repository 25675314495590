import { Inject, Injectable } from '@angular/core';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ApiService } from '@front/services/api.service';
import { IBreadcrumbPath } from '@front/services/breadcrumb.service';
import { Endpoint, IReportConfig } from '@shared';
import { Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

export interface IReportAccess {
  accessToken: string;
  expiry: string;
  status: number;
  embedUrl: [{ embedUrl: string; reportId: string; reportName: string }];
}

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {
  clientConfig: IClientConfig;

  constructor(private api: ApiService, @Inject(ENUMS) enums: IClientConfig) {
    this.clientConfig = enums;
  }

  async getAllEmbedInfo(bmc: string, reportConfig: IReportConfig) {
    const reports: { [key: string]: Observable<IReportAccess> } = {};

    for (const reportId of reportConfig.reportIds) {
      const report = this.getEmbedInfo(reportId, bmc, reportConfig.workspaceId).pipe(
        catchError((_err) => {
          return of(null);
        }),
        filter((report) => report !== null)
      ) as Observable<IReportAccess>;

      reports[reportId] = report;
    }
    return reports;
  }

  getEmbedInfo(reportId: string, bmc: string, workspaceId: string): Observable<IReportAccess> {
    const data: { workspaceId: string; reportId: string; bmc?: string } = {
      workspaceId,
      reportId
    };

    if (this.clientConfig.powerBiSendBMC) data.bmc = bmc;

    return this.api.get(`${Endpoint.POWER_BI}/token`, data);
  }

  getReportsToDisplay(path: IBreadcrumbPath[]): IReportConfig | null {
    // Return first reports found starting from last aggreg

    for (let i = path.length - 1; i >= 0; --i) {
      const aggreg = path[i];
      if (aggreg.reports && aggreg.reports?.reportIds && aggreg.reports.reportIds.length > 0) {
        return aggreg.reports;
      }
    }

    return null;
  }
}
