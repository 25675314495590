import { DateTime, Duration } from 'luxon';

export const isNullish = (val: unknown): val is null | undefined => val === null || val === undefined;
export const isNotNullish = (val: unknown): val is NonNullable<typeof val> => !isNullish(val);

export function getOldValue(percentage: number, finalValue: number): number {
  return finalValue / (1 + percentage / 100);
}

export function getEnumValues(myEnum: any): string[] {
  return Object.keys(myEnum).map((k) => myEnum[k as any]);
}

export function dateToNgbDateStruct(date: Date) {
  return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
}

export function computeToDate(date: Date, isMonthlyModel: boolean): Date {
  date.setUTCHours(0);
  // 7 is Sunday
  let newDate = DateTime.fromJSDate(date);
  if (!isMonthlyModel && newDate.weekday != 7) {
    newDate = newDate.plus({ days: 7 - newDate.weekday });
  } else if (isMonthlyModel && newDate.day !== newDate.daysInMonth) {
    // For monthly models
    newDate = newDate.set({ day: newDate.daysInMonth });
  }
  return newDate.toJSDate();
}

export function computeScenarioToDate(date: Date, isMonthlyModel: boolean): Date {
  date.setUTCHours(0);
  // 7 is Sunday
  let newDate = DateTime.fromJSDate(date);
  if (isMonthlyModel) {
    if (newDate.day < newDate.daysInMonth / 2) {
      newDate = newDate.minus({ days: newDate.day });
    } else {
      newDate = newDate.set({ day: newDate.daysInMonth });
    }
  } else {
    if (newDate.weekday <= 4) {
      newDate = newDate.minus({ days: newDate.weekday });
    } else {
      newDate = newDate.plus({ days: 7 - newDate.weekday });
    }
  }
  return newDate.toJSDate();
}

export function isMonthly(date1: Date, date2: Date): boolean {
  const firstDate = DateTime.fromJSDate(date1);
  const secondDate = DateTime.fromJSDate(date2);
  const diff = Duration.fromObject(firstDate.diff(secondDate, ['months', 'days']).toObject()).as('days');
  return Math.abs(diff) > 8;
}

export function arePeriodsDiscontinuous(firstPeriodEndDate: Date, secondPeriodStartDate: Date, isMonthly: boolean): boolean {
  const firstDate = DateTime.fromJSDate(firstPeriodEndDate);
  const secondDate = DateTime.fromJSDate(secondPeriodStartDate);
  const diff = Duration.fromObject(firstDate.diff(secondDate, ['months', 'days']).toObject()).as('days');
  if (isMonthly) {
    return Math.abs(diff) > 31;
  }
  return Math.abs(diff) > 8;
}

export function computeFromDate(date: Date, isMonthlyModel: boolean): Date {
  date.setUTCHours(0);
  let newDate = DateTime.fromJSDate(date);
  if (isMonthlyModel && newDate.day > 1) {
    // monthly model
    newDate = newDate.set({ day: 1 });
  } else if (!isMonthlyModel && newDate.weekday !== 1) {
    // 1 is monday
    newDate = newDate.minus({ days: newDate.weekday - 1 });
  }
  return newDate.toJSDate();
}

export function computeScenarioFromDate(date: Date, isMonthlyModel: boolean): Date {
  date.setUTCHours(0);
  let newDate = DateTime.fromJSDate(date);
  if (isMonthlyModel) {
    if (newDate.day > 1 && newDate.day < newDate.daysInMonth / 2) {
      newDate = newDate.set({ day: 1 });
    } else if (newDate.day > newDate.daysInMonth / 2) {
      newDate = newDate.set({ day: 1, month: newDate.month + 1 });
    }
  } else {
    if (newDate.weekday <= 4) {
      newDate = newDate.minus({ days: newDate.weekday - 1 });
    } else {
      newDate = newDate.plus({ days: 8 - newDate.weekday });
    }
  }
  return newDate.toJSDate();
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha !== undefined) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export function stringToHexColor(str: string): string {
  let hash = 0;
  if (str.length === 0) return '#000000';
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (var i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).slice(-2);
  }
  return color;
}

export function dateTimeToIsoDate(date: DateTime): string {
  return date.toJSDate().toISOString();
}

export function deleteTrailingSlashes(path: string): string {
  return path.replace(/\/+$/, '');
}

export function validateUrl(url: string) {
  const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9]+(?:[\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/.*)?$/i;
  return urlRegex.test(url);
}

export function castISOStringsToJsDates(...dates: (string | undefined)[]): (Date | undefined)[] {
  return dates.map((date) => (date ? DateTime.fromISO(date).toJSDate() : undefined));
}
