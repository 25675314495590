import { BusinessObject, IMMOModel, IPlatformDeployment } from '@shared';

export enum ModelDeploymentState {
  ONGOING = 'ongoing',
  DONE = 'done',
  ERROR = 'error'
}

export interface IMmoModelDeployment extends BusinessObject {
  aggregationStructureId: string;
  modelOriginId: string; // model scope identifier  ( i.e. consultantModelId in the case of interop deployment)
  modelOriginName: string;
  modelOriginVersion: string;
  mainModelId?: string;
  modelSource?: {
    type?: string;
    uri?: string;
  };
  mmoModelId: string;
  mmoModel?: IMMOModel;
  deploymentId: string;
  state: ModelDeploymentState;
  error?: string;
  deployment?: IPlatformDeployment;
}
