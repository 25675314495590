import { accorConfig } from './clients-config/accor.config';
import { gskConfig } from './clients-config/gsk.config';
import { axaConfig } from './clients-config/axa.config';
import { ekiConfig } from './clients-config/eki.config';
import { renaultConfig } from './clients-config/renault.config';
import { forresterConfig } from './clients-config/forrester.config';
import { nestleConfig } from './clients-config/nestle-pfme.config';
import { nestlePromoConfig } from './clients-config/nestle-promo.config';
import { sandboxConfig } from './clients-config/sandbox.config';
import { nissanConfig } from './clients-config/nissan.config';
import { nestleApConfig } from './clients-config/nestle-ap.config';
import { nestlePromoPrdConfig } from './clients-config/nestle-promo-prd.config';
import { specsaversConfig } from './clients-config/specsavers.config';
import { moetConfig } from './clients-config/moet.config';
import { promoDemoConfig } from './clients-config/promo-demo.config';
import { ppdGskConfig } from './clients-config/ppd-gsk.config';
import { fdjConfig } from './clients-config/fdj.config';
import { ekiWorkerConfig } from './clients-config/eki-worker.config';
import { heinekenConfig } from './clients-config/heineken.config';
import { eurostarConfig } from './clients-config/eurostar.config';
import { ekiGskConfig } from './clients-config/eki-gsk.config';
import { legoConfig } from './clients-config/lego.config';
import { keringConfig } from './clients-config/kering.config';
import { orangeConfig } from './clients-config/orange.config';
import { unileverConfig } from './clients-config/unilever.config';
import { bonduelleConfig } from './clients-config/bonduelle.config';

export const config = {
  // SET CONFIG VARIABLES THERE
  clients: {
    eki: ekiConfig,
    forrester: forresterConfig,
    renault: renaultConfig,
    axa: axaConfig,
    accor: accorConfig,
    gsk: gskConfig,
    ppdGsk: ppdGskConfig,
    nestle: nestleConfig,
    nestleAp: nestleApConfig,
    nestlePromo: nestlePromoConfig,
    nestlePromoPrd: nestlePromoPrdConfig,
    sandbox: sandboxConfig,
    nissan: nissanConfig,
    specsavers: specsaversConfig,
    moet: moetConfig,
    promoDemo: promoDemoConfig,
    fdj: fdjConfig,
    ekiWorker: ekiWorkerConfig,
    heineken: heinekenConfig,
    eurostar: eurostarConfig,
    ekiGsk: ekiGskConfig,
    lego: legoConfig,
    kering: keringConfig,
    orange: orangeConfig,
    unilever: unileverConfig,
    bonduelle: bonduelleConfig
  }
};
