import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';
import { defaultConfig } from './default.config';

export const keringConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Kering',
  logo: 'assets/images/logo_kering.svg',
  moduleAccess: {
    investment: true,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    turnover: 'Sales',
    brand: 'Brand',
    model: 'Model',
    'pooled model': 'Pooled Model',
    volume: 'Volume',
    'other invests': 'Other Investments',
    roi: 'ROI',
    profit: 'Profit',
    'market share': 'Awareness'
  },
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.marketShare]
};
