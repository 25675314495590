export enum FeatureToggle {
  DisplayInvestmentConfiguration = 'displayInvestmentConfiguration',
  ActivateNestedModels = 'activateNestedModels',
  ActivateNestedMocks = 'activateNestedMocks',
  ActivateExplodedView = 'activateExplodedView',
  ActivateCache = 'activateCache',
  ActivateSimulationInteropToInterop = 'activateSimulationInteropToInterop',
  PerformSimulationOnWorker = 'performSimulationOnWorker',
  DisplayNestedCardsInAggregatedModels = 'displayNestedCardsInAggregatedModels'
}
