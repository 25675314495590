import { DurationInput } from 'luxon';

export const INVESTMENT_IMPORT_FILE = 'investment';
export const MASSTER_FILE_IMPORT = 'masster';
export const JSON_FILE_IMPORT = 'jsonFile';
export const STANDARD_OPTIMIZATION_VARIATION = 0.2;
export const DEACTIVATE_DEFAULT_MESSAGE = `Please fill and check variables settings then save your changes.\nIf you leave before saving, your model will be incomplete.`;
export const DEACTIVATE_SHARED_MESSAGE = `Please Validate your model.\nIf you leave before saving, your model will be incomplete.`;
export const DEFAULT_SHARED_INVEST_NAME = 'other invests';
export const DEFAULT_INITIAL_SCENARIO_FORECAST: DurationInput = { years: 1 };
export const DEFAULT_CROSS_SECTION_KEY_NAME = 'Default';
