import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SnackbarService } from '@front/app/modules/shared-global/snackbar/snackbar.service';

@Component({
  selector: 'app-zone-file-upload',
  templateUrl: './zone-file-upload.component.html',
  styleUrls: ['./zone-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZoneFileUploadComponent),
      multi: true
    }
  ]
})
export class ZoneFileUploadComponent {
  @Input()
  public acceptRegex?: string;

  @Input()
  public acceptType?: string[];

  // Two-way binding to "file"
  @Input() file: File | null;
  @Output() fileChange = new EventEmitter<File | null>();

  @Input()
  public streamChanges: boolean | 'true' = false;

  public disabled = false;

  constructor(private readonly snackbarService: SnackbarService) {
    this.file = null;
  }

  private isMatchingType(file: File) {
    return this.acceptType?.includes('.' + file.name.split('.').pop()!);
  }

  private isMatchingRegex(file: File) {
    if (!this.acceptRegex) return false;
    const regex = new RegExp(this.acceptRegex);
    return regex.test(file.name);
  }

  private isFileAccepted(files?: FileList) {
    if (!files) return false;
    const file = files[0];
    if (!this.acceptRegex && !this.acceptType) {
      return true;
    }
    if (this.acceptRegex && this.acceptType) {
      return this.isMatchingRegex(file) && this.isMatchingType(file);
    }
    return this.isMatchingRegex(file) || this.isMatchingType(file);
  }

  handleFileInput(event: DragEvent): void {
    const files = event.dataTransfer?.files;
    if (this.isFileAccepted(files)) {
      this.file = files![0];
      this.fileChange.emit(this.file);

      this.innerValue = this.file;
      this.onChange();
    } else {
      let errorMessage;
      if (this.acceptType) {
        errorMessage = ' File should be with format ' + this.acceptType.join(' or ') + '.';
      }
      if (this.acceptRegex) {
        errorMessage += ' File should match : ' + this.acceptRegex + '.';
      }
      this.snackbarService.showError('Oops !..', 'Wrong file format.' + errorMessage);
    }
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: File | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  /* CVA */
  writeValue(value: File | null): void {
    if (value === null) {
      this.file = null;

      this.fileChange.emit(this.file);
      this.innerValue = this.file;

      this.onChange();
    } else {
      this.innerValue = value;
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
