import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface ISelectOption<T> {
  value: T;
  name: string;
  disabled?: boolean;
  disabledText?: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './generic-select.component.html',
  styleUrls: ['./generic-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent<T> implements ControlValueAccessor, OnChanges, OnInit {
  @Input() public options: ISelectOption<T>[] = [];

  @Input() public label = '';

  @Input() public placeholder = 'Placeholder';

  @Input() public disabled = false;

  @Input() public streamChanges: boolean | 'true' = false;

  @Input() public selectedValue?: T;

  @Input() public capitalize: boolean = true;

  public selected: ISelectOption<T> | null = null;

  innerValue: T | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  ngOnInit(): void {
    this.selected = this.options.find((opt) => opt.value === this.selectedValue) || null;
  }

  public optionClicked(option: ISelectOption<T>): void {
    // We do nothing if the clicked option is the current one;
    if (option === this.selected) {
      return;
    }

    this.selected = option;

    this.propagateChange(this.selected.value);
  }

  private findProperName(): void {
    if (this.innerValue === null) {
      this.selected = null;
      return;
    }

    const index = this.options.findIndex((e) => e.value === this.innerValue);

    if (index === -1) {
      this.selected = null;
      return;
    }

    this.selected = this.options[index];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue) {
      this.findProperName();
    }
  } // We MUST NOT propagate change here, we are in the init method.

  /* CVA */ writeValue(value: T): void {
    this.innerValue = value;
    this.findProperName();
  }

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
