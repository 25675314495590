import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const nestlePromoPrdConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Nestlé',
  logo: 'assets/images/logo-nestle.svg',
  platformName: 'Promo Platform',
  platformLogo: 'assets/images/ap_platform_nestle_logo.svg',
  plateformHelpImage: 'assets/images/glossary-nestle-promo.png',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: false,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: false,
    channelPerformance: false,
    scenarios: false,
    promo: {
      postEval: true,
      preEval: true,
      efficiencyLevers: true,
      optimization: true
    }
  },
  sso: {
    azure: true
  },
  completeConfigurationPage: false
};
