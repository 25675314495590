import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const orangeConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Orange',
  logo: 'assets/images/logo-orange.svg',
  wording: {
    ...defaultConfig.wording,
    turnover: 'Marge Placée'
  },
  sso: {
    azure: true
  }
};
