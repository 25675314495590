import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/no-input-rename */

@Directive({
  selector: '[borderLeftColor]'
})
export class BorderLeftColorDirective implements OnChanges {
  @Input('borderLeftColor')
  color!: string;

  @Input()
  style = 'solid';

  @Input()
  width = '5px';

  constructor(private readonly host: ElementRef) {}

  ngOnChanges(_: SimpleChanges): void {
    this.host.nativeElement.style.borderLeft = `${this.width} ${this.style} ${this.color}`;
  }
}
