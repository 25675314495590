import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SnackbarService } from '@front/app/modules/shared-global/snackbar/snackbar.service';
import { ISelectOption } from '@front/interfaces/generic-select.interface';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent {
  @Input() list: ({ checked?: boolean } & ISelectOption<string>)[] = [];
  @Input() maxCheck: number = 10;
  @Input() constrainHeight: boolean = false;
  @Input() disableActionButton: boolean = false;

  @Output() shareCheckedList = new EventEmitter();

  checkedList: ISelectOption<string>[];

  // Only used in HTML template
  showDropDown: boolean = false;
  stopSelection: boolean = false;

  constructor(private readonly snackBarService: SnackbarService) {
    this.checkedList = [];
  }

  getSelectedValue(status: boolean | undefined, name: string, value: string): void {
    if (status) {
      this.checkedList.push({ name, value });
    } else {
      var index = this.checkedList.findIndex((element) => element.value === value);
      this.checkedList.splice(index, 1);
    }
    this.stopSelection = this.checkedList.length >= this.maxCheck;
  }

  shareList(): void {
    if (this.checkedList.length) {
      this.showDropDown = false;
      this.shareCheckedList.emit(this.checkedList);
    } else {
      this.snackBarService.showWarning('Warning', 'You have not selected any levers');
    }
  }

  onClick() {
    this.showDropDown = !this.showDropDown;
  }

  onClickOutside() {
    if (this.showDropDown) this.showDropDown = false;
  }
}
