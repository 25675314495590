import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '~/app/modules/shared-global/snackbar/snackbar.service';
import { HttpStatusCode } from '@shared';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status != HttpStatusCode.UNAUTHORIZED && error.status != HttpStatusCode.FORBIDDEN) {
          this.snackbarService.showError(
            'Oops !..',
            error.error?.message || error.error || error.statusText || 'We have encountered an issue...'
          );
        }
        return throwError(error);
      })
    );
  }
}
