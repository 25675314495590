export enum DigitalType {
  'all_pfme' = 'all_pfme',
  'google' = 'google',
  'wamiz' = 'wamiz'
}

export const DigitalTypeMap: { [key in DigitalType]: string } = {
  [DigitalType.all_pfme]: 'All PFME',
  [DigitalType.google]: 'Google only',
  [DigitalType.wamiz]: 'Wamiz only'
};
