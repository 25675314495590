import { Component, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-number-input',
  templateUrl: './generic-number-input.component.html',
  styleUrls: ['./generic-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() public value = 0;
  @Input() public editable = false;
  @Input() public unit = '';
  @Input() public height = 35;
  @Input() public numberFormat = '+0';
  @Input() public isBorder = true;
  @Input() public setWidthMax = true;
  @Input() public backgroundColor = true;
  @Input() public streamChanges: boolean | 'true' = false;
  @Input() arrowControl: boolean = true;
  @Input() evalInput: boolean = true;
  @Input() labelSize: number = 12;
  @Input() labelPadding: number = 4;

  public evalValue: string = '';
  public disabled = false;
  public manualEdit = false;

  private allowedEvalInputCharsRegEx = /^[+\-*/%\s\d.()]+$/;

  increaseInterval = setInterval(() => null, 1000);
  decreaseInterval = setInterval(() => null, 1000);

  constructor(private eRef: ElementRef) {}

  /* CVA */ innerValue: number | null = this.value;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  @HostListener('document:mousedown', ['$event'])
  clickout(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.manualEdit = false;
    }
    this.setEvalValue();
  }

  onClick() {
    if (!this.editable) return;
    this.manualEdit = this.editable;
    if (this.eRef.nativeElement) {
      setTimeout(() => {
        this.eRef.nativeElement.focus();
      });
    }
  }

  setEvalValue() {
    if (this.evalInput) {
      this.evalValue = eval(this.sanitizeInput(this.evalValue));
    }
    this.value = this.evalValue ? Number(parseFloat(this.evalValue).toFixed(2)) : 0;
    this.innerValue = this.value;
  }

  private sanitizeInput(input: string): string {
    const isMathFunction = this.allowedEvalInputCharsRegEx.test(input);
    return isMathFunction ? input : '';
  }

  onMouseDown(increase: boolean): void {
    if (increase) {
      this.value++;
      setTimeout(() => (this.increaseInterval = setInterval(() => this.value++, 300)), 300);
    } else {
      this.value--;
      setTimeout(() => (this.decreaseInterval = setInterval(() => this.value--, 300)), 300);
    }
  }

  onMouseUp(): void {
    clearInterval(this.increaseInterval);
    clearInterval(this.decreaseInterval);

    // To be sure interval is cleaned, even after OnMouseDown timeout
    setTimeout(() => {
      clearInterval(this.increaseInterval);
      clearInterval(this.decreaseInterval);
    }, 300);

    this.validateValue();
  }

  validateValue(): void {
    this.setEvalValue();
    this.manualEdit = false;
    this.innerValue = this.value;
    this.propagateChange(this.innerValue);
  }

  public onChange(event: number | string | null = null): void {
    if (event === null) return;
    if (typeof event === 'string') {
      this.value = event === '' ? 0 : parseInt(event);
      this.evalValue = event;
    } else if (typeof event === 'number') {
      this.value = event;
      this.evalValue = event.toString();
    }
    this.innerValue = this.value;
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onBlur(): void {
    this.propagateTouched();
  }

  /* CVA */
  writeValue(value: string): void {
    this.evalValue = value?.toString();

    if (typeof value === 'string') {
      this.innerValue = parseInt(value);
      this.value = this.innerValue;
    } else if (typeof value === 'number') {
      this.innerValue = value;
      this.value = this.innerValue;
    } else if (value === null) {
      this.innerValue = null;
    } else {
      console.warn('Unexpected input', value);
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
