import { Pipe, PipeTransform } from '@angular/core';
import { IDate } from '~/interfaces/date.interface';
import { ensureDate } from '~/utils/date';

@Pipe({ name: 'ensureDate' })
export class EnsureDatePipe implements PipeTransform {
  transform(input: IDate | undefined | null): Date | null {
    if (!input) {
      return null;
    }

    return ensureDate(input);
  }
}
