import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { IRouteData } from '@front/interfaces/route-data.interface';
import { ApplicationPermissionsService } from '~/services/application-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  constructor(private router: Router, private applicationPermissionsService: ApplicationPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const routeData = route.data as IRouteData;
    if (!routeData.title) {
      return true;
    }

    const isPermitted = this.applicationPermissionsService.isPermitted(routeData.title);
    if (isPermitted) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
