import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ConfigService } from '@front/services/api/config.api.service';
import { IConfig } from '@shared';
import { Observable } from 'rxjs';
import { AppStateService } from '~/services/state/app-state.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigResolver {
  private clientConfig: IClientConfig;

  constructor(private configService: ConfigService, private appState: AppStateService, @Inject(ENUMS) enums: IClientConfig) {
    this.clientConfig = enums;
  }

  resolve(_route: ActivatedRouteSnapshot): Observable<IConfig | null> {
    return this.configService.getCurrent(this.clientConfig.aggregationWording).pipe(tap((config) => this.appState.setConfigs(config)));
  }
}
