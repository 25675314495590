import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

numeral.register('locale', 'ekiapp', {
  delimiters: {
    thousands: ' ',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal: function (_number) {
    return 'nth';
  },
  currency: {
    symbol: '€'
  }
});

// switch between locales
numeral.locale('ekiapp');

/**
 * Documentation: http://numeraljs.com/
 */
@Pipe({ name: 'numeral' })
export class NumeralPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(input: number | string | any, format?: string): string | number | null {
    // If input is falsey (and not zero)
    if (!input && input !== 0) {
      return null;
    }

    // if a substraction returns epsilon number, it will have difficulties being interpreted
    if (Math.abs(input) < 0.000001) {
      input = 0;
    }

    // If no format provided we simply return the numeric value
    if (!format) {
      // @ts-ignore
      return numeral(input).value();
    }
    // @ts-ignore
    return numeral(input).format(format);
  }
}
