import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInAnimation } from '@front/app/modules/shared/animations/fade-in.animation';
import { fadeOutAnimation } from '@front/app/modules/shared/animations/fade-out.animation';

@Component({
  selector: 'app-toggle-switch-box',
  templateUrl: './generic-toggle-switch-box.component.html',
  styleUrls: ['./generic-toggle-switch-box.component.scss'],
  animations: [fadeInAnimation('350ms'), fadeOutAnimation('250ms')]
})
export class ToggleSwitchBoxComponent implements OnInit {
  @Input() public label = '';

  @Input() public description = '';

  @Input() public disabled = false;

  @Input() public activated = false;

  @Output() activateToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public visibleTooltip = false;

  ngOnInit(): void {
    this.activated = this.disabled;
  }

  onClick(): void {
    if (!this.disabled) {
      this.activated = !this.activated;
      this.disabled = this.activated ? true : false;
      this.activateToggle.emit(this.activated);
    }
  }
}
