import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IClientConfig } from '@front/interfaces/client-config.interface';

export const ENUMS = new InjectionToken('ENUMS');
export const ACTIVE_CONFIG = new InjectionToken('ACTIVE_CONFIG');

export interface ClientEnumsConfig {
  configs: IClientConfig[];
  active?: string;
}

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class ConfigsInjectorModule {
  static forRoot(config: ClientEnumsConfig): ModuleWithProviders<any> {
    return {
      ngModule: ConfigsInjectorModule,
      providers: [
        {
          provide: ENUMS,
          useValue: config.configs
        },
        {
          provide: ACTIVE_CONFIG,
          useValue: config.active
        }
      ]
    };
  }
}
