import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeItemComponent } from '@front/app/modules/shared/tree-item/tree-item.component';
import { SharedGlobalModule } from '@front/app/modules/shared-global/shared-global.module';
import { IconDirectiveModule } from '@front/app/modules/shared-global/directives/icon/icon.directive.module';

@NgModule({
  declarations: [TreeItemComponent],
  exports: [TreeItemComponent],
  imports: [CommonModule, SharedGlobalModule, IconDirectiveModule]
})
export class TreeItemModule {}
