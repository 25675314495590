export const validatePwd = (password: string): boolean => {
  // TODO: Set a business confirmed regex for passwords
  // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  const re = /\w+/;
  return re.test(password);
};

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
