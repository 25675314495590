import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@front/app/modules/shared-global/snackbar/snackbar.service';
import { HttpStatusCode } from '@shared';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * This interceptor allow you to be redirected home in case you try to access forbidden areas
 */
@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly snackbarService: SnackbarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === HttpStatusCode.FORBIDDEN) {
          this.snackbarService.showError('Forbidden Access', 'You have attempted to access a page for which you have no access rights');
          this.router.navigate(['']);
        }
        return throwError(err);
      })
    );
  }
}
