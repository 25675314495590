import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { assertDefined, ICrossSection } from '@shared';
import { AppStateService } from '~/services/state/app-state.service';
import { SubSink } from 'subsink';
import {
  computeCsValueFromValuesArray,
  CsDimensionConfig,
  getCsDimensionsConfig
} from '~/app/modules/shared/cross-section-filters/cross-section-filters/cross-section-filters.utils';
import { map, tap } from 'rxjs/operators';

type OnChangeFn = (cs: ICrossSection | null) => undefined;
type OnTouchFn = () => undefined;

@Component({
  selector: 'app-cross-section-filters',
  templateUrl: './cross-section-filters.component.html',
  styleUrls: ['./cross-section-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrossSectionFiltersComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossSectionFiltersComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() crossSections: ICrossSection[] = [];
  @Input() color: 'primary' | 'secondary' = 'primary';

  private form = new UntypedFormArray([]);
  private onChangeFn: OnChangeFn | null = null;
  private onTouchFn: OnTouchFn | null = null;

  csDimensionConfigs: CsDimensionConfig[] = [];

  private subs = new SubSink();

  constructor(private appState: AppStateService) {}

  ngOnInit(): void {
    this.listenToValueChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { crossSections } = changes;

    if (crossSections?.currentValue) {
      this.csDimensionConfigs = getCsDimensionsConfig(crossSections.currentValue, this.appState.getConfigs()?.crossSections);
      this.initForm(this.csDimensionConfigs);
      this.setDisabledDimensions();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private listenToValueChanges(): void {
    this.subs.sink = this.form.valueChanges
      .pipe(
        tap(() => this.setDisabledDimensions()),
        map(() => this.computeCsFilter(this.form.value))
      )
      .subscribe((csFilter) => {
        assertDefined(this.onChangeFn);
        assertDefined(this.onTouchFn);
        this.onChangeFn(csFilter);
        this.onTouchFn();
      });
  }

  private computeCsFilter(value: string[]): ICrossSection | null {
    return computeCsValueFromValuesArray(value, this.csDimensionConfigs);
  }

  private setDisabledDimensions(): void {
    this.form.controls.forEach((control, i) => {
      const mustDisableDimension = !this.isPreviousDimensionSelected(this.csDimensionConfigs[i]);
      if (mustDisableDimension) {
        control.disable({ emitEvent: false });
        control.setValue(null, { emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  isPreviousDimensionSelected(dimensionConfig: CsDimensionConfig): boolean {
    const currentDimensionIndex = this.csDimensionConfigs.findIndex((searchDimension) => searchDimension === dimensionConfig);
    if (currentDimensionIndex === 0) {
      return true;
    }
    return !!this.getDimensionForm(currentDimensionIndex - 1).value;
  }

  getDimensionForm(i: number): UntypedFormControl {
    return this.form.at(i) as UntypedFormControl;
  }

  private initForm(crossSectionDimensionsConfigs: CsDimensionConfig[]) {
    this.form.clear({ emitEvent: false });
    crossSectionDimensionsConfigs.forEach(() => this.form.push(new UntypedFormControl()));
  }

  // CVA
  registerOnChange(fn: OnChangeFn): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: OnTouchFn): void {
    this.onTouchFn = fn;
  }

  writeValue(selectedCs: ICrossSection | null): void {
    this.form.controls.forEach((control, i) => {
      const csDimensionKey = this.csDimensionConfigs[i].crossSectionKey;
      const controlValue = selectedCs?.[csDimensionKey] ?? null;
      control.setValue(controlValue, { emitEvent: false });
    });
    this.setDisabledDimensions();
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }
}
