import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppStateService } from '@front/services/state/app-state.service';
import { IAggregationStructure, IReportConfig, recursiveFindArrayGeneric } from '@shared';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';

export interface IBreadcrumbPath {
  name: string;
  id: string;
  dropdown: boolean;
  children?: IBreadcrumbPath[];
  reports?: IReportConfig;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  path$: Observable<IBreadcrumbPath[]>;
  clientConfig: IClientConfig;

  constructor(private appStateService: AppStateService, @Inject(ENUMS) enums: IClientConfig) {
    this.clientConfig = enums;

    this.path$ = combineLatest([this.appStateService.selectedAggregationStructure$, this.appStateService.aggregationStructures$]).pipe(
      map(([selectedAggregationStructure, aggregationStructures]) =>
        this.generatePathFromParents(selectedAggregationStructure, aggregationStructures ?? [])
      )
    );
  }

  private generatePathFromParents(
    aggregationStructure: IAggregationStructure | null,
    aggregationStructures: IAggregationStructure[]
  ): IBreadcrumbPath[] {
    if (!aggregationStructure) {
      return [];
    }

    const aggregationStructureWithParents = recursiveFindArrayGeneric<IAggregationStructure>(
      aggregationStructures,
      (as) => as.children as IAggregationStructure[],
      (as) => as.id === aggregationStructure?.id
    );

    if (!aggregationStructureWithParents) {
      return [];
    }

    const ancestors: IAggregationStructure[] = (aggregationStructureWithParents?.ancestors as IAggregationStructure[]) ?? [];

    return [
      ...ancestors.map((ancestor) => {
        return {
          name: ancestor.name,
          id: ancestor.id,
          reports: ancestor.reports,
          dropdown: false,
          children: ancestor?.children?.map((child) => ({
            name: child.name,
            id: child.id
          })) as IBreadcrumbPath[] | undefined
        };
      }),
      {
        name: aggregationStructureWithParents.name,
        id: aggregationStructureWithParents.id,
        reports: aggregationStructureWithParents.reports,
        dropdown: false,
        children: aggregationStructure?.children?.map((child) => ({
          name: child.name,
          id: child.id
        })) as IBreadcrumbPath[] | undefined
      }
    ];
  }

  get scope() {
    return this.path$.pipe(
      map((path) => {
        if (path.length === 0) return undefined;

        return path[path.length - 1].name;
      })
    );
  }

  get bmc() {
    const countryIndex = this.clientConfig.aggregationWording.findIndex((wording) => wording === 'Country');
    const brandIndex = this.clientConfig.aggregationWording.findIndex((wording) => wording === 'Brand');

    return this.path$.pipe(
      map((path) => {
        if (path.length === 0) return undefined;

        if (path[countryIndex] && path[brandIndex]) {
          return `${path[countryIndex].name} ${path[brandIndex].name}`;
        }

        return null;
      })
    );
  }
}
