import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { INotification } from '@shared';
import { NotificationService } from '@front/app/modules/shared/notification/notification.service';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationContainerComponent implements OnDestroy {
  @Output()
  public notificationPanelStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readNotifications: INotification[] = [];
  public unreadNotifications: INotification[] = [];
  public isOpen: boolean = false;

  constructor(private notificationService: NotificationService, private elementRef: ElementRef) {
    this.notificationService.notifications$.subscribe((notifications) => {
      [this.readNotifications, this.unreadNotifications] = this.splitNotificationBetweenRead(notifications.slice(0, 10));
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen === true) {
      this.onCrossClick();
    }
    this.isOpen = true;
  }

  ngOnDestroy(): void {
    this.unreadNotifications.forEach((notification) => {
      this.notificationService.readNotification(notification);
    });
  }

  splitNotificationBetweenRead(notifications: INotification[]) {
    const readNotifications = notifications.filter((notification) => notification.isRead);
    const unreadNotifications = notifications.filter((notification) => !notification.isRead);

    return [readNotifications, unreadNotifications];
  }

  onCrossClick() {
    this.notificationPanelStateChange.emit(false);
  }
}
