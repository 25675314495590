import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureNameComponent } from './picture-name.component';
import { IconDirectiveModule } from '../../shared-global/directives/icon/icon.directive.module';

const MODULES = [CommonModule, IconDirectiveModule];

const COMPONENTS = [PictureNameComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class PictureNameModule {}
