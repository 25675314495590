import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Endpoint, ICredentials, ISSODomain, IUserLogin, validateEmail, validatePwd } from '@shared';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { TokenService } from '@front/services/token.service';

export const USER_KEY: Readonly<string> = 'mmo_user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public readonly user$$: BehaviorSubject<IUserLogin | null> = new BehaviorSubject<IUserLogin | null>(null);
  public readonly user$: Observable<IUserLogin | null> = this.user$$.asObservable();

  constructor(
    private api: ApiService,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private readonly tokenService: TokenService
  ) {
    if (this.isAuthenticated()) {
      const currentUser = this.getCurrentUser();
      this.user$$.next(currentUser);
    }
  }

  get user(): IUserLogin | null {
    return this.getUser();
  }

  public getSSODomains(): Observable<ISSODomain[]> {
    const ssoRoute = `${Endpoint.SSO}/domains`;

    return this.api.get<ISSODomain[]>(ssoRoute);
  }

  public login(credentials: ICredentials): Observable<IUserLogin | boolean> {
    if (!credentials || !this.validateCredentials(credentials)) {
      return of(false);
    }

    return this.api
      .post<IUserLogin, ICredentials>(Endpoint.LOGIN, credentials)
      .pipe(
        tap((res) => {
          if (res.token) {
            this.setUser(res);
            this.tokenService.setToken(res.token);
          } else {
            throwError('No token found in response');
          }
        })
      )
      .pipe(
        tap((res) => this.user$$.next(res)),
        catchError(() => of(false))
      );
  }

  public validateCredentials(credentials: ICredentials): boolean {
    return validatePwd(credentials.password) && validateEmail(credentials.email);
  }

  public isAuthenticated(): boolean {
    const myRawToken = this.tokenService.getToken();
    const isExpired = this.jwtHelper.isTokenExpired(myRawToken || '');
    if (myRawToken && !isExpired) {
      return true;
    }
    return false;
  }

  public disconnect() {
    this.tokenService.clearToken();
    this.user$$.next(null);
    this.router.navigate(['/auth']);
  }

  public getCurrentUser() {
    const rawToken = this.tokenService.getToken();
    let decodedToken = undefined;
    if (rawToken) {
      try {
        decodedToken = this.jwtHelper.decodeToken(rawToken);
      } catch (error) {
        // Error related to the decoding of wrong tokens
        throw new Error('Impossible to decode login information');
      }
    }
    return decodedToken;
  }

  public requestPwdResetEmail(email: string): Observable<boolean> {
    return this.api.post(Endpoint.RESET_PASSWORD, { email });
  }

  public resetPwdWithToken(resetToken: string, newPassword: string): Observable<boolean> {
    return this.api.put(`${Endpoint.RESET_PASSWORD}/reset-token?resetToken=${resetToken}`, { newPassword });
  }

  public setUser(user: IUserLogin) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  private getUser(): IUserLogin | null {
    const stringUser = localStorage.getItem(USER_KEY);

    return stringUser ? JSON.parse(stringUser) : null;
  }
}
