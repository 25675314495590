import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationComponent } from './components/notification-container/notification/notification.component';
import { SharedGlobalModule } from '@front/app/modules/shared-global/shared-global.module';
import { IconDirectiveModule } from '@front/app/modules/shared-global/directives/icon/icon.directive.module';

@NgModule({
  declarations: [NotificationContainerComponent, NotificationComponent],
  imports: [CommonModule, SharedGlobalModule, IconDirectiveModule],
  exports: [NotificationContainerComponent, NotificationComponent]
})
export class NotificationModule {}
