import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './components/snackbar-container/snackbar/snackbar.component';
import { SnackbarContainerComponent } from './components/snackbar-container/snackbar-container.component';

@NgModule({
  declarations: [SnackbarContainerComponent, SnackbarComponent],
  imports: [CommonModule],
  exports: [SnackbarContainerComponent, SnackbarComponent]
})
export class SnackbarModule {}
