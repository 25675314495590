import { Component, Input } from '@angular/core';
import { IScenario } from '@shared';

@Component({
  selector: 'app-picture-name',
  templateUrl: './picture-name.component.html',
  styleUrls: ['./picture-name.component.scss']
})
export class PictureNameComponent {
  @Input() scenario!: IScenario;

  getTagName(username: string | null | undefined) {
    if (!username) username = 'Ekimetrics';

    const splitedUsername = username.split(' ');
    if (splitedUsername.length === 1) return `${splitedUsername[0][0]}${splitedUsername[0][1]}`;

    return `${splitedUsername[0][0]}${splitedUsername[1][0]}`;
  }
}
