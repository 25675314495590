import { Injectable } from '@angular/core';
import { assertDefined, IUser, IUserLogin, Roles } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor() {}

  public hasRole(user: IUserLogin | IUser | null | undefined, role: Roles) {
    assertDefined(user, 'User is undefined');
    return user.role === role;
  }

  public hasRoleAmongArray(user: IUserLogin | IUser | null | undefined, roles: Roles[]) {
    assertDefined(user, 'User is undefined');
    for (let i = 0; i < roles.length; i++) {
      if (user.role === roles[i]) return true;
    }
    return false;
  }
}
