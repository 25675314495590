import { assertDefined, ICrossSection, ICrossSectionConfig } from '@shared';
import { uniqBy } from 'lodash';
import { ISelectOption } from '~/interfaces/generic-select.interface';

export interface CsDimensionConfig {
  label: string;
  crossSectionKey: string;
  options: ISelectOption<string | null>[];
}

export function getCsDimensionsConfig(crossSections: ICrossSection[], crossSectionConfigs?: ICrossSectionConfig[]): CsDimensionConfig[] {
  const dimensionsMap = new Map<string, CsDimensionConfig>();
  crossSections.forEach((cs) => {
    const keys = Object.keys(cs);
    keys.forEach((key) => {
      if (!dimensionsMap.has(key)) {
        dimensionsMap.set(key, { crossSectionKey: key, label: getDimensionLabel(key) ?? key, options: [{ value: null, name: 'None' }] });
      }
      const currentValue = dimensionsMap.get(key);
      assertDefined(currentValue);
      const updatedOptions = [...currentValue.options, { value: cs[key], name: cs[key] }];

      dimensionsMap.set(key, { ...currentValue, options: uniqBy(updatedOptions, (x) => x.value) });
    });
  });
  const unsortedDimensions = Array.from(dimensionsMap.values());
  if (!crossSectionConfigs) {
    return unsortedDimensions;
  }
  return unsortedDimensions.sort((a, b) => getDimensionOrder(a) - getDimensionOrder(b));

  function getDimensionOrder(dimensionConfig: CsDimensionConfig): number {
    return crossSectionConfigs?.find((csc) => csc.key === dimensionConfig.crossSectionKey)?.order ?? Infinity;
  }

  function getDimensionLabel(csKey: string): string | undefined {
    return crossSectionConfigs?.find((csc) => csc.key === csKey)?.label;
  }
}

export function getFollowingDimensions(dimension: CsDimensionConfig, dimensions: CsDimensionConfig[]): CsDimensionConfig[] {
  const currentDimensionIndex = dimensions.findIndex((searchDimension) => searchDimension === dimension);
  return dimensions.slice(currentDimensionIndex);
}

export function computeCsValueFromValuesArray(values: string[], dimensions: CsDimensionConfig[]): ICrossSection | null {
  const cs: ICrossSection = {};
  values.forEach((value, i) => {
    if (!value) return;
    const dimensionConfig = dimensions[i];
    cs[dimensionConfig.crossSectionKey] = value;
  });
  return Object.keys(cs).length ? cs : null;
}
