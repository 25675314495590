import { Inject, Pipe, PipeTransform } from '@angular/core';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ENUMS } from '../configs/configs-injector/configs-injector.module';

@Pipe({ name: 'clientWording' })
export class ClientWordingPipe implements PipeTransform {
  clientConfig: IClientConfig;

  constructor(@Inject(ENUMS) enums: IClientConfig) {
    this.clientConfig = enums;
  }

  transform(value: string | null, customRoi: string | undefined = undefined): string {
    let clientWording = '';
    if (!value) return '';
    if (value.includes('aggregation')) {
      const index = parseInt(value.split('-')[1]);
      if (index >= this.clientConfig.aggregationWording.length) {
        clientWording = 'Aggregation';
      } else {
        clientWording = this.clientConfig.aggregationWording[index];
      }
    } else {
      if (customRoi && this.clientConfig.roiPerKpi && this.clientConfig.roiPerKpi[customRoi]) {
        clientWording = this.clientConfig.roiPerKpi[customRoi];
      } else {
        clientWording = this.clientConfig.wording[value] as string;
      }
    }
    return clientWording ?? value;
  }
}
