import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const nissanConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Nissan',
  logo: 'assets/images/logo-Eki-noir.svg',
  platformName: 'PAPA',
  moduleAccess: {
    investment: true,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD', 'Orders', 'Volume', 'AED', 'SAR', 'AUD']
};
