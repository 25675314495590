import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '@front/interceptors/auth.interceptor';
import { RouteDataService } from '@front/services/route-data.service';
import { SharedGlobalModule } from '@front/app/modules/shared-global/shared-global.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { SharedModule } from './modules/shared/shared.module';
import { ConfigsInjectorModule } from './configs/configs-injector/configs-injector.module';
import { config } from './configs/config';
import { environment } from '@front/environments/environment';
import { AccessInterceptor } from '@front/interceptors/access.interceptor';
import { ErrorInterceptor } from '@front/interceptors/error.interceptor';
import { CanLoadPromo } from './modules/promo/guards/canLoadPromo';
import { IconDirectiveModule } from '@front/app/modules/shared-global/directives/icon/icon.directive.module';
import { ClientWordingPipe } from './pipes/client-wording.pipe';
import { EncodeHttpParamsInterceptor } from '@front/interceptors/http.interceptor';
import { CoreAngularModule } from '@eki-ui-kit/core-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedGlobalModule,
    NgbModule,
    SharedModule,
    IconDirectiveModule,
    ConfigsInjectorModule.forRoot({
      // error No index signature with a parameter of type 'string' was found on type of clients
      configs: (config.clients as any)[environment.client],
      active: environment.client
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [RouteDataService],
      multi: true
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    CanLoadPromo,
    ClientWordingPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
