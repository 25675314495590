import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalItems = 0;
  @Input() itemsPerPage = 0;

  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  public pages: number[] = [];
  activePage = 1;

  ngOnInit(): void {
    this.initPages();
  }

  ngOnChanges(): any {
    this.initPages();
    this.activePage = 1;
    this.pageChange.emit(1);
  }

  public initPages(): void {
    this.pages = [];
    let pagesNumber = Math.ceil(this.totalItems / this.itemsPerPage);
    for (let i = 1; i <= pagesNumber; i++) {
      this.pages.push(i);
    }
  }

  public onPageChange(pageNumber: number): void {
    if (pageNumber <= this.pages.length && pageNumber > 0) {
      this.activePage = pageNumber;
      this.pageChange.emit(pageNumber);
    }
  }
}
