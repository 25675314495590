import { Component, forwardRef, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Interval } from 'luxon';
import { IIntervalNgbDateStruct } from '~/interfaces/date.interface';

@Component({
  selector: 'app-interval-date-picker',
  templateUrl: './interval-date-picker.component.html',
  styleUrls: ['./interval-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntervalDatePickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class IntervalDatePickerComponent implements ControlValueAccessor, OnInit {
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct | null = { year: 1970, month: 10, day: 10 }; // this.calendar.getNext(this.calendar.getToday(), 'd', 5);
  startDate: NgbDateStruct;
  _interval?: Interval;
  @Input() set dateInterval(interval: Interval | null) {
    if (interval) {
      this.startDate = interval.start;
    } else {
      this.startDate = DateTime.now();
    }
    this._interval = interval ?? Interval.fromDateTimes(DateTime.now(), DateTime.now().plus({ days: 10 }));
  }
  get interval() {
    return this._interval;
  }

  _minDate = DateTime.now().minus({ years: 10 });
  @Input() set minDate(date: NgbDateStruct | null | undefined) {
    if (date) {
      this._minDate = this.computeFromDate(DateTime.fromObject(this.convertNgbDate(date)));
    }
  }

  _maxDate = DateTime.now().plus({ years: 10 });
  @Input() set maxDate(date: NgbDateStruct | null | undefined) {
    if (date) {
      this._maxDate = this.computeToDate(DateTime.fromObject(this.convertNgbDate(date)));
    }
  }

  @Output() dateIntervalChange = new EventEmitter<Interval>();

  hoveredDate: NgbDate | null = null;

  get formattedDate() {
    return `${this.interval?.start.toFormat('LLL. dd yyyy')} to ${this.interval?.end.toFormat('LLL. dd yyyy') ?? '...'}`;
  }

  public openDatePicker = false;

  @Input() public label = '';

  @Input() public optionalText = '';

  @Input() public streamChanges: boolean | 'true' = false;

  @Input() public disabled = false;

  @Input() emitOnInit = true;

  public focused = false;

  constructor(private readonly calendar: NgbCalendar) {
    this.fromDate = this.calendar.getToday();
    this.startDate = this.calendar.getToday();
  }

  ngOnInit(): void {
    const fromDate = this.computeFromDate(DateTime.fromObject(this.convertNgbDate(this.fromDate)));
    const toDate = this.computeToDate(DateTime.fromObject(this.convertNgbDate(this.toDate!)));

    if (this.emitOnInit) {
      this.onIntervalSelection(Interval.fromDateTimes(fromDate, toDate));
    }
  }

  isHovered(date: NgbDate): boolean | null {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate): boolean | null {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean | null {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  onDateSelection(date: NgbDate): void {
    const dateTime = DateTime.fromObject(this.convertNgbDate(date));
    if (!this.fromDate && !this.toDate) {
      this.fromDate = this.computeFromDate(dateTime);
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = this.computeToDate(dateTime);
    } else {
      this.toDate = null;
      this.fromDate = this.computeFromDate(dateTime);
    }
    if (this.fromDate && this.toDate) {
      const fromDate = this.computeFromDate(DateTime.fromObject(this.convertNgbDate(this.fromDate)));
      const toDate = this.computeToDate(DateTime.fromObject(this.convertNgbDate(this.toDate)));
      this.onIntervalSelection(Interval.fromDateTimes(fromDate, toDate));
    }
  }

  private computeFromDate(from: DateTime): DateTime {
    // 1 is Monday
    if (from.weekday != 1) {
      from = from.minus({ days: from.weekday - 1 });
    }
    return from;
  }

  private computeToDate(to: DateTime): DateTime {
    // 7 is Sunday
    if (to.weekday != 7) {
      to = to.plus({ days: 7 - to.weekday });
    }
    return to;
  }

  private convertNgbDate(date: NgbDate | NgbDateStruct) {
    return { year: date.year, month: date.month, day: date.day };
  }

  private onIntervalSelection(interval: Interval) {
    this.updateDate(interval);
    this.dateIntervalChange.emit(interval);
    this.openDatePicker = false;
  }

  private updateDate(interval: Interval | null): void {
    if (interval) {
      this.innerValue = { fromDate: interval.start, toDate: interval.end };
      this.fromDate = interval.start;
      this.toDate = interval.end;
      this.onChange();
    }
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: IIntervalNgbDateStruct | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  /* CVA */
  writeValue(_value: IIntervalNgbDateStruct): void {
    this.updateDate(this.dateInterval);
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
