import { Pipe, PipeTransform } from '@angular/core';
import { ICrossSection } from '@shared';

@Pipe({
  name: 'crossSections'
})
export class CrossSectionsPipe implements PipeTransform {
  transform(crossSections?: ICrossSection[] | ICrossSection | null, limitTo?: number, noCrossSectionPlaceholder = ''): string {
    if (!crossSections) {
      return noCrossSectionPlaceholder;
    }

    let inputCrossSections = Array.isArray(crossSections) ? crossSections : [crossSections];
    if (inputCrossSections.length === 0) {
      return noCrossSectionPlaceholder;
    }

    let mustEncodeEllipsis = false;
    if (typeof limitTo === 'number') {
      mustEncodeEllipsis = inputCrossSections.length > limitTo;
      inputCrossSections = inputCrossSections.slice(0, limitTo);
    }

    const encodedCrossSections = inputCrossSections.map(this.encodeCrossSection).join(' ; ');
    return `${encodedCrossSections}${mustEncodeEllipsis ? ' ...' : ''}`;
  }

  private encodeCrossSection(cs: ICrossSection): string {
    return Object.values(cs).join(' - ');
  }
}
