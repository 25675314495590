export enum Mechanic {
  'ri>34' = 'ri>34',
  'carte' = 'carte',
  'ri<34' = 'ri<34',
  'lv<67' = 'lv<67',
  '3e_gratuit' = '3e_gratuit',
  'lv>67' = 'lv>67',
  'autre' = 'autre',
  'gratuité' = 'gratuité'
}

export const MechanicsMap: { [key in Mechanic]: { color: string; label: string; generosity: number; min: number; max: number } } = {
  '3e_gratuit': {
    label: '3e gratuit',
    color: '#536AAE',
    generosity: 33.3,
    min: 33.3,
    max: 33.3
  },
  autre: {
    label: 'Autre',
    color: '#53AE96',
    generosity: 100,
    min: 0,
    max: 100
  },
  carte: {
    label: 'Carte',
    color: '#2EADD1',
    generosity: 30,
    min: 0,
    max: 100
  },
  'lv<67': {
    label: 'LV < 67',
    color: '#D09A68',
    generosity: 30,
    min: 0,
    max: 66.9999999 / 2
  },
  'lv>67': {
    label: 'LV ≥ 67',
    color: '#8097E9',
    generosity: 34,
    min: 67 / 2,
    max: 100
  },
  'ri>34': {
    label: 'RI ≥ 34',
    color: '#5397AE',
    generosity: 34,
    min: 34,
    max: 100
  },
  'ri<34': {
    label: 'RI < 34',
    color: '#DB7582',
    generosity: 30,
    min: 0,
    max: 33.9999999
  },
  gratuité: {
    label: 'Gratuité',
    color: '#aab248',
    generosity: 0,
    min: 0,
    max: 100
  }
};

export const MechanicsSmpMap: { [key: string]: { color: string; label: string } } = {
  'ri 8%': {
    label: 'RI 8%',
    color: '#8097E9'
  },
  'ri 10%': {
    label: 'RI 10%',
    color: '#8097E9'
  },
  'ri 15%': {
    label: 'RI 15%',
    color: '#8097E9'
  },
  'ri 20%': {
    label: 'RI 20%',
    color: '#8097E9'
  },
  'ri 24%': {
    label: 'RI 24%',
    color: '#8097E9'
  },
  'ri 25%': {
    label: 'RI 25%',
    color: '#8097E9'
  },
  'ri 30%': {
    label: 'RI 30%',
    color: '#8097E9'
  },
  'ri 33%': {
    label: 'RI 33%',
    color: '#8097E9'
  },
  'ri 34%': {
    label: 'RI 34%',
    color: '#8097E9'
  },
  'ri 35%': {
    label: 'RI 35%',
    color: '#8097E9'
  },
  'ri 40%': {
    label: 'RI 40%',
    color: '#8097E9'
  },
  'ri 50%': {
    label: 'RI 50%',
    color: '#8097E9'
  },
  'ri 68%': {
    label: 'RI 68%',
    color: '#8097E9'
  },
  'ri 100%': {
    label: 'RI 100%',
    color: '#8097E9'
  },
  '2 + 1': {
    label: '2 + 1',
    color: '#5398AE'
  },
  '4 + 2': {
    label: '4 + 2',
    color: '#5398AE'
  },
  '1 + 1': {
    label: '1 + 1',
    color: '#5398AE'
  },
  '3 + 1': {
    label: '3 + 1',
    color: '#5398AE'
  },
  'lv 2e -25%': {
    label: 'LV 2E -25%',
    color: '#D09A68'
  },
  'lv 2e -40%': {
    label: 'LV 2E -40%',
    color: '#D09A68'
  },
  'lv 2e -50%': {
    label: 'LV 2E -50%',
    color: '#D09A68'
  },
  'lv 2e -60%': {
    label: 'LV 2E -60%',
    color: '#D09A68'
  },
  'lv 2e -66%': {
    label: 'LV 2E -66%',
    color: '#D09A68'
  },
  'lv 2e -68%': {
    label: 'LV 2E -68%',
    color: '#D09A68'
  },
  'lv 2e -70%': {
    label: 'LV 2E -70%',
    color: '#D09A68'
  },
  'carte 20%': {
    label: 'CARTE 20%',
    color: '#DB7582'
  },
  'carte 25%': {
    label: 'CARTE 25%',
    color: '#DB7582'
  },
  'carte 30%': {
    label: 'CARTE 30%',
    color: '#DB7582'
  },
  'carte 34%': {
    label: 'CARTE 34%',
    color: '#DB7582'
  },
  'carte 35%': {
    label: 'CARTE 35%',
    color: '#DB7582'
  },
  'carte 40%': {
    label: 'CARTE 40%',
    color: '#DB7582'
  },
  'carte 50%': {
    label: 'CARTE 50%',
    color: '#DB7582'
  },
  autre: {
    label: 'AUTRE',
    color: '#C6B68E'
  },
  gratuité: {
    label: 'GRATUITE',
    color: '#C6B68E'
  }
};
