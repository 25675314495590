import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ColorPickerComponent implements OnInit {
  @Input() color = '#FFFFFF';
  @Output() colorChange: EventEmitter<string> = new EventEmitter();

  @Input() colorPalette = [
    '#7C0000',
    '#FF0000',
    '#FFAAAA',
    '#9A4600',
    '#FE9238',
    '#FFCEA5',
    '#BFB809',
    '#F5EC07',
    '#FFFBA3',
    '#9CB602',
    '#D4F507',
    '#4B9700',
    '#92D050',
    '#D9FFB1',
    '#187600',
    '#6AD050',
    '#227763',
    '#59E4C3',
    '#B2FCEA',
    '#006C93',
    '#00B0F0',
    '#9CE5FF',
    '#00376A',
    '#2985DA',
    '#88C3FA',
    '#2B1986',
    '#5232EE',
    '#A28FFD',
    '#7030A0',
    '#DBABFF',
    '#6D0957',
    '#D53AB3',
    '#FFBEF1',
    '#81003E',
    '#ED0B77',
    '#000000',
    '#888888',
    '#DDDDDD'
  ];
  open = false;

  @Input() public label = '';

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.colorChanged(this.color);
  }

  handleChangeComplete(event: ColorEvent) {
    if (event.color.hex) {
      this.color = event.color.hex.toUpperCase();
    }
    this.colorChanged(this.color);
  }

  colorChanged(color: string): void {
    this.color = color.toUpperCase();
    this.innerValue = color.toUpperCase();
    this.colorChange.emit(this.color);

    this.onChange();
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.ref.markForCheck();
    this.propagateChange(this.innerValue);
  }

  /* CVA */
  writeValue(value: string): void {
    this.colorChanged(value);
  }

  /* CVA */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /* CVA */
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
