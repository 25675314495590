import { MMOModelUnitType } from '@shared';
import { Type } from '@sinclair/typebox';

export const postAggregateModelInitBodySchema = Type.Object({
  unitType: Type.Enum(MMOModelUnitType),
  aggregationStructureId: Type.String(),
  name: Type.String()
});

export const postAggregateModelFinaliseBodySchema = Type.Object({
  modelId: Type.String(),
  aggregationStructureId: Type.String(),
  aggregatedInvestments: Type.Array(
    Type.Object({
      investmentName: Type.String(),
      aggregationStructureIds: Type.Array(Type.String()),
      sourceAggregationStructureIds: Type.Array(Type.String())
    })
  )
});
