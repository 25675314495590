import { IAggregationStructure, VariableMetric, MMOModelUnitType, ICrossSection } from '@shared';
import { DataAggregationLevel, DataAggregationSearchedData } from '../../enums/data-aggregation.enum';
import { ILever } from '../business/lever.business.interface';
import { ITimeDataPoint } from '../business/time-series.business.interface';

export interface IDataAggregationQueryParams {
  startDate: Date;
  endDate: Date;
  level?: number;
  isActionable?: boolean;
  isEditable?: boolean;
  typeSelected?: MMOModelUnitType;
  isInvestment?: boolean;
  timeSeries?: boolean;
  subLevers?: boolean;
  isVisible?: boolean;
  metric?: string | RegExp;
  crossSection?: ICrossSection | null;
  isExploded?: boolean;
  aggregateByCrossSectionName?: string;
}

export interface IDataAggregationParams extends IDataAggregationQueryParams {
  id: string;
  modelId?: string;
  aggregationLevel: DataAggregationLevel;
  searchedData: DataAggregationSearchedData;
}

export interface IPromoDataAggregationParams {
  timeSerieVariant: TimeSerieVariant;
  startDate?: Date;
  endDate?: Date;
  retailerId?: string;
  categoryId?: string;
  mechanic?: string;
}

export enum TimeSerieVariant {
  LEVER = 'LEVER',
  TYPE = 'TYPE'
}

export interface IDataAggregation {
  aggregationStructure?: Pick<IAggregationStructure, 'id' | 'name' | 'color'>;
  lever?: Pick<ILever, 'id' | 'name' | 'color' | 'order' | 'isActionable'>;
  variableId?: string;
  leverId?: string;
  timeSeries?: ITimeDataPoint[];
  total: number;
  weeksWithInvestments?: number;
  unit: string;
  children?: IDataAggregation[];
  isActionable?: boolean;
  metric?: VariableMetric;
  forecastCpm?: number;
  referenceCpm?: number;
  aggregateByCrossSectionName?: string;
}

export interface ISettingsDataAggregation {
  lever?: Pick<ILever, 'id' | 'name' | 'color' | 'order'>;
  data: ISettingsDataAggregationData[];
  children?: ISettingsDataAggregation[];
}

export interface ISettingsDataAggregationData {
  aggregationStructure: Pick<IAggregationStructure, 'id' | 'name' | 'color'>;
  total: number;
}

export interface IEvolutionPercentage {
  leverId?: string;
  isActionable: boolean;
  leverOldPercentage: number;
  leverNewPercentage: number;
  subLevers: IEvolutionPercentage[] | null;
}
