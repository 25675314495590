import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';
import { defaultConfig } from './default.config';

export const unileverConfig: IClientConfig = {
  ...defaultConfig,
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit],
  roiPerKpi: {
    [MMOModelUnitType.turnover]: 'ROI',
    [MMOModelUnitType.volume]: 'ROI',
    [MMOModelUnitType.profit]: 'ROI'
  },
  wording: {
    ...defaultConfig.wording,
    turnover: 'Turnover'
  }
};
