export enum Endpoint {
  HEALTHCHECK = '/healthcheck',
  HOME = '/home',
  AGGREGATION_STRUCTURE = '/aggregation-structures',
  LEVER = '/levers',
  LOGIN = '/login',
  SSO = '/login-sso',
  MMO_MODEL = '/mmo-models',
  SCENARIO = '/scenarios',
  TIMESERIES = '/time-series',
  USER = '/users',
  VARIABLE = '/variables',
  RESPONSE_CURVE = '/response-curves',
  CURVES = '/curves',
  RESET_PASSWORD = '/reset-password',
  DATA_AGGREGATION = '/data-aggregations',
  NOTIFICATION = '/notifications',
  POWER_BI = '/power-bi',
  DIGITAL_FOCUS = '/digital-focus',
  DEEP_DIVE = '/deep-dive',
  PROMOTION = '/promotions',
  PROMO_TIME_SERIES = '/promo-time-series',
  PROMO_IMPACT = '/promo-impact',
  PROMO_COMPETITORS = '/promo-competitors',
  CATEGORY = '/categories',
  RETAILER = '/retailers',
  PROMO_SCENARIO = '/promo-scenarios',
  SEGMENT = '/segments',
  CHARTS = '/charts',
  ACCESS = '/access',
  CONFIG = '/config',
  INVESTMENT = '/investment',
  FEATURE = '/feature',
  OPTIMAL_WEEKLY = '/optimal-weekly',
  INJECTION = '/injections',
  EXPORT_LOG = '/export-log',
  LOG = '/log'
}
