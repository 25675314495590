import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';
import { MMOModelUnitType } from '@shared';

export const forresterConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Renault',
  logo: 'assets/images/Logo_renault.svg',
  platformName: 'MEM Platform',
  platformLogo: 'assets/images/mem-platform-logo.svg',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: true,
    displayAvm: false,
    configurationAsClient: true,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    turnover: 'Turnover',
    profit: 'Profit'
  },
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit],
  sso: {
    azure: true
  }
};
