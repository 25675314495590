import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ROUTER_DEFAULT_PATH } from '@front/utils/variables';

@Injectable()
export class CanLoadPromo {
  clientConfig: IClientConfig;

  constructor(@Inject(ENUMS) enums: IClientConfig, private router: Router) {
    this.clientConfig = enums;
  }

  canLoad(): boolean {
    if (this.clientConfig.moduleAccess.promo) {
      return true;
    }

    this.router.navigate([ROUTER_DEFAULT_PATH]);
    return false;
  }
}
