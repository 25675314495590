// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

:host {
  display: block;
}

.snackBar-container {
  position: fixed;
  color: white;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  z-index: 10050;
}

.right {
  right: 12px;
}

.bottom {
  bottom: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
