import { Component, Input } from '@angular/core';
import { IScenarioTag } from '@front/interfaces/scenario-tags.interface';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  @Input() tags: IScenarioTag[] = [];

  createTagClass(tag: IScenarioTag): string {
    return 'tag-' + tag.tagType + (tag.tagType === 'status' ? '-' + tag.status : '');
  }
}
