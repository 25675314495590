import { Injectable } from '@angular/core';
import { ApiService } from '@front/services/api.service';
import { IApiRestEndpointService } from '@front/services/api/api-endpoint-service.interface';
import {
  Endpoint,
  IAggregationStructure,
  IApiAggregationStructure,
  ICreateAggregationStructure,
  IUpdateAggregationStructure
} from '@shared';
import { Observable, ReplaySubject } from 'rxjs';
import { AggregationStructureDataConverter } from '@front/services/api-data-converters/aggregation-structure';
import { map } from 'rxjs/operators';
import { AppStateService } from '../state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AggregationStructureService
  implements IApiRestEndpointService<IAggregationStructure, ICreateAggregationStructure, IUpdateAggregationStructure>
{
  readonly endpoint = Endpoint.AGGREGATION_STRUCTURE;
  private converter = new AggregationStructureDataConverter();
  rootAggregationStructures: ReplaySubject<IAggregationStructure[]> | null = null;

  constructor(private apiService: ApiService, private appState: AppStateService) {}

  create(createData: ICreateAggregationStructure): Observable<IAggregationStructure> {
    return this.apiService
      .post<IApiAggregationStructure, ICreateAggregationStructure>(this.endpoint, createData)
      .pipe(map((data) => this.converter.fromApi(data)));
  }

  delete(id: string): Observable<boolean> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  getAll(queryParams: any = {}): Observable<IAggregationStructure[]> {
    return this.apiService
      .getAll<IApiAggregationStructure>(this.endpoint, queryParams)
      .pipe(map((elements) => elements.map((data) => this.converter.fromApi(data))));
  }

  getById(id: string, queryParams: any = {}): Observable<IAggregationStructure> {
    return this.apiService
      .get<IApiAggregationStructure>(`${this.endpoint}/${id}`, queryParams)
      .pipe(map((data) => this.converter.fromApi(data)));
  }

  update(id: string, updateData: Partial<IUpdateAggregationStructure>): Observable<IAggregationStructure> {
    return this.apiService
      .put<IApiAggregationStructure, Partial<IUpdateAggregationStructure>>(`${this.endpoint}/${id}`, updateData)
      .pipe(map((data) => this.converter.fromApi(data)));
  }

  getRootAggregationStructures(): Observable<IAggregationStructure[]> {
    if (!this.rootAggregationStructures) {
      this.rootAggregationStructures = new ReplaySubject<IAggregationStructure[]>();
      this.getAll({ parentId: null }).subscribe((agg) => {
        this.rootAggregationStructures?.next(agg);
        this.appState.setAggregationStructures(agg);
      });
    }
    return this.rootAggregationStructures;
  }

  resetRootAggregationStructures(): void {
    if (this.rootAggregationStructures) {
      this.rootAggregationStructures.unsubscribe();
      this.rootAggregationStructures = null;
    }
  }

  getHistoricalEndDateForMany(aggregationIds: string[], excludeArchivedModel: boolean): Observable<{ historyEndDate: Date }> {
    return this.apiService.get<{ historyEndDate: Date }>(`${this.endpoint}/historical-dates`, {
      ids: aggregationIds,
      excludeArchivedModel
    });
  }

  getConcernedBrandsAndPromotions(structure: IAggregationStructure) {
    const recursiveFindBrands = (element: IAggregationStructure): IAggregationStructure[] => {
      let brands = [element] as IAggregationStructure[];
      if (element.children && element.children.length > 0) {
        for (const child of element.children) {
          brands = [...brands, ...recursiveFindBrands(child)];
        }
      }
      return brands;
    };

    return recursiveFindBrands(structure);
  }

  getAllByCategoryId(id: string): Observable<IAggregationStructure[]> {
    return this.apiService.get<IAggregationStructure[]>(`${this.endpoint}/by-category-id`, { id });
  }
}
