import { Component, Directive, forwardRef, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable quote-props */

/**
 *
 */
@Directive({
  host: { class: 'eki-form-control' }
})
abstract class FormControlComponent<T> implements ControlValueAccessor {
  @Input() readOnly = false;

  @Input() appearance: 'focus-outlined' | 'none' = 'none';

  @Input() disabled = false;

  value?: T;

  @HostBinding('class.eki-control-focus')
  hasFocus = false;

  @HostBinding('class.eki-control-focus-outlined')
  get focusOutlinedAppearance(): boolean {
    return this.appearance === 'focus-outlined';
  }

  abstract writeValue(value: T): void;

  abstract onChange(value: string): void;

  updateOnChange: (value: T) => void = (_) => {};

  updateOnTouched: () => void = () => {};

  registerOnChange(fn: (value: T) => void): void {
    this.updateOnChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.updateOnTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onFocus(): void {
    this.hasFocus = true;
  }

  onBlur(): void {
    this.hasFocus = false;
    this.updateOnTouched();
  }
}

/**
 *
 */
@Component({
  selector: 'eki-input-text',
  template: `<input
    type="text"
    [value]="value"
    [disabled]="disabled"
    [readOnly]="readOnly"
    (input)="onChange($any($event).target.value)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  /> `,
  styleUrls: ['input.components.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTextComponent), multi: true }],
  encapsulation: ViewEncapsulation.None
})
export class InputTextComponent extends FormControlComponent<string> {
  onChange(value: string): void {
    this.updateOnChange(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }
}

/**
 *
 */
@Component({
  selector: 'eki-input-number',
  template: `<input
    type="number"
    [value]="value"
    [disabled]="disabled"
    [readOnly]="readOnly"
    (input)="onChange($any($event).target.value)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  /> `,
  styleUrls: ['input.components.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputNumberComponent), multi: true }],
  encapsulation: ViewEncapsulation.None
})
export class InputNumberComponent extends FormControlComponent<number> {
  onChange(value: string): void {
    this.updateOnChange(parseFloat(value));
  }

  writeValue(value: number): void {
    this.value = value;
  }
}

/*
@Component({
  selector: 'eki-checkbox',
  template: `
    <input type="checkbox"/>
  `,
  styleUrls: ['input.components.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent extends FormControlComponent<boolean> {
}
*/
