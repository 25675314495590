import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';
import { MMOModelUnitType } from '@shared';

export const axaConfig: IClientConfig = {
  ...defaultConfig,
  name: 'AXA',
  logo: 'assets/images/logo-axa.svg',
  platformName: 'MMO Platform',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit, MMOModelUnitType.decisions],
  wording: {
    ...defaultConfig.wording,
    turnover: 'TO',
    volume: 'NB',
    profit: 'APE',
    decisions: 'VOL'
  },
  powerBiSendBMC: true,
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD', 'NB']
};
