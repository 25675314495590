import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const accorConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Accor',
  logo: 'assets/images/logo-accor.svg',
  platformName: 'MMO Platform (Sandbox version)',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: false,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  }
};
