import { ILever, ITimeDataPoint } from '..';
import { BusinessObject, CreateBusinessObject } from './business.interface';
import { IPromotion } from './promotion.business.interface';

export enum PromoTimeSeriesType {
  promo = 'promo',
  nonPromo = 'sales_without_promo',
  withPromo = 'sales_with_promo',
  baseline = 'baseline'
}

export interface IPromoTimeSeries extends BusinessObject {
  promotionId: string | null;
  promotion?: IPromotion;
  type: PromoTimeSeriesType;
  leverId: string | null;
  lever?: ILever;
  series: ITimeDataPoint[];
  startDate: Date;
  endDate: Date;
}

type ReadOnlyProperties = Pick<IPromoTimeSeries, 'promotionId'>;
export type ICreatePromoTimeSeries = CreateBusinessObject<IPromoTimeSeries, ReadOnlyProperties>;
