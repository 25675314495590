import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import numeral from 'numeral';

@Component({
  selector: 'app-input',
  templateUrl: './generic-input.component.html',
  styleUrls: ['./generic-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent<T = string | Date | number> implements ControlValueAccessor {
  @Input()
  public step = 1;

  @Input()
  public height?: number;

  @Input()
  public toKilo = false;

  @Input()
  public label = '';

  @Input()
  public optionalText = '';

  @Input()
  public icon = '';

  @Input()
  public placeholder = '';

  @Input()
  public type = 'text';

  @Input()
  public inputStyle: 'outlined' | 'flat' = 'outlined';

  @Input()
  public autocomplete = 'off';

  @Input()
  public name: string | undefined;

  @Input()
  public streamChanges: boolean | 'true' = false;

  @Input() hint: string | null = null;

  @Input()
  public disabled = false;

  @Input()
  public numberFormat?: string;

  @Input()
  public unit = '';

  @Input()
  public isEditing = true;

  public focused = false;

  /* CVA */ innerValue: string | number | null = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  /* CVA */
  writeValue(value: T): void {
    // Date is instance of Date
    if (this.type === 'date' && value instanceof Date) {
      this.innerValue = value.toISOString().split('T')[0];
    }
    // Date is ISO string (default JSON serialization)
    else if (this.type === 'date' && typeof value === 'string' && value.includes('T')) {
      this.innerValue = value.split('T')[0];
    }
    //
    else if (this.type === 'number' && typeof value === 'number') {
      this.innerValue = value;
    }
    //
    else if (typeof value === 'string') {
      this.innerValue = value;
    }
    //
    else if (value === null) {
      this.innerValue = null;
    }
    //
    else {
      console.warn('Unexpected input', this.type, value);
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private getFormatedNumeral(value: number) {
    return numeral(this.toKilo && value >= 1000 ? value / 1000 : value).format(this.numberFormat);
  }

  public toNumberInput() {
    if (this.type === 'number') {
      const numberValue = this.innerValue as number;
      if (this.innerValue === 0) {
        return 0;
      }
      return this.getFormatedNumeral(numberValue) + (this.toKilo && numberValue >= 1000 ? 'K' : '') + this.unit;
    } else {
      return typeof this.innerValue;
    }
  }
}
