import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BorderLeftColorDirective } from './directives/border-left-color.directive';
import { SnackbarModule } from './snackbar/snackbar.module';
import { ShowIfPermittedDirective } from './directives/show-if-permitted.directive';

@NgModule({
  imports: [CommonModule],
  exports: [BorderLeftColorDirective, SnackbarModule, ShowIfPermittedDirective],
  declarations: [BorderLeftColorDirective, ShowIfPermittedDirective]
})
export class SharedGlobalModule {}
