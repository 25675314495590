import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SnackBar } from '@front/app/modules/shared-global/snackbar/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: []
})
export class SnackbarComponent {
  @Input()
  snackbar!: SnackBar;
}
