export enum VariableMetric {
  grp = 'grp',
  clicks = 'clicks',
  impressions = 'impressions',
  sample = 'sample',
  calls = 'calls',
  circulations = 'circulations',
  views = 'views',
  reach = 'reach',
  none = 'none'
}
