import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';
import { MMOModelUnitType } from '@shared';

export const heinekenConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Heineken',
  platformName: 'MMO Platform',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: true,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    turnover: 'By Campaign',
    volume: 'By Lever'
  },
  aggregationWording: ['Division', 'Product', 'Country', 'Brand'],
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume]
};
