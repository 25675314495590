import { ITreeNode } from '../interfaces/utils/tree.interface';
import { max } from './math';

// generic tree structure helper methods

export function recursiveApplyGeneric<T>(node: T, childrenFn: (node: T) => T[], applyFn: (arg: T) => void): T {
  if (!node) {
    return node;
  }

  applyFn(node);

  const children = childrenFn(node);

  if (!children || !children?.length) {
    return node;
  }

  children.forEach((c) => recursiveApplyGeneric(c as T, childrenFn, applyFn));

  return node;
}

export function recursiveApplyArrayGeneric<T>(nodes: T[], childrenFn: (node: T) => T[], applyFn: (arg: T) => void): T[] {
  for (const node of nodes) {
    recursiveApplyGeneric<T>(node, childrenFn, applyFn);
  }
  return nodes;
}

export function recursiveMapGeneric<T, MappedT>(
  node: T,
  childrenFn: (node: T) => T[],
  mapFn: (arg: T, parent: T | null, depth: number) => MappedT,
  depth = 0,
  parent: T | null = null
): MappedT {
  const mappedNode = mapFn(node, parent, depth);

  const children = childrenFn(node);

  if (!children || !children?.length) {
    return mappedNode;
  }

  const mappedChildren = children.map((c) => recursiveMapGeneric(c as T, childrenFn, mapFn, depth + 1, node));

  return { ...mappedNode, children: mappedChildren };
}

export function recursiveMapArrayGeneric<T, MappedT>(
  nodes: T[],
  childrenFn: (node: T) => T[],
  mapFn: (arg: T, parent: T | null, depth: number) => MappedT
): MappedT[] {
  const mappedNodes = nodes.map((node) => recursiveMapGeneric<T, MappedT>(node, childrenFn, mapFn));
  return mappedNodes;
}

/**
 * Finds a node in the tree.
 * WARNING: not optimal, do not use for big trees without improving it ( it will traverse the whole tree all the time )
 * @param node
 * @param childrenFn the field of T containing the children
 * @param findFn
 */
export function recursiveFindGeneric<T>(node: T, childrenFn: (node: T) => T[], findFn: (arg: T) => boolean): T | null {
  let returnValue: T | null = null;
  let found = false;
  recursiveApplyGeneric<T>(node, childrenFn, (arg) => {
    if (!found && findFn(arg)) {
      returnValue = arg;
      found = true;
    }
  });
  return returnValue;
}

export function recursiveFindArrayGeneric<T>(nodes: T[], childrenFn: (node: T) => T[], findFn: (arg: T) => boolean): T | null {
  for (const node of nodes) {
    const foundValue = recursiveFindGeneric<T>(node, childrenFn, findFn);
    if (foundValue) {
      return foundValue;
    }
  }
  return null;
}

export function maxDepth<T>(nodes: T[], childrenFn: (node: T) => T[]): number {
  if (!nodes || nodes.length === 0) {
    return 0;
  }
  return 1 + max(nodes.map((node) => maxDepth(childrenFn(node), childrenFn)));
}

// ITreeNode helper methods

/**
 * Recursive count of children;
 *
 * Ex:
 * Used to calculate the SVG line offset between buisiness line cards.
 */
export function countChildren(node: ITreeNode, depth: number): number {
  if (!node?.expand) {
    return 0;
  }

  if (!node?.children || !node?.children?.length) {
    return 0;
  }

  let count = node.children.length;

  let i = 1;
  const len = node.children.length;
  for (const sub of node.children) {
    // If this this a first depth case, we skip the last child's children
    if (depth === 0 && len === i++) {
      break;
    }

    count += countChildren(sub, depth + 1);
  }

  return count;
}

/**
 * Recursive apply a function to all children in the tree
 */
export function recursiveApply<T extends ITreeNode>(node: T, applyFn: (arg: T) => void): T {
  return recursiveApplyGeneric<T>(node, (t) => t.children as T[], applyFn);
}

/**
 * Recursive apply a function to all children in the tree forest
 */
export function recursiveApplyArray<T extends ITreeNode>(nodes: T[], applyFn: (arg: T) => void): T[] {
  return recursiveApplyArrayGeneric<T>(nodes, (t) => t.children as T[], applyFn);
}

export function recursiveMapArray<T extends ITreeNode, MappedT extends ITreeNode>(
  nodes: T[],
  mapFn: (arg: T, parent: T | null, depth: number) => MappedT
): MappedT[] {
  return recursiveMapArrayGeneric<T, MappedT>(nodes, (t) => t.children as T[], mapFn);
}

/**
 * Finds a node in the tree.
 * WARNING: not optimal, do not use for big trees without improving it ( it will traverse the whole tree all the time )
 * @param node
 * @param findFn
 */
export function recursiveFind<T extends ITreeNode>(node: T, findFn: (arg: T) => boolean): T | null {
  return recursiveFindGeneric<T>(node, (t) => t.children as T[], findFn);
}

/**
 * Finds a node in a forest, i.e. an array of trees.
 * WARNING: not optimal, do not use for big trees without improving it ( it will traverse the whole tree all the time )
 * @param node
 * @param findFn
 */
export function recursiveFindArray<T extends ITreeNode>(nodes: T[], findFn: (arg: T) => boolean): T | null {
  return recursiveFindArrayGeneric<T>(nodes, (t) => t.children as T[], findFn);
}
