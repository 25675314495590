import { NgModule } from '@angular/core';
import { CrossSectionFiltersComponent } from './cross-section-filters/cross-section-filters.component';
import { SelectModule } from '~/app/modules/shared/select/generic-select/generic-select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlAccessorDirectiveModule, CoreAngularModule } from '@eki-ui-kit/core-angular';

@NgModule({
  declarations: [CrossSectionFiltersComponent],
  imports: [SelectModule, ReactiveFormsModule, CoreAngularModule, ControlAccessorDirectiveModule],
  exports: [CrossSectionFiltersComponent]
})
export class CrossSectionFiltersModule {}
