export enum ApplicationModules {
  pastPerf = 'Past performance',
  scenarios = 'Scenarios',
  optimization = 'Optimization',
  gapAnalysis = 'Gap analysis',
  reports = 'Reports',
  configuration = 'Configuration',
  focusRoi = 'Focus ROIs',
  focusEffectiveness = 'Focus Effectiveness',
  haloEffects = 'Halo Effects',
  nestedRoi = 'Nested ROI',
  bubbleChart = 'Bubble Chart',
  optimalWeeklyInvestments = 'Optimal weekly investments',
  createSimulation = 'Create simulation',
  createOptimization = 'Create optimization',
  scenarioManualUpload = 'Scenario manual upload',
  performanceBreakdownScenario = 'Performance breakdown',
  costPerMetric = 'Cost per metric modification'
}
