import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { IAppState } from '@front/services/state/app-state.interface';
import { Observable } from 'rxjs';
import { IAggregationStructure, IConfig, ILever, IScenario } from '@shared';

const DEFAULT_APP_STATE: IAppState = {
  aggregationStructure: { all: null, selected: null },
  scenario: { all: null, selected: null },
  lever: { all: null },
  config: { all: null }
};

@Injectable({
  providedIn: 'root'
})
export class AppStateService extends ComponentStore<IAppState> {
  constructor() {
    super(DEFAULT_APP_STATE);
  }

  // Selectors

  readonly aggregationStructures$: Observable<IAggregationStructure[] | null> = this.select((state) => state.aggregationStructure.all);
  getAggregationStructures(): IAggregationStructure[] | null {
    return this.get((state) => state.aggregationStructure.all);
  }

  readonly selectedAggregationStructure$: Observable<IAggregationStructure | null> = this.select(
    (state) => state.aggregationStructure.selected
  );
  getSelectedAggregationStructure(): IAggregationStructure | null {
    return this.get((state) => state.aggregationStructure.selected);
  }

  readonly scenarios$: Observable<IScenario[] | null> = this.select((state) => state.scenario.all);
  getScenarios(): IScenario[] | null {
    return this.get((state) => state.scenario.all);
  }

  readonly selectedScenario$: Observable<IScenario | null> = this.select((state) => state.scenario.selected);
  getSelectedScenario(): IScenario | null {
    return this.get((state) => state.scenario.selected);
  }

  readonly levers$: Observable<ILever[] | null> = this.select((state) => state.lever.all);
  getLevers(): ILever[] | null {
    return this.get((state) => state.lever.all);
  }

  readonly configs$: Observable<IConfig | null> = this.select((state) => state.config.all);
  getConfigs(): IConfig | null {
    return this.get((state) => state.config.all);
  }

  // State manipulation

  // SelectedAggregationStructure
  readonly setSelectedAggregationStructure = this.updater((state, aggregationStructure: IAggregationStructure | null) => ({
    ...state,
    aggregationStructure: { ...state.aggregationStructure, selected: aggregationStructure }
  }));
  resetSelectedAggregationStructure() {
    this.setSelectedAggregationStructure(null);
  }

  // AggregationStructures
  readonly setAggregationStructures = this.updater((state, aggregationStructures: IAggregationStructure[] | null) => ({
    ...state,
    aggregationStructure: { ...state.aggregationStructure, all: aggregationStructures }
  }));
  resetAggregationStructures() {
    this.setAggregationStructures(null);
  }

  // SelectedScenario
  readonly setSelectedScenario = this.updater((state, scenario: IScenario | null) => ({
    ...state,
    scenario: { ...state.scenario, selected: scenario }
  }));
  resetSelectedScenario() {
    this.setSelectedScenario(null);
  }

  // Scenarios
  readonly setScenarios = this.updater((state, scenarios: IScenario[] | null) => ({
    ...state,
    scenario: { ...state.scenario, all: scenarios }
  }));
  resetScenarios() {
    this.setScenarios(null);
  }

  // Levers
  readonly setLevers = this.updater((state, levers: ILever[] | null) => ({
    ...state,
    lever: { ...state.lever, all: levers }
  }));
  resetLevers() {
    this.setLevers(null);
  }

  // Configs
  readonly setConfigs = this.updater((state, configs: IConfig | null) => ({
    ...state,
    config: { ...state.config, all: configs }
  }));
  resetConfigs() {
    this.setConfigs(null);
  }
}
