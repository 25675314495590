import { IApiDataConverter } from '@front/services/api-data-converters/api-data-converter.interface';
import copy from 'fast-copy';
import { IAggregationStructure, IApiAggregationStructure } from '@shared';
import { DateApiDataConverter } from '@front/services/api-data-converters/date.interface';

export class AggregationStructureDataConverter implements IApiDataConverter<IApiAggregationStructure, IAggregationStructure> {
  private dateConverter = new DateApiDataConverter();
  fromApi(apiData: IApiAggregationStructure): IAggregationStructure {
    const aggregationStructure: IApiAggregationStructure = copy(apiData) as IApiAggregationStructure;
    if (aggregationStructure.children) {
      aggregationStructure.children = aggregationStructure.children.map((child) =>
        this.fromApi({
          ...(child as IAggregationStructure),
          parent: aggregationStructure,
          ancestors: [...(aggregationStructure?.ancestors || []), aggregationStructure]
        } as IApiAggregationStructure)
      );
    }
    return {
      ...aggregationStructure,
      models: aggregationStructure.models?.map((model) => ({
        ...model,
        historyStartDate: this.dateConverter.fromApi(model.historyStartDate as any),
        historyEndDate: this.dateConverter.fromApi(model.historyEndDate as any)
      }))
    }; // todo: fix api mmo model
  }

  toApi(bo: IAggregationStructure): IApiAggregationStructure {
    const { color, name, parent, models } = copy(bo);
    return { color, name, parent, models } as IApiAggregationStructure;
  }
}
