import { IApiDataConverter } from '@front/services/api-data-converters/api-data-converter.interface';
import copy from 'fast-copy';
import { IApiUser, IUser } from '@shared';

export class UserDataConverter implements IApiDataConverter<IApiUser, IUser> {
  fromApi(apiData: IApiUser): IUser {
    const user: IUser = copy(apiData) as IUser;
    return user;
  }

  toApi(bo: IUser): IApiUser {
    const { name, email } = copy(bo);
    return { name, email } as IApiUser;
  }
}
