import { Pipe, PipeTransform } from '@angular/core';

type ReturnType<T extends (...args: any) => any> = T extends (...args: any) => infer R ? R : any;
type Parameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;

@Pipe({
  name: 'memoize'
})
export class MemoizePipe implements PipeTransform {
  transform<T extends (...args: any) => any>(fn: T, ...args: Parameters<T>): ReturnType<T> {
    return fn.apply(this, args);
  }
}
