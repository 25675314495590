export enum EfficiencyLeversGroupType {
  'MECHANIC' = 'MECHANIC',
  'RETAILER' = 'RETAILER',
  'PRODUCT' = 'PRODUCT'
}

export interface IEfficiencyLevers {
  id: string;
  name: string;
  nns: number;
  ros: number;
  roi: number;
  color?: string;
}
