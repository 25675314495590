export enum TimeSeriesType {
  raw = 'raw',
  processed = 'processed',
  contribution = 'contribution',
  contributionIndexedOnTime = 'contributionIndexedOnTime',
  achieved = 'achieved'
}

export enum ContributionType {
  sellOut = 'sellOut',
  volume = 'volume',
  margin = 'margin'
}
