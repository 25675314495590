import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { PeriodGranularity } from '@front/interfaces/date.interface';
import { ISelectOption } from '@front/interfaces/generic-select.interface';
import { AppStateService } from '@front/services/state/app-state.service';
import { dateToNgbDateStruct, IAggregationStructure, IConfig, ICrossSection, MMOModelUnitType } from '@shared';
import { Interval } from 'luxon';
import { UntypedFormControl } from '@angular/forms';
import { SubSink } from 'subsink';

export interface IFilterParams {
  selectedAggregationStructureId: string;
  typeSelectedOption?: MMOModelUnitType;
  selectedCurrentDateInterval: Interval | null;
  selectedLastDateInterval: Interval | null;
  dateSelectedGranularity: PeriodGranularity;
  leverLevelSelected: number;
  crossSection?: ICrossSection | null;
}

@Component({
  selector: 'app-perf-filters',
  templateUrl: './perf-filters.component.html',
  styleUrls: ['./perf-filters.component.scss']
})
export class PerfFiltersComponent implements OnInit, OnChanges {
  @Input() aggregationStructure: IAggregationStructure | null = null;
  @Input() aggregationStructureChildren: IAggregationStructure[] | undefined;
  @Input() historyStartDate: Date | null = null;
  @Input() historyEndDate: Date | null = null;
  @Input() comparisonPeriodStartDate: Date | null = null;
  @Input() comparisonPeriodEndDate: Date | null = null;
  @Input() currentPeriod: Interval | null = null;
  @Input() lastPeriod: Interval | null = null;
  @Input() unitTypeArray: ISelectOption<string>[] = [];
  @Input() leversLevelsOptions: ISelectOption<number>[] = [];
  @Input() leverLevelSelected: number = 0;
  @Input() isChannelPerformance: boolean = false;
  @Input() displayCrossSections: boolean = false;

  private subs = new SubSink();

  public crossSectionSelectionForm = new UntypedFormControl();

  public readonly dateSelectOptions = {
    value: PeriodGranularity.YEAR,
    options: [
      { value: PeriodGranularity.YEAR, name: 'Last 12M' },
      { value: PeriodGranularity.QUARTER, name: 'Last quarter' },
      { value: PeriodGranularity.MONTH, name: 'Last month' },
      { value: PeriodGranularity.YEAR_TO_DATE, name: 'Year to Date' },
      { value: PeriodGranularity.CUSTOM, name: 'Custom' }
    ]
  };
  isFinal: boolean = true;
  selectedAggregationStructureId = '';
  requestOnGoing: boolean = true;
  public readonly comparisonPeriodSelectOptions = {
    value: this.dateSelectOptions.value,
    options: [
      { value: PeriodGranularity.YEAR, name: 'Previous year' },
      { value: 'previous', name: 'Previous period' },
      { value: PeriodGranularity.CUSTOM, name: 'Custom' },
      { value: 'none', name: 'None' }
    ]
  };
  // Compared periods selection
  selectedCurrentDateInterval: Interval | null = null;
  selectedLastDateInterval: Interval | null = null;

  // Unit type selection
  public typeSelectedOption?: MMOModelUnitType;
  public brandSelectOption!: ISelectOption<string>[];

  clientConfig: IClientConfig;
  config: IConfig;
  dateToNgbDateStruct = dateToNgbDateStruct;

  @Output() onParamsChange: EventEmitter<IFilterParams> = new EventEmitter();
  constructor(@Inject(ENUMS) enums: IClientConfig, @Inject(AppStateService) appStateService: AppStateService) {
    this.clientConfig = enums;
    this.config = appStateService.getConfigs()!;
  }

  ngOnInit(): void {
    if (this.aggregationStructure) {
      this.selectedAggregationStructureId = this.aggregationStructure.id;
      this.refreshFiltersOptions();
      this.initCrossSectionForm();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aggregationStructure) {
      this.refreshFiltersOptions();
    }
  }

  private refreshFiltersOptions(): void {
    this.initUnitTypeOptions();
    this.initBrandSelectionsOptions();
  }

  private initBrandSelectionsOptions() {
    if (this.aggregationStructureHasChildren() && this.aggregationStructure) {
      this.brandSelectOption = this.aggregationChildrenToBrandSelectOptions(
        this.aggregationStructureChildren!,
        this.aggregationStructure.id
      );
      this.selectedAggregationStructureId = this.aggregationStructure.id;
    }
  }

  private aggregationChildrenToBrandSelectOptions(
    aggregationStructureChildren: IAggregationStructure[],
    parentAggregationId: string
  ): ISelectOption<string>[] {
    let brandSelectOptions = aggregationStructureChildren.map((aggregationStructure) => {
      return { value: aggregationStructure.id, name: aggregationStructure.name };
    });
    brandSelectOptions.unshift({ value: parentAggregationId, name: 'All' });
    return brandSelectOptions;
  }

  onSelectedBrandChange(id: string) {
    this.selectedAggregationStructureId = id;
    this.setIsFinal();
    this.sendParamsChange();
  }

  setRequestOnGoing(status: boolean): void {
    this.requestOnGoing = status;
  }

  onSelectedGranularityChange(period: PeriodGranularity): void {
    if (period === PeriodGranularity.CUSTOM) {
      this.selectedCurrentDateInterval = this.currentPeriod;
      this.selectedLastDateInterval = this.lastPeriod;
    } else {
      this.onSelectedDateChange(period);
    }
  }

  onSelectedDateChange(period: PeriodGranularity): void {
    this.dateSelectOptions.value = period;
    this.sendParamsChange();
  }

  onSelectedLevelChange(level: number): void {
    this.leverLevelSelected = level;
    this.sendParamsChange();
  }

  isIntervalSubmitable() {
    return !this.selectedCurrentDateInterval || this.comparisonPeriodSelectOptions.value !== PeriodGranularity.CUSTOM;
  }

  onComparisonPeriodChange(value: string): void {
    if (this.selectedCurrentDateInterval && this.selectedCurrentDateInterval?.end) {
      const currentStart = this.selectedCurrentDateInterval.start;
      const currentEnd = this.selectedCurrentDateInterval.end;
      if (value === PeriodGranularity.YEAR) {
        this.selectedLastDateInterval = Interval.fromDateTimes(currentStart.minus({ years: 1 }), currentEnd.minus({ years: 1 }));
        this.changeSelectedDateInterval(this.selectedLastDateInterval, 'last');
      } else if (value === 'previous') {
        const differenceInDay = Interval.fromDateTimes(currentStart, currentEnd).toDuration('day');
        this.selectedLastDateInterval = Interval.fromDateTimes(
          currentStart.minus(differenceInDay).minus({ day: 1 }),
          currentStart.minus({ day: 1 })
        );
        this.changeSelectedDateInterval(this.selectedLastDateInterval, 'last');
      } else if (value === 'none') {
        this.selectedLastDateInterval = null;
      }
    }
  }

  changeSelectedDateInterval(e: Interval, period: 'current' | 'last'): void {
    if (e.end) {
      if (period === 'current') {
        this.selectedCurrentDateInterval = e;
      } else if (period === 'last') {
        this.selectedLastDateInterval = e;
      }
    }
  }

  private setIsFinal(): void {
    if (this.aggregationStructureChildren) {
      const existsInChilds = this.aggregationStructureChildren.find((agg) => agg.id === this.selectedAggregationStructureId);
      if (existsInChilds) {
        this.isFinal = true;
      } else {
        this.isFinal = false;
      }
    } else {
      this.isFinal = true;
    }
  }

  onSelectedTypeChange(type: string): void {
    this.typeSelectedOption = type as MMOModelUnitType;
    this.sendParamsChange();
  }

  aggregationStructureHasChildren(): boolean {
    return !!this.aggregationStructure?.children && this.aggregationStructure.children?.length > 0;
  }

  private initUnitTypeOptions(): void {
    if (this.unitTypeArray && this.unitTypeArray.length > 0) {
      const firstAvailable = this.unitTypeArray.find((option) => !option.disabled);
      this.typeSelectedOption = firstAvailable?.value as MMOModelUnitType;
    }
  }

  private initCrossSectionForm(): void {
    this.subs.sink = this.crossSectionSelectionForm.valueChanges.subscribe((crossSection) => {
      this.sendParamsChange({ crossSection });
    });
  }

  private sendParamsChange(filters: Partial<IFilterParams> = {}): void {
    this.setRequestOnGoing(true);
    this.onParamsChange.emit({
      selectedAggregationStructureId: this.selectedAggregationStructureId,
      typeSelectedOption: this.typeSelectedOption,
      selectedCurrentDateInterval: this.selectedCurrentDateInterval,
      selectedLastDateInterval: this.selectedLastDateInterval,
      dateSelectedGranularity: this.dateSelectOptions.value,
      leverLevelSelected: this.leverLevelSelected,
      ...filters
    });
  }

  get crossSections(): ICrossSection[] {
    return this.aggregationStructure?.models?.find((model) => model.unitType === this.typeSelectedOption)?.crossSections ?? [];
  }
}
