import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';
import { defaultConfig } from './default.config';

export const renaultConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Renault',
  logo: 'assets/images/Logo_renault.svg',
  platformName: 'MEM Platform',
  platformLogo: 'assets/images/mem-platform-logo.svg',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: true,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    brand: 'Country',
    turnover: 'Turnover',
    model: 'KPI',
    volume: 'Orders',
    profit: 'Profit',
    'other invests': 'Other Investments'
  },
  aggregationWording: ['Brand', 'Country', 'Model'],
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit],
  GATrackingID: 'UA-124520099-10',
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD', 'Orders', 'Volume']
};
