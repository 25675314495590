import { Injectable } from '@angular/core';

export const AUTH_TOKEN_KEY: Readonly<string> = 'mmo_token';

@Injectable({ providedIn: 'root' })
export class TokenService {
  public getToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }

  public setToken(token: string): void {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  }

  public clearToken(): void {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
}
