import {
  AfterViewInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Component,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  Inject,
  forwardRef
} from '@angular/core';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { ApplicationModules, IAggregationStructure, IConfig, Roles, MMOModelStatus } from '@shared';
import { AppStateService } from '@front/services/state/app-state.service';
import { expandAnimation } from '@front/app/modules/shared/animations/expand.animation';
import { Router } from '@angular/router';
import { PermissionService } from '@front/services/permission.service';
import { AuthService } from '@front/services/auth.service';
import { ApplicationPermissionsService } from '@front/services/application-permissions.service';

@Component({
  selector: 'app-aggregation-structure',
  templateUrl: './aggregation-structure.component.html',
  styleUrls: ['./aggregation-structure.component.scss'],
  animations: [expandAnimation('500ms')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AggregationStructureComponent implements OnInit, AfterViewInit {
  @Input() aggregationStructure: IAggregationStructure | null = null;
  @Input() isExpanded = false;
  @Output() childrenUpdate = new EventEmitter<any>();
  @Output() treeDirtied = new EventEmitter<void>();
  @Output() buttonClicked = new EventEmitter();
  @ViewChildren(forwardRef(() => AggregationStructureComponent)) childAggregationStructures!: QueryList<AggregationStructureComponent>;

  childrenCount: number = 0;
  lastChildCount: number = 0;
  clientConfig: IClientConfig;
  config: IConfig;
  tooltipLabel: string = '';
  windowBreakpoint = false;
  applicationModules = ApplicationModules;
  displayScenarioNb = false;
  displayAllocatedInvest = false;
  displaySellOut = false;

  constructor(
    @Inject(ENUMS) enums: IClientConfig,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly appStateService: AppStateService,
    private readonly authService: AuthService,
    private readonly permissionService: PermissionService,
    private readonly applicationPermissionService: ApplicationPermissionsService
  ) {
    this.clientConfig = enums;
    this.config = this.appStateService.getConfigs()!;
  }

  ngOnInit(): void {
    this.aggregationBreakpoint();
  }

  ngAfterViewInit(): void {
    this.countOpenedChildren();
    if (this.aggregationStructure?.models?.some((model) => model.status === MMOModelStatus.complete)) {
      this.displayScenarioNb = true;
      this.displayAllocatedInvest = true;
      this.displaySellOut = true;
    } else {
      this.displayScenarioNb = this.aggregationStructure?.models?.some((model) => model.status === MMOModelStatus.archived) ?? false;
      this.displayAllocatedInvest = false;
      this.displaySellOut = false;
    }
  }

  countOpenedChildren(): void {
    this.childrenCount = this.getOpenedChildren();
    this.lastChildCount = this.getLastChildCount();
    this.childrenUpdate.emit();
    this.cdr.markForCheck();
  }

  getOpenedChildren(): number {
    return (
      this.childAggregationStructures?.reduce(
        (children, component) => (component.isExpanded ? component.getOpenedChildren() + children + 1 : children + 1),
        0
      ) ?? 0
    );
  }

  getLastChildCount(): number {
    return this.childAggregationStructures?.last?.childrenCount && this.childAggregationStructures?.last?.isExpanded
      ? this.childAggregationStructures?.last?.childrenCount
      : 0;
  }

  getDisabledClass(resource = ''): string {
    return !this.canAccessResource(resource) ? 'disabled' : '';
  }

  swapExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.countOpenedChildren();
    this.childrenUpdate.emit();
  }

  canAccessResource(resource = ''): boolean {
    switch (resource) {
      case 'performance': {
        return this.hasModels();
      }
      case 'channel-performance': {
        return this.hasModels() && this.hasChildrenWithModels();
      }
      case 'simulation': {
        return this.hasIncompleteModels();
      }
      case 'reports': {
        return !!this.aggregationStructure && !!this.isReportsEnabled(this.aggregationStructure);
      }
      default: {
        return false;
      }
    }
  }

  getUrlRedirection(aggregationId: string, destination: string): string {
    let notSerialized;
    if (
      this.permissionService.hasRoleAmongArray(this.authService.getCurrentUser(), [Roles.admin, Roles.appManager]) ||
      this.isClientAndHasAccess()
    ) {
      switch (destination) {
        case '/performance': {
          if (this.canAccessResource('performance')) {
            notSerialized = `${destination}/${aggregationId}`;
          }
          break;
        }
        case '/scenarios': {
          if (this.canAccessResource('simulation')) {
            notSerialized = `${destination}/${aggregationId}`;
          }
          break;
        }
        case '/reports': {
          if (this.canAccessResource('reports')) {
            notSerialized = `${destination}/${aggregationId}`;
          }
          break;
        }
      }
    }

    return this.router.serializeUrl(this.router.createUrlTree([notSerialized]));
  }

  setTooltipLabel(label: string): void {
    this.tooltipLabel = label;
  }

  aggregationBreakpoint(): void {
    const watchMedia = window.matchMedia('(max-width: 1250px)');
    this.windowBreakpoint = watchMedia.matches;
    watchMedia.addEventListener('change', (e) => {
      this.windowBreakpoint = e.matches;
      this.cdr.markForCheck();
    });
  }

  isReportsEnabled(aggregation: IAggregationStructure) {
    return (
      this.applicationPermissionService.isPermitted(ApplicationModules.reports) &&
      aggregation.reports?.reportIds &&
      aggregation.reports.reportIds.length
    );
  }

  hasModels(): boolean {
    return !!this.aggregationStructure?.modelsIds.length;
  }

  hasChildrenWithModels(): boolean {
    return !!this.aggregationStructure?.childrenModelsNb;
  }

  hasIncompleteModels(): boolean {
    return !!this.aggregationStructure?.models?.find((model) => [MMOModelStatus.complete, MMOModelStatus.archived].includes(model.status));
  }

  private isClientAndHasAccess(): boolean {
    return (
      this.permissionService.hasRoleAmongArray(this.authService.getCurrentUser(), [Roles.client, Roles.consultant]) &&
      this.aggregationStructure?.allocatedInvest !== undefined
    );
  }
}
