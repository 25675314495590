export enum DataAggregationLevel {
  all = 'all',
  levers = 'levers',
  children = 'children'
}

export enum DataAggregationSearchedData {
  investment = 'investment',
  contribution = 'contribution'
}
