import { Injectable } from '@angular/core';
import { ApiService } from '@front/services/api.service';
import { IUser, ICreateUser, IUpdateUser, IApiUser, Endpoint, IUserResetPassword } from '@shared';
import { IApiRestEndpointService } from './api-endpoint-service.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDataConverter } from '@front/services/api-data-converters/user';

@Injectable({
  providedIn: 'root'
})
export class UserService implements IApiRestEndpointService<IUser> {
  readonly endpoint = Endpoint.USER;
  private converter = new UserDataConverter();

  constructor(readonly apiService: ApiService) {}

  getById(id: string, queryParams: any = {}): Observable<IUser> {
    return this.apiService.get<IApiUser>(`${this.endpoint}/${id}`, queryParams).pipe(map((data) => this.converter.fromApi(data)));
  }

  delete(id: string): Observable<boolean> {
    return this.apiService.delete(`${this.endpoint}/${id}`);
  }

  update(id: string, updateData: IUpdateUser): Observable<IUser> {
    return this.apiService
      .put<IApiUser, IUpdateUser>(`${this.endpoint}/${id}`, updateData)
      .pipe(map((data) => this.converter.fromApi(data)));
  }

  create(createData: ICreateUser): Observable<IUser> {
    return this.apiService.post<IApiUser, ICreateUser>(this.endpoint, createData).pipe(map((data) => this.converter.fromApi(data)));
  }

  getAll(queryParams: any = {}): Observable<IUser[]> {
    return this.apiService
      .getAll<IApiUser>(this.endpoint, queryParams)
      .pipe(map((elements) => elements.map((data) => this.converter.fromApi(data))));
  }
  sendResetPasswordMail(email: string): Observable<IUser> {
    return this.apiService
      .post<IApiUser, IUserResetPassword>(`${this.endpoint}/send-reset-password-mail`, { email: email })
      .pipe(map((data) => this.converter.fromApi(data)));
  }
}
