import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';
import { defaultConfig } from './default.config';

export const nestleConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Nestlé',
  logo: 'assets/images/logo-nestle.svg',
  platformName: 'PFME Platform',
  platformLogo: 'assets/images/ap_platform_nestle_logo.svg',
  moduleAccess: {
    investment: false,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: true,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    profit: 'Profit',
    roi: 'ROA',
    pdm: 'Unit'
  },
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit, MMOModelUnitType.pdm],
  modelTypeHideRoi: {
    [MMOModelUnitType.volume]: true,
    [MMOModelUnitType.pdm]: true
  },
  roiPerKpi: {
    [MMOModelUnitType.turnover]: 'ROA',
    [MMOModelUnitType.volume]: 'ROA',
    [MMOModelUnitType.profit]: 'ROA',
    [MMOModelUnitType.pdm]: 'ROA'
  },
  aggregationWording: ['Category', 'Macro Lever', 'Brand', 'Product'],
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD', 'Tonnes']
};
