import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ekimetrics-logo',
  templateUrl: './logo.component.html'
})
export class EkimetricsLogoComponent {
  @Input()
  color = '#fff';
}
