// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
}

.grid-column-start-1 {
  col-start: 1;
}

.grid-column-start-2 {
  col-start: 2;
}

.grid-column-start-3 {
  col-start: 3;
}

.grid-column-start-4 {
  col-start: 4;
}

.grid-column-start-5 {
  col-start: 5;
}

.grid-column-start-6 {
  col-start: 6;
}

.grid-column-start-7 {
  col-start: 7;
}

.grid-column-start-8 {
  col-start: 8;
}

.grid-column-start-9 {
  col-start: 9;
}

.grid-column-end-1 {
  col-end: 1;
}

.grid-column-end-2 {
  col-end: 2;
}

.grid-column-end-3 {
  col-end: 3;
}

.grid-column-end-4 {
  col-end: 4;
}

.grid-column-end-5 {
  col-end: 5;
}

.grid-column-end-6 {
  col-end: 6;
}

.grid-column-end-7 {
  col-end: 7;
}

.grid-column-end-8 {
  col-end: 8;
}

.grid-column-end-9 {
  col-end: 9;
}

.grid-row-start-1 {
  row-start: 1;
}

.grid-row-start-2 {
  row-start: 2;
}

.grid-row-start-3 {
  row-start: 3;
}

.grid-row-start-4 {
  row-start: 4;
}

.grid-row-start-5 {
  row-start: 5;
}

.grid-row-start-6 {
  row-start: 6;
}

.grid-row-start-7 {
  row-start: 7;
}

.grid-row-start-8 {
  row-start: 8;
}

.grid-row-start-9 {
  row-start: 9;
}

.grid-row-end-1 {
  row-end: 1;
}

.grid-row-end-2 {
  row-end: 2;
}

.grid-row-end-3 {
  row-end: 3;
}

.grid-row-end-4 {
  row-end: 4;
}

.grid-row-end-5 {
  row-end: 5;
}

.grid-row-end-6 {
  row-end: 6;
}

.grid-row-end-7 {
  row-end: 7;
}

.grid-row-end-8 {
  row-end: 8;
}

.grid-row-end-9 {
  row-end: 9;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.row-span-1 {
  grid-row: span 1/span 1;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.row-span-2 {
  grid-row: span 2/span 2;
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.row-span-3 {
  grid-row: span 3/span 3;
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.row-span-4 {
  grid-row: span 4/span 4;
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.row-span-5 {
  grid-row: span 5/span 5;
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.row-span-6 {
  grid-row: span 6/span 6;
}

.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.row-span-7 {
  grid-row: span 7/span 7;
}

.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.row-span-8 {
  grid-row: span 8/span 8;
}

.grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.row-span-9 {
  grid-row: span 9/span 9;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 710px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px;
  }
}
@media (min-width: 1300px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1480px;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1300px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.min-w-5 {
  min-width: 5% !important;
}

.max-w-5 {
  max-width: 5% !important;
}

.min-w-10 {
  min-width: 10% !important;
}

.max-w-10 {
  max-width: 10% !important;
}

.min-w-15 {
  min-width: 15% !important;
}

.max-w-15 {
  max-width: 15% !important;
}

.min-w-20 {
  min-width: 20% !important;
}

.max-w-20 {
  max-width: 20% !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.max-w-25 {
  max-width: 25% !important;
}

.min-w-30 {
  min-width: 30% !important;
}

.max-w-30 {
  max-width: 30% !important;
}

.min-w-35 {
  min-width: 35% !important;
}

.max-w-35 {
  max-width: 35% !important;
}

.min-w-40 {
  min-width: 40% !important;
}

.max-w-40 {
  max-width: 40% !important;
}

.min-w-45 {
  min-width: 45% !important;
}

.max-w-45 {
  max-width: 45% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.min-w-55 {
  min-width: 55% !important;
}

.max-w-55 {
  max-width: 55% !important;
}

.min-w-60 {
  min-width: 60% !important;
}

.max-w-60 {
  max-width: 60% !important;
}

.min-w-65 {
  min-width: 65% !important;
}

.max-w-65 {
  max-width: 65% !important;
}

.min-w-70 {
  min-width: 70% !important;
}

.max-w-70 {
  max-width: 70% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.max-w-75 {
  max-width: 75% !important;
}

.min-w-80 {
  min-width: 80% !important;
}

.max-w-80 {
  max-width: 80% !important;
}

.min-w-85 {
  min-width: 85% !important;
}

.max-w-85 {
  max-width: 85% !important;
}

.min-w-90 {
  min-width: 90% !important;
}

.max-w-90 {
  max-width: 90% !important;
}

.min-w-95 {
  min-width: 95% !important;
}

.max-w-95 {
  max-width: 95% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.max-w-auto {
  max-width: auto !important;
}

.min-h-5 {
  min-height: 5% !important;
}

.max-h-5 {
  max-height: 5% !important;
}

.min-h-10 {
  min-height: 10% !important;
}

.max-h-10 {
  max-height: 10% !important;
}

.min-h-15 {
  min-height: 15% !important;
}

.max-h-15 {
  max-height: 15% !important;
}

.min-h-20 {
  min-height: 20% !important;
}

.max-h-20 {
  max-height: 20% !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.max-h-25 {
  max-height: 25% !important;
}

.min-h-30 {
  min-height: 30% !important;
}

.max-h-30 {
  max-height: 30% !important;
}

.min-h-35 {
  min-height: 35% !important;
}

.max-h-35 {
  max-height: 35% !important;
}

.min-h-40 {
  min-height: 40% !important;
}

.max-h-40 {
  max-height: 40% !important;
}

.min-h-45 {
  min-height: 45% !important;
}

.max-h-45 {
  max-height: 45% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.max-h-50 {
  max-height: 50% !important;
}

.min-h-55 {
  min-height: 55% !important;
}

.max-h-55 {
  max-height: 55% !important;
}

.min-h-60 {
  min-height: 60% !important;
}

.max-h-60 {
  max-height: 60% !important;
}

.min-h-65 {
  min-height: 65% !important;
}

.max-h-65 {
  max-height: 65% !important;
}

.min-h-70 {
  min-height: 70% !important;
}

.max-h-70 {
  max-height: 70% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.max-h-75 {
  max-height: 75% !important;
}

.min-h-80 {
  min-height: 80% !important;
}

.max-h-80 {
  max-height: 80% !important;
}

.min-h-85 {
  min-height: 85% !important;
}

.max-h-85 {
  max-height: 85% !important;
}

.min-h-90 {
  min-height: 90% !important;
}

.max-h-90 {
  max-height: 90% !important;
}

.min-h-95 {
  min-height: 95% !important;
}

.max-h-95 {
  max-height: 95% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.min-h-auto {
  min-height: auto !important;
}

.max-h-auto {
  max-height: auto !important;
}

.flex-basis-5 {
  flex-basis: 5%;
}

.flex-basis-10 {
  flex-basis: 10%;
}

.flex-basis-15 {
  flex-basis: 15%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-30 {
  flex-basis: 30%;
}

.flex-basis-35 {
  flex-basis: 35%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-45 {
  flex-basis: 45%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-55 {
  flex-basis: 55%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

.flex-basis-65 {
  flex-basis: 65%;
}

.flex-basis-70 {
  flex-basis: 70%;
}

.flex-basis-75 {
  flex-basis: 75%;
}

.flex-basis-80 {
  flex-basis: 80%;
}

.flex-basis-85 {
  flex-basis: 85%;
}

.flex-basis-90 {
  flex-basis: 90%;
}

.flex-basis-95 {
  flex-basis: 95%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-basis-auto {
  flex-basis: auto;
}

.flex-basis-1\\/2 {
  flex-basis: 50%;
}

.flex-basis-1\\/3 {
  flex-basis: 33.333333%;
}

.flex-basis-2\\/3 {
  flex-basis: 66.666667%;
}

.flex-basis-1\\/4 {
  flex-basis: 25%;
}

.flex-basis-2\\/4 {
  flex-basis: 50%;
}

.flex-basis-3\\/4 {
  flex-basis: 75%;
}

.flex-basis-1\\/5 {
  flex-basis: 20%;
}

.flex-basis-2\\/5 {
  flex-basis: 40%;
}

.flex-basis-3\\/5 {
  flex-basis: 60%;
}

.flex-basis-4\\/5 {
  flex-basis: 80%;
}

.flex-basis-full {
  flex-basis: 100%;
}

.gap-0-0 {
  gap: 0 0 !important;
}

.gap-0-1 {
  gap: 0 0.25rem !important;
}

.gap-0-2 {
  gap: 0 0.5rem !important;
}

.gap-0-3 {
  gap: 0 1rem !important;
}

.gap-0-4 {
  gap: 0 1.5rem !important;
}

.gap-0-5 {
  gap: 0 3rem !important;
}

.gap-0-6 {
  gap: 0 4.5rem !important;
}

.gap-0-7 {
  gap: 0 6rem !important;
}

.gap-0-8 {
  gap: 0 7.5rem !important;
}

.gap-1-0 {
  gap: 0.25rem 0 !important;
}

.gap-1-1 {
  gap: 0.25rem 0.25rem !important;
}

.gap-1-2 {
  gap: 0.25rem 0.5rem !important;
}

.gap-1-3 {
  gap: 0.25rem 1rem !important;
}

.gap-1-4 {
  gap: 0.25rem 1.5rem !important;
}

.gap-1-5 {
  gap: 0.25rem 3rem !important;
}

.gap-1-6 {
  gap: 0.25rem 4.5rem !important;
}

.gap-1-7 {
  gap: 0.25rem 6rem !important;
}

.gap-1-8 {
  gap: 0.25rem 7.5rem !important;
}

.gap-2-0 {
  gap: 0.5rem 0 !important;
}

.gap-2-1 {
  gap: 0.5rem 0.25rem !important;
}

.gap-2-2 {
  gap: 0.5rem 0.5rem !important;
}

.gap-2-3 {
  gap: 0.5rem 1rem !important;
}

.gap-2-4 {
  gap: 0.5rem 1.5rem !important;
}

.gap-2-5 {
  gap: 0.5rem 3rem !important;
}

.gap-2-6 {
  gap: 0.5rem 4.5rem !important;
}

.gap-2-7 {
  gap: 0.5rem 6rem !important;
}

.gap-2-8 {
  gap: 0.5rem 7.5rem !important;
}

.gap-3-0 {
  gap: 1rem 0 !important;
}

.gap-3-1 {
  gap: 1rem 0.25rem !important;
}

.gap-3-2 {
  gap: 1rem 0.5rem !important;
}

.gap-3-3 {
  gap: 1rem 1rem !important;
}

.gap-3-4 {
  gap: 1rem 1.5rem !important;
}

.gap-3-5 {
  gap: 1rem 3rem !important;
}

.gap-3-6 {
  gap: 1rem 4.5rem !important;
}

.gap-3-7 {
  gap: 1rem 6rem !important;
}

.gap-3-8 {
  gap: 1rem 7.5rem !important;
}

.gap-4-0 {
  gap: 1.5rem 0 !important;
}

.gap-4-1 {
  gap: 1.5rem 0.25rem !important;
}

.gap-4-2 {
  gap: 1.5rem 0.5rem !important;
}

.gap-4-3 {
  gap: 1.5rem 1rem !important;
}

.gap-4-4 {
  gap: 1.5rem 1.5rem !important;
}

.gap-4-5 {
  gap: 1.5rem 3rem !important;
}

.gap-4-6 {
  gap: 1.5rem 4.5rem !important;
}

.gap-4-7 {
  gap: 1.5rem 6rem !important;
}

.gap-4-8 {
  gap: 1.5rem 7.5rem !important;
}

.gap-5-0 {
  gap: 3rem 0 !important;
}

.gap-5-1 {
  gap: 3rem 0.25rem !important;
}

.gap-5-2 {
  gap: 3rem 0.5rem !important;
}

.gap-5-3 {
  gap: 3rem 1rem !important;
}

.gap-5-4 {
  gap: 3rem 1.5rem !important;
}

.gap-5-5 {
  gap: 3rem 3rem !important;
}

.gap-5-6 {
  gap: 3rem 4.5rem !important;
}

.gap-5-7 {
  gap: 3rem 6rem !important;
}

.gap-5-8 {
  gap: 3rem 7.5rem !important;
}

.gap-6-0 {
  gap: 4.5rem 0 !important;
}

.gap-6-1 {
  gap: 4.5rem 0.25rem !important;
}

.gap-6-2 {
  gap: 4.5rem 0.5rem !important;
}

.gap-6-3 {
  gap: 4.5rem 1rem !important;
}

.gap-6-4 {
  gap: 4.5rem 1.5rem !important;
}

.gap-6-5 {
  gap: 4.5rem 3rem !important;
}

.gap-6-6 {
  gap: 4.5rem 4.5rem !important;
}

.gap-6-7 {
  gap: 4.5rem 6rem !important;
}

.gap-6-8 {
  gap: 4.5rem 7.5rem !important;
}

.gap-7-0 {
  gap: 6rem 0 !important;
}

.gap-7-1 {
  gap: 6rem 0.25rem !important;
}

.gap-7-2 {
  gap: 6rem 0.5rem !important;
}

.gap-7-3 {
  gap: 6rem 1rem !important;
}

.gap-7-4 {
  gap: 6rem 1.5rem !important;
}

.gap-7-5 {
  gap: 6rem 3rem !important;
}

.gap-7-6 {
  gap: 6rem 4.5rem !important;
}

.gap-7-7 {
  gap: 6rem 6rem !important;
}

.gap-7-8 {
  gap: 6rem 7.5rem !important;
}

.gap-8-0 {
  gap: 7.5rem 0 !important;
}

.gap-8-1 {
  gap: 7.5rem 0.25rem !important;
}

.gap-8-2 {
  gap: 7.5rem 0.5rem !important;
}

.gap-8-3 {
  gap: 7.5rem 1rem !important;
}

.gap-8-4 {
  gap: 7.5rem 1.5rem !important;
}

.gap-8-5 {
  gap: 7.5rem 3rem !important;
}

.gap-8-6 {
  gap: 7.5rem 4.5rem !important;
}

.gap-8-7 {
  gap: 7.5rem 6rem !important;
}

.gap-8-8 {
  gap: 7.5rem 7.5rem !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-grid {
  display: grid !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-grid {
    display: grid !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
}
@media (min-width: 1300px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1300px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
}
@media (min-width: 1300px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
}
.min-width-100 {
  min-width: 100px;
}

.max-width-100 {
  max-width: 100px;
}

.min-width-250 {
  min-width: 250px;
}

.max-width-250 {
  max-width: 250px;
}

.min-width-400 {
  min-width: 400px;
}

.max-width-400 {
  max-width: 400px;
}

.min-width-600 {
  min-width: 600px;
}

.max-width-600 {
  max-width: 600px;
}

.bg-black {
  background-color: #000;
}

.bg-gray {
  background-color: #808080;
}

.bg-white {
  background-color: #fff;
}

.bg-background-80 {
  background-color: #f0f4f8;
}

.bg-background-100 {
  background-color: #e9eff5;
}

.bg-background-140 {
  background-color: #d7e2ed;
}

.bg-success-700 {
  background-color: #164d39;
}

.bg-success-500 {
  background-color: #2c9d82;
}

.bg-success-100 {
  background-color: #d5f3ec;
}

.bg-success-50 {
  background-color: #217557;
}

.bg-warning-700 {
  background-color: #e78428;
}

.bg-warning-500 {
  background-color: #e78428;
}

.bg-warning-200 {
  background-color: #e78428;
}

.bg-danger-700 {
  background-color: #8c2330;
}

.bg-danger-500 {
  background-color: #d04556;
}

.bg-danger-300 {
  background-color: #ee778c;
}

.bg-ekiBlue1 {
  background-color: #1d88a1;
}

.bg-ekiBlue2 {
  background-color: #7acbde;
}

.bg-ekiBlue3 {
  background-color: #ccebf2;
}

.bg-ekiYellow1 {
  background-color: #b49935;
}

.bg-ekiYellow2 {
  background-color: #d7c378;
}

.bg-ekiYellow3 {
  background-color: #e4dc92;
}

.bg-bluish {
  background-color: #133c55;
}

.bg-blue {
  background-color: #386fa4;
}

.bg-lightBlue {
  background-color: #5993d8;
}

.bg-darkViolet {
  background-color: #392759;
}

.bg-violet {
  background-color: #5662d9;
}

.bg-lightViolet {
  background-color: #9290ed;
}

.bg-darkPurple {
  background-color: #772140;
}

.bg-purple {
  background-color: #d24f7e;
}

.bg-lightPurple {
  background-color: #e2bebe;
}

.bg-darkGreen {
  background-color: #265e54;
}

.bg-green {
  background-color: #3fa8a2;
}

.bg-lightGreen {
  background-color: #92e4d6;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #808080;
}

.text-white {
  color: #fff;
}

.text-background-80 {
  color: #f0f4f8;
}

.text-background-100 {
  color: #e9eff5;
}

.text-background-140 {
  color: #d7e2ed;
}

.text-success-700 {
  color: #164d39;
}

.text-success-500 {
  color: #2c9d82;
}

.text-success-100 {
  color: #d5f3ec;
}

.text-success-50 {
  color: #217557;
}

.text-warning-700 {
  color: #e78428;
}

.text-warning-500 {
  color: #e78428;
}

.text-warning-200 {
  color: #e78428;
}

.text-danger-700 {
  color: #8c2330;
}

.text-danger-500 {
  color: #d04556;
}

.text-danger-300 {
  color: #ee778c;
}

.text-ekiBlue1 {
  color: #1d88a1;
}

.text-ekiBlue2 {
  color: #7acbde;
}

.text-ekiBlue3 {
  color: #ccebf2;
}

.text-ekiYellow1 {
  color: #b49935;
}

.text-ekiYellow2 {
  color: #d7c378;
}

.text-ekiYellow3 {
  color: #e4dc92;
}

.text-bluish {
  color: #133c55;
}

.text-blue {
  color: #386fa4;
}

.text-lightBlue {
  color: #5993d8;
}

.text-darkViolet {
  color: #392759;
}

.text-violet {
  color: #5662d9;
}

.text-lightViolet {
  color: #9290ed;
}

.text-darkPurple {
  color: #772140;
}

.text-purple {
  color: #d24f7e;
}

.text-lightPurple {
  color: #e2bebe;
}

.text-darkGreen {
  color: #265e54;
}

.text-green {
  color: #3fa8a2;
}

.text-lightGreen {
  color: #92e4d6;
}

.fill-black {
  fill: #000;
}

.fill-gray {
  fill: #808080;
}

.fill-white {
  fill: #fff;
}

.fill-background-80 {
  fill: #f0f4f8;
}

.fill-background-100 {
  fill: #e9eff5;
}

.fill-background-140 {
  fill: #d7e2ed;
}

.fill-success-700 {
  fill: #164d39;
}

.fill-success-500 {
  fill: #2c9d82;
}

.fill-success-100 {
  fill: #d5f3ec;
}

.fill-success-50 {
  fill: #217557;
}

.fill-warning-700 {
  fill: #e78428;
}

.fill-warning-500 {
  fill: #e78428;
}

.fill-warning-200 {
  fill: #e78428;
}

.fill-danger-700 {
  fill: #8c2330;
}

.fill-danger-500 {
  fill: #d04556;
}

.fill-danger-300 {
  fill: #ee778c;
}

.fill-ekiBlue1 {
  fill: #1d88a1;
}

.fill-ekiBlue2 {
  fill: #7acbde;
}

.fill-ekiBlue3 {
  fill: #ccebf2;
}

.fill-ekiYellow1 {
  fill: #b49935;
}

.fill-ekiYellow2 {
  fill: #d7c378;
}

.fill-ekiYellow3 {
  fill: #e4dc92;
}

.fill-bluish {
  fill: #133c55;
}

.fill-blue {
  fill: #386fa4;
}

.fill-lightBlue {
  fill: #5993d8;
}

.fill-darkViolet {
  fill: #392759;
}

.fill-violet {
  fill: #5662d9;
}

.fill-lightViolet {
  fill: #9290ed;
}

.fill-darkPurple {
  fill: #772140;
}

.fill-purple {
  fill: #d24f7e;
}

.fill-lightPurple {
  fill: #e2bebe;
}

.fill-darkGreen {
  fill: #265e54;
}

.fill-green {
  fill: #3fa8a2;
}

.fill-lightGreen {
  fill: #92e4d6;
}

.stroke-black {
  stroke: #000;
}

.stroke-gray {
  stroke: #808080;
}

.stroke-white {
  stroke: #fff;
}

.stroke-background-80 {
  stroke: #f0f4f8;
}

.stroke-background-100 {
  stroke: #e9eff5;
}

.stroke-background-140 {
  stroke: #d7e2ed;
}

.stroke-success-700 {
  stroke: #164d39;
}

.stroke-success-500 {
  stroke: #2c9d82;
}

.stroke-success-100 {
  stroke: #d5f3ec;
}

.stroke-success-50 {
  stroke: #217557;
}

.stroke-warning-700 {
  stroke: #e78428;
}

.stroke-warning-500 {
  stroke: #e78428;
}

.stroke-warning-200 {
  stroke: #e78428;
}

.stroke-danger-700 {
  stroke: #8c2330;
}

.stroke-danger-500 {
  stroke: #d04556;
}

.stroke-danger-300 {
  stroke: #ee778c;
}

.stroke-ekiBlue1 {
  stroke: #1d88a1;
}

.stroke-ekiBlue2 {
  stroke: #7acbde;
}

.stroke-ekiBlue3 {
  stroke: #ccebf2;
}

.stroke-ekiYellow1 {
  stroke: #b49935;
}

.stroke-ekiYellow2 {
  stroke: #d7c378;
}

.stroke-ekiYellow3 {
  stroke: #e4dc92;
}

.stroke-bluish {
  stroke: #133c55;
}

.stroke-blue {
  stroke: #386fa4;
}

.stroke-lightBlue {
  stroke: #5993d8;
}

.stroke-darkViolet {
  stroke: #392759;
}

.stroke-violet {
  stroke: #5662d9;
}

.stroke-lightViolet {
  stroke: #9290ed;
}

.stroke-darkPurple {
  stroke: #772140;
}

.stroke-purple {
  stroke: #d24f7e;
}

.stroke-lightPurple {
  stroke: #e2bebe;
}

.stroke-darkGreen {
  stroke: #265e54;
}

.stroke-green {
  stroke: #3fa8a2;
}

.stroke-lightGreen {
  stroke: #92e4d6;
}

.bg-black-important {
  background-color: #000 !important;
}

.bg-gray-important {
  background-color: #808080 !important;
}

.bg-white-important {
  background-color: #fff !important;
}

.bg-background-80-important {
  background-color: #f0f4f8 !important;
}

.bg-background-100-important {
  background-color: #e9eff5 !important;
}

.bg-background-140-important {
  background-color: #d7e2ed !important;
}

.bg-success-700-important {
  background-color: #164d39 !important;
}

.bg-success-500-important {
  background-color: #2c9d82 !important;
}

.bg-success-100-important {
  background-color: #d5f3ec !important;
}

.bg-success-50-important {
  background-color: #217557 !important;
}

.bg-warning-700-important {
  background-color: #e78428 !important;
}

.bg-warning-500-important {
  background-color: #e78428 !important;
}

.bg-warning-200-important {
  background-color: #e78428 !important;
}

.bg-danger-700-important {
  background-color: #8c2330 !important;
}

.bg-danger-500-important {
  background-color: #d04556 !important;
}

.bg-danger-300-important {
  background-color: #ee778c !important;
}

.bg-ekiBlue1-important {
  background-color: #1d88a1 !important;
}

.bg-ekiBlue2-important {
  background-color: #7acbde !important;
}

.bg-ekiBlue3-important {
  background-color: #ccebf2 !important;
}

.bg-ekiYellow1-important {
  background-color: #b49935 !important;
}

.bg-ekiYellow2-important {
  background-color: #d7c378 !important;
}

.bg-ekiYellow3-important {
  background-color: #e4dc92 !important;
}

.bg-bluish-important {
  background-color: #133c55 !important;
}

.bg-blue-important {
  background-color: #386fa4 !important;
}

.bg-lightBlue-important {
  background-color: #5993d8 !important;
}

.bg-darkViolet-important {
  background-color: #392759 !important;
}

.bg-violet-important {
  background-color: #5662d9 !important;
}

.bg-lightViolet-important {
  background-color: #9290ed !important;
}

.bg-darkPurple-important {
  background-color: #772140 !important;
}

.bg-purple-important {
  background-color: #d24f7e !important;
}

.bg-lightPurple-important {
  background-color: #e2bebe !important;
}

.bg-darkGreen-important {
  background-color: #265e54 !important;
}

.bg-green-important {
  background-color: #3fa8a2 !important;
}

.bg-lightGreen-important {
  background-color: #92e4d6 !important;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-l-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-r-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-t-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-b-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-l-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-r-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-t-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-b-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-l-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-r-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-t-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-b-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-4 {
  border-width: 4px !important;
  border-style: solid;
}

.border-l-4 {
  border-left-width: 4px !important;
  border-left-style: solid;
}

.border-r-4 {
  border-right-width: 4px !important;
  border-right-style: solid;
}

.border-t-4 {
  border-top-width: 4px !important;
  border-top-style: solid;
}

.border-b-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid;
}

.border-5 {
  border-width: 5px !important;
  border-style: solid;
}

.border-l-5 {
  border-left-width: 5px !important;
  border-left-style: solid;
}

.border-r-5 {
  border-right-width: 5px !important;
  border-right-style: solid;
}

.border-t-5 {
  border-top-width: 5px !important;
  border-top-style: solid;
}

.border-b-5 {
  border-bottom-width: 5px !important;
  border-bottom-style: solid;
}

.border-6 {
  border-width: 6px !important;
  border-style: solid;
}

.border-l-6 {
  border-left-width: 6px !important;
  border-left-style: solid;
}

.border-r-6 {
  border-right-width: 6px !important;
  border-right-style: solid;
}

.border-t-6 {
  border-top-width: 6px !important;
  border-top-style: solid;
}

.border-b-6 {
  border-bottom-width: 6px !important;
  border-bottom-style: solid;
}

.border-7 {
  border-width: 7px !important;
  border-style: solid;
}

.border-l-7 {
  border-left-width: 7px !important;
  border-left-style: solid;
}

.border-r-7 {
  border-right-width: 7px !important;
  border-right-style: solid;
}

.border-t-7 {
  border-top-width: 7px !important;
  border-top-style: solid;
}

.border-b-7 {
  border-bottom-width: 7px !important;
  border-bottom-style: solid;
}

.border-8 {
  border-width: 8px !important;
  border-style: solid;
}

.border-l-8 {
  border-left-width: 8px !important;
  border-left-style: solid;
}

.border-r-8 {
  border-right-width: 8px !important;
  border-right-style: solid;
}

.border-t-8 {
  border-top-width: 8px !important;
  border-top-style: solid;
}

.border-b-8 {
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

.border-9 {
  border-width: 9px !important;
  border-style: solid;
}

.border-l-9 {
  border-left-width: 9px !important;
  border-left-style: solid;
}

.border-r-9 {
  border-right-width: 9px !important;
  border-right-style: solid;
}

.border-t-9 {
  border-top-width: 9px !important;
  border-top-style: solid;
}

.border-b-9 {
  border-bottom-width: 9px !important;
  border-bottom-style: solid;
}

.border-10 {
  border-width: 10px !important;
  border-style: solid;
}

.border-l-10 {
  border-left-width: 10px !important;
  border-left-style: solid;
}

.border-r-10 {
  border-right-width: 10px !important;
  border-right-style: solid;
}

.border-t-10 {
  border-top-width: 10px !important;
  border-top-style: solid;
}

.border-b-10 {
  border-bottom-width: 10px !important;
  border-bottom-style: solid;
}

.border-11 {
  border-width: 11px !important;
  border-style: solid;
}

.border-l-11 {
  border-left-width: 11px !important;
  border-left-style: solid;
}

.border-r-11 {
  border-right-width: 11px !important;
  border-right-style: solid;
}

.border-t-11 {
  border-top-width: 11px !important;
  border-top-style: solid;
}

.border-b-11 {
  border-bottom-width: 11px !important;
  border-bottom-style: solid;
}

.border-12 {
  border-width: 12px !important;
  border-style: solid;
}

.border-l-12 {
  border-left-width: 12px !important;
  border-left-style: solid;
}

.border-r-12 {
  border-right-width: 12px !important;
  border-right-style: solid;
}

.border-t-12 {
  border-top-width: 12px !important;
  border-top-style: solid;
}

.border-b-12 {
  border-bottom-width: 12px !important;
  border-bottom-style: solid;
}

.border-13 {
  border-width: 13px !important;
  border-style: solid;
}

.border-l-13 {
  border-left-width: 13px !important;
  border-left-style: solid;
}

.border-r-13 {
  border-right-width: 13px !important;
  border-right-style: solid;
}

.border-t-13 {
  border-top-width: 13px !important;
  border-top-style: solid;
}

.border-b-13 {
  border-bottom-width: 13px !important;
  border-bottom-style: solid;
}

.border-14 {
  border-width: 14px !important;
  border-style: solid;
}

.border-l-14 {
  border-left-width: 14px !important;
  border-left-style: solid;
}

.border-r-14 {
  border-right-width: 14px !important;
  border-right-style: solid;
}

.border-t-14 {
  border-top-width: 14px !important;
  border-top-style: solid;
}

.border-b-14 {
  border-bottom-width: 14px !important;
  border-bottom-style: solid;
}

.border-15 {
  border-width: 15px !important;
  border-style: solid;
}

.border-l-15 {
  border-left-width: 15px !important;
  border-left-style: solid;
}

.border-r-15 {
  border-right-width: 15px !important;
  border-right-style: solid;
}

.border-t-15 {
  border-top-width: 15px !important;
  border-top-style: solid;
}

.border-b-15 {
  border-bottom-width: 15px !important;
  border-bottom-style: solid;
}

.border-16 {
  border-width: 16px !important;
  border-style: solid;
}

.border-l-16 {
  border-left-width: 16px !important;
  border-left-style: solid;
}

.border-r-16 {
  border-right-width: 16px !important;
  border-right-style: solid;
}

.border-t-16 {
  border-top-width: 16px !important;
  border-top-style: solid;
}

.border-b-16 {
  border-bottom-width: 16px !important;
  border-bottom-style: solid;
}

.border-17 {
  border-width: 17px !important;
  border-style: solid;
}

.border-l-17 {
  border-left-width: 17px !important;
  border-left-style: solid;
}

.border-r-17 {
  border-right-width: 17px !important;
  border-right-style: solid;
}

.border-t-17 {
  border-top-width: 17px !important;
  border-top-style: solid;
}

.border-b-17 {
  border-bottom-width: 17px !important;
  border-bottom-style: solid;
}

.border-18 {
  border-width: 18px !important;
  border-style: solid;
}

.border-l-18 {
  border-left-width: 18px !important;
  border-left-style: solid;
}

.border-r-18 {
  border-right-width: 18px !important;
  border-right-style: solid;
}

.border-t-18 {
  border-top-width: 18px !important;
  border-top-style: solid;
}

.border-b-18 {
  border-bottom-width: 18px !important;
  border-bottom-style: solid;
}

.border-19 {
  border-width: 19px !important;
  border-style: solid;
}

.border-l-19 {
  border-left-width: 19px !important;
  border-left-style: solid;
}

.border-r-19 {
  border-right-width: 19px !important;
  border-right-style: solid;
}

.border-t-19 {
  border-top-width: 19px !important;
  border-top-style: solid;
}

.border-b-19 {
  border-bottom-width: 19px !important;
  border-bottom-style: solid;
}

.border-20 {
  border-width: 20px !important;
  border-style: solid;
}

.border-l-20 {
  border-left-width: 20px !important;
  border-left-style: solid;
}

.border-r-20 {
  border-right-width: 20px !important;
  border-right-style: solid;
}

.border-t-20 {
  border-top-width: 20px !important;
  border-top-style: solid;
}

.border-b-20 {
  border-bottom-width: 20px !important;
  border-bottom-style: solid;
}

.border-1\\/10-rem {
  border-width: calc(1rem)/10 !important;
  border-style: solid;
}

.border-l-1\\/10-rem {
  border-left-width: calc(1rem)/10 !important;
  border-left-style: solid;
}

.border-r-1\\/10-rem {
  border-right-width: calc(1rem)/10 !important;
  border-right-style: solid;
}

.border-t-1\\/10-rem {
  border-top-width: calc(1rem)/10 !important;
  border-top-style: solid;
}

.border-b-1\\/10-rem {
  border-bottom-width: calc(1rem)/10 !important;
  border-bottom-style: solid;
}

.border-2\\/10-rem {
  border-width: calc(2rem)/10 !important;
  border-style: solid;
}

.border-l-2\\/10-rem {
  border-left-width: calc(2rem)/10 !important;
  border-left-style: solid;
}

.border-r-2\\/10-rem {
  border-right-width: calc(2rem)/10 !important;
  border-right-style: solid;
}

.border-t-2\\/10-rem {
  border-top-width: calc(2rem)/10 !important;
  border-top-style: solid;
}

.border-b-2\\/10-rem {
  border-bottom-width: calc(2rem)/10 !important;
  border-bottom-style: solid;
}

.border-3\\/10-rem {
  border-width: calc(3rem)/10 !important;
  border-style: solid;
}

.border-l-3\\/10-rem {
  border-left-width: calc(3rem)/10 !important;
  border-left-style: solid;
}

.border-r-3\\/10-rem {
  border-right-width: calc(3rem)/10 !important;
  border-right-style: solid;
}

.border-t-3\\/10-rem {
  border-top-width: calc(3rem)/10 !important;
  border-top-style: solid;
}

.border-b-3\\/10-rem {
  border-bottom-width: calc(3rem)/10 !important;
  border-bottom-style: solid;
}

.border-4\\/10-rem {
  border-width: calc(4rem)/10 !important;
  border-style: solid;
}

.border-l-4\\/10-rem {
  border-left-width: calc(4rem)/10 !important;
  border-left-style: solid;
}

.border-r-4\\/10-rem {
  border-right-width: calc(4rem)/10 !important;
  border-right-style: solid;
}

.border-t-4\\/10-rem {
  border-top-width: calc(4rem)/10 !important;
  border-top-style: solid;
}

.border-b-4\\/10-rem {
  border-bottom-width: calc(4rem)/10 !important;
  border-bottom-style: solid;
}

.border-5\\/10-rem {
  border-width: calc(5rem)/10 !important;
  border-style: solid;
}

.border-l-5\\/10-rem {
  border-left-width: calc(5rem)/10 !important;
  border-left-style: solid;
}

.border-r-5\\/10-rem {
  border-right-width: calc(5rem)/10 !important;
  border-right-style: solid;
}

.border-t-5\\/10-rem {
  border-top-width: calc(5rem)/10 !important;
  border-top-style: solid;
}

.border-b-5\\/10-rem {
  border-bottom-width: calc(5rem)/10 !important;
  border-bottom-style: solid;
}

.border-6\\/10-rem {
  border-width: calc(6rem)/10 !important;
  border-style: solid;
}

.border-l-6\\/10-rem {
  border-left-width: calc(6rem)/10 !important;
  border-left-style: solid;
}

.border-r-6\\/10-rem {
  border-right-width: calc(6rem)/10 !important;
  border-right-style: solid;
}

.border-t-6\\/10-rem {
  border-top-width: calc(6rem)/10 !important;
  border-top-style: solid;
}

.border-b-6\\/10-rem {
  border-bottom-width: calc(6rem)/10 !important;
  border-bottom-style: solid;
}

.border-7\\/10-rem {
  border-width: calc(7rem)/10 !important;
  border-style: solid;
}

.border-l-7\\/10-rem {
  border-left-width: calc(7rem)/10 !important;
  border-left-style: solid;
}

.border-r-7\\/10-rem {
  border-right-width: calc(7rem)/10 !important;
  border-right-style: solid;
}

.border-t-7\\/10-rem {
  border-top-width: calc(7rem)/10 !important;
  border-top-style: solid;
}

.border-b-7\\/10-rem {
  border-bottom-width: calc(7rem)/10 !important;
  border-bottom-style: solid;
}

.border-8\\/10-rem {
  border-width: calc(8rem)/10 !important;
  border-style: solid;
}

.border-l-8\\/10-rem {
  border-left-width: calc(8rem)/10 !important;
  border-left-style: solid;
}

.border-r-8\\/10-rem {
  border-right-width: calc(8rem)/10 !important;
  border-right-style: solid;
}

.border-t-8\\/10-rem {
  border-top-width: calc(8rem)/10 !important;
  border-top-style: solid;
}

.border-b-8\\/10-rem {
  border-bottom-width: calc(8rem)/10 !important;
  border-bottom-style: solid;
}

.border-9\\/10-rem {
  border-width: calc(9rem)/10 !important;
  border-style: solid;
}

.border-l-9\\/10-rem {
  border-left-width: calc(9rem)/10 !important;
  border-left-style: solid;
}

.border-r-9\\/10-rem {
  border-right-width: calc(9rem)/10 !important;
  border-right-style: solid;
}

.border-t-9\\/10-rem {
  border-top-width: calc(9rem)/10 !important;
  border-top-style: solid;
}

.border-b-9\\/10-rem {
  border-bottom-width: calc(9rem)/10 !important;
  border-bottom-style: solid;
}

.border-10\\/10-rem {
  border-width: calc(10rem)/10 !important;
  border-style: solid;
}

.border-l-10\\/10-rem {
  border-left-width: calc(10rem)/10 !important;
  border-left-style: solid;
}

.border-r-10\\/10-rem {
  border-right-width: calc(10rem)/10 !important;
  border-right-style: solid;
}

.border-t-10\\/10-rem {
  border-top-width: calc(10rem)/10 !important;
  border-top-style: solid;
}

.border-b-10\\/10-rem {
  border-bottom-width: calc(10rem)/10 !important;
  border-bottom-style: solid;
}

.border-11\\/10-rem {
  border-width: calc(11rem)/10 !important;
  border-style: solid;
}

.border-l-11\\/10-rem {
  border-left-width: calc(11rem)/10 !important;
  border-left-style: solid;
}

.border-r-11\\/10-rem {
  border-right-width: calc(11rem)/10 !important;
  border-right-style: solid;
}

.border-t-11\\/10-rem {
  border-top-width: calc(11rem)/10 !important;
  border-top-style: solid;
}

.border-b-11\\/10-rem {
  border-bottom-width: calc(11rem)/10 !important;
  border-bottom-style: solid;
}

.border-12\\/10-rem {
  border-width: calc(12rem)/10 !important;
  border-style: solid;
}

.border-l-12\\/10-rem {
  border-left-width: calc(12rem)/10 !important;
  border-left-style: solid;
}

.border-r-12\\/10-rem {
  border-right-width: calc(12rem)/10 !important;
  border-right-style: solid;
}

.border-t-12\\/10-rem {
  border-top-width: calc(12rem)/10 !important;
  border-top-style: solid;
}

.border-b-12\\/10-rem {
  border-bottom-width: calc(12rem)/10 !important;
  border-bottom-style: solid;
}

.border-13\\/10-rem {
  border-width: calc(13rem)/10 !important;
  border-style: solid;
}

.border-l-13\\/10-rem {
  border-left-width: calc(13rem)/10 !important;
  border-left-style: solid;
}

.border-r-13\\/10-rem {
  border-right-width: calc(13rem)/10 !important;
  border-right-style: solid;
}

.border-t-13\\/10-rem {
  border-top-width: calc(13rem)/10 !important;
  border-top-style: solid;
}

.border-b-13\\/10-rem {
  border-bottom-width: calc(13rem)/10 !important;
  border-bottom-style: solid;
}

.border-14\\/10-rem {
  border-width: calc(14rem)/10 !important;
  border-style: solid;
}

.border-l-14\\/10-rem {
  border-left-width: calc(14rem)/10 !important;
  border-left-style: solid;
}

.border-r-14\\/10-rem {
  border-right-width: calc(14rem)/10 !important;
  border-right-style: solid;
}

.border-t-14\\/10-rem {
  border-top-width: calc(14rem)/10 !important;
  border-top-style: solid;
}

.border-b-14\\/10-rem {
  border-bottom-width: calc(14rem)/10 !important;
  border-bottom-style: solid;
}

.border-15\\/10-rem {
  border-width: calc(15rem)/10 !important;
  border-style: solid;
}

.border-l-15\\/10-rem {
  border-left-width: calc(15rem)/10 !important;
  border-left-style: solid;
}

.border-r-15\\/10-rem {
  border-right-width: calc(15rem)/10 !important;
  border-right-style: solid;
}

.border-t-15\\/10-rem {
  border-top-width: calc(15rem)/10 !important;
  border-top-style: solid;
}

.border-b-15\\/10-rem {
  border-bottom-width: calc(15rem)/10 !important;
  border-bottom-style: solid;
}

.border-16\\/10-rem {
  border-width: calc(16rem)/10 !important;
  border-style: solid;
}

.border-l-16\\/10-rem {
  border-left-width: calc(16rem)/10 !important;
  border-left-style: solid;
}

.border-r-16\\/10-rem {
  border-right-width: calc(16rem)/10 !important;
  border-right-style: solid;
}

.border-t-16\\/10-rem {
  border-top-width: calc(16rem)/10 !important;
  border-top-style: solid;
}

.border-b-16\\/10-rem {
  border-bottom-width: calc(16rem)/10 !important;
  border-bottom-style: solid;
}

.border-17\\/10-rem {
  border-width: calc(17rem)/10 !important;
  border-style: solid;
}

.border-l-17\\/10-rem {
  border-left-width: calc(17rem)/10 !important;
  border-left-style: solid;
}

.border-r-17\\/10-rem {
  border-right-width: calc(17rem)/10 !important;
  border-right-style: solid;
}

.border-t-17\\/10-rem {
  border-top-width: calc(17rem)/10 !important;
  border-top-style: solid;
}

.border-b-17\\/10-rem {
  border-bottom-width: calc(17rem)/10 !important;
  border-bottom-style: solid;
}

.border-18\\/10-rem {
  border-width: calc(18rem)/10 !important;
  border-style: solid;
}

.border-l-18\\/10-rem {
  border-left-width: calc(18rem)/10 !important;
  border-left-style: solid;
}

.border-r-18\\/10-rem {
  border-right-width: calc(18rem)/10 !important;
  border-right-style: solid;
}

.border-t-18\\/10-rem {
  border-top-width: calc(18rem)/10 !important;
  border-top-style: solid;
}

.border-b-18\\/10-rem {
  border-bottom-width: calc(18rem)/10 !important;
  border-bottom-style: solid;
}

.border-19\\/10-rem {
  border-width: calc(19rem)/10 !important;
  border-style: solid;
}

.border-l-19\\/10-rem {
  border-left-width: calc(19rem)/10 !important;
  border-left-style: solid;
}

.border-r-19\\/10-rem {
  border-right-width: calc(19rem)/10 !important;
  border-right-style: solid;
}

.border-t-19\\/10-rem {
  border-top-width: calc(19rem)/10 !important;
  border-top-style: solid;
}

.border-b-19\\/10-rem {
  border-bottom-width: calc(19rem)/10 !important;
  border-bottom-style: solid;
}

.border-20\\/10-rem {
  border-width: calc(20rem)/10 !important;
  border-style: solid;
}

.border-l-20\\/10-rem {
  border-left-width: calc(20rem)/10 !important;
  border-left-style: solid;
}

.border-r-20\\/10-rem {
  border-right-width: calc(20rem)/10 !important;
  border-right-style: solid;
}

.border-t-20\\/10-rem {
  border-top-width: calc(20rem)/10 !important;
  border-top-style: solid;
}

.border-b-20\\/10-rem {
  border-bottom-width: calc(20rem)/10 !important;
  border-bottom-style: solid;
}

.border-black {
  border-color: #000;
}

.border-l-black {
  border-left-color: #000;
}

.border-r-black {
  border-right-color: #000;
}

.border-t-black {
  border-top-color: #000;
}

.border-b-black {
  border-bottom-color: #000;
}

.border-gray {
  border-color: #808080;
}

.border-l-gray {
  border-left-color: #808080;
}

.border-r-gray {
  border-right-color: #808080;
}

.border-t-gray {
  border-top-color: #808080;
}

.border-b-gray {
  border-bottom-color: #808080;
}

.border-white {
  border-color: #fff;
}

.border-l-white {
  border-left-color: #fff;
}

.border-r-white {
  border-right-color: #fff;
}

.border-t-white {
  border-top-color: #fff;
}

.border-b-white {
  border-bottom-color: #fff;
}

.border-background-80 {
  border-color: #f0f4f8;
}

.border-l-background-80 {
  border-left-color: #f0f4f8;
}

.border-r-background-80 {
  border-right-color: #f0f4f8;
}

.border-t-background-80 {
  border-top-color: #f0f4f8;
}

.border-b-background-80 {
  border-bottom-color: #f0f4f8;
}

.border-background-100 {
  border-color: #e9eff5;
}

.border-l-background-100 {
  border-left-color: #e9eff5;
}

.border-r-background-100 {
  border-right-color: #e9eff5;
}

.border-t-background-100 {
  border-top-color: #e9eff5;
}

.border-b-background-100 {
  border-bottom-color: #e9eff5;
}

.border-background-140 {
  border-color: #d7e2ed;
}

.border-l-background-140 {
  border-left-color: #d7e2ed;
}

.border-r-background-140 {
  border-right-color: #d7e2ed;
}

.border-t-background-140 {
  border-top-color: #d7e2ed;
}

.border-b-background-140 {
  border-bottom-color: #d7e2ed;
}

.border-success-700 {
  border-color: #164d39;
}

.border-l-success-700 {
  border-left-color: #164d39;
}

.border-r-success-700 {
  border-right-color: #164d39;
}

.border-t-success-700 {
  border-top-color: #164d39;
}

.border-b-success-700 {
  border-bottom-color: #164d39;
}

.border-success-500 {
  border-color: #2c9d82;
}

.border-l-success-500 {
  border-left-color: #2c9d82;
}

.border-r-success-500 {
  border-right-color: #2c9d82;
}

.border-t-success-500 {
  border-top-color: #2c9d82;
}

.border-b-success-500 {
  border-bottom-color: #2c9d82;
}

.border-success-100 {
  border-color: #d5f3ec;
}

.border-l-success-100 {
  border-left-color: #d5f3ec;
}

.border-r-success-100 {
  border-right-color: #d5f3ec;
}

.border-t-success-100 {
  border-top-color: #d5f3ec;
}

.border-b-success-100 {
  border-bottom-color: #d5f3ec;
}

.border-success-50 {
  border-color: #217557;
}

.border-l-success-50 {
  border-left-color: #217557;
}

.border-r-success-50 {
  border-right-color: #217557;
}

.border-t-success-50 {
  border-top-color: #217557;
}

.border-b-success-50 {
  border-bottom-color: #217557;
}

.border-warning-700 {
  border-color: #e78428;
}

.border-l-warning-700 {
  border-left-color: #e78428;
}

.border-r-warning-700 {
  border-right-color: #e78428;
}

.border-t-warning-700 {
  border-top-color: #e78428;
}

.border-b-warning-700 {
  border-bottom-color: #e78428;
}

.border-warning-500 {
  border-color: #e78428;
}

.border-l-warning-500 {
  border-left-color: #e78428;
}

.border-r-warning-500 {
  border-right-color: #e78428;
}

.border-t-warning-500 {
  border-top-color: #e78428;
}

.border-b-warning-500 {
  border-bottom-color: #e78428;
}

.border-warning-200 {
  border-color: #e78428;
}

.border-l-warning-200 {
  border-left-color: #e78428;
}

.border-r-warning-200 {
  border-right-color: #e78428;
}

.border-t-warning-200 {
  border-top-color: #e78428;
}

.border-b-warning-200 {
  border-bottom-color: #e78428;
}

.border-danger-700 {
  border-color: #8c2330;
}

.border-l-danger-700 {
  border-left-color: #8c2330;
}

.border-r-danger-700 {
  border-right-color: #8c2330;
}

.border-t-danger-700 {
  border-top-color: #8c2330;
}

.border-b-danger-700 {
  border-bottom-color: #8c2330;
}

.border-danger-500 {
  border-color: #d04556;
}

.border-l-danger-500 {
  border-left-color: #d04556;
}

.border-r-danger-500 {
  border-right-color: #d04556;
}

.border-t-danger-500 {
  border-top-color: #d04556;
}

.border-b-danger-500 {
  border-bottom-color: #d04556;
}

.border-danger-300 {
  border-color: #ee778c;
}

.border-l-danger-300 {
  border-left-color: #ee778c;
}

.border-r-danger-300 {
  border-right-color: #ee778c;
}

.border-t-danger-300 {
  border-top-color: #ee778c;
}

.border-b-danger-300 {
  border-bottom-color: #ee778c;
}

.border-ekiBlue1 {
  border-color: #1d88a1;
}

.border-l-ekiBlue1 {
  border-left-color: #1d88a1;
}

.border-r-ekiBlue1 {
  border-right-color: #1d88a1;
}

.border-t-ekiBlue1 {
  border-top-color: #1d88a1;
}

.border-b-ekiBlue1 {
  border-bottom-color: #1d88a1;
}

.border-ekiBlue2 {
  border-color: #7acbde;
}

.border-l-ekiBlue2 {
  border-left-color: #7acbde;
}

.border-r-ekiBlue2 {
  border-right-color: #7acbde;
}

.border-t-ekiBlue2 {
  border-top-color: #7acbde;
}

.border-b-ekiBlue2 {
  border-bottom-color: #7acbde;
}

.border-ekiBlue3 {
  border-color: #ccebf2;
}

.border-l-ekiBlue3 {
  border-left-color: #ccebf2;
}

.border-r-ekiBlue3 {
  border-right-color: #ccebf2;
}

.border-t-ekiBlue3 {
  border-top-color: #ccebf2;
}

.border-b-ekiBlue3 {
  border-bottom-color: #ccebf2;
}

.border-ekiYellow1 {
  border-color: #b49935;
}

.border-l-ekiYellow1 {
  border-left-color: #b49935;
}

.border-r-ekiYellow1 {
  border-right-color: #b49935;
}

.border-t-ekiYellow1 {
  border-top-color: #b49935;
}

.border-b-ekiYellow1 {
  border-bottom-color: #b49935;
}

.border-ekiYellow2 {
  border-color: #d7c378;
}

.border-l-ekiYellow2 {
  border-left-color: #d7c378;
}

.border-r-ekiYellow2 {
  border-right-color: #d7c378;
}

.border-t-ekiYellow2 {
  border-top-color: #d7c378;
}

.border-b-ekiYellow2 {
  border-bottom-color: #d7c378;
}

.border-ekiYellow3 {
  border-color: #e4dc92;
}

.border-l-ekiYellow3 {
  border-left-color: #e4dc92;
}

.border-r-ekiYellow3 {
  border-right-color: #e4dc92;
}

.border-t-ekiYellow3 {
  border-top-color: #e4dc92;
}

.border-b-ekiYellow3 {
  border-bottom-color: #e4dc92;
}

.border-bluish {
  border-color: #133c55;
}

.border-l-bluish {
  border-left-color: #133c55;
}

.border-r-bluish {
  border-right-color: #133c55;
}

.border-t-bluish {
  border-top-color: #133c55;
}

.border-b-bluish {
  border-bottom-color: #133c55;
}

.border-blue {
  border-color: #386fa4;
}

.border-l-blue {
  border-left-color: #386fa4;
}

.border-r-blue {
  border-right-color: #386fa4;
}

.border-t-blue {
  border-top-color: #386fa4;
}

.border-b-blue {
  border-bottom-color: #386fa4;
}

.border-lightBlue {
  border-color: #5993d8;
}

.border-l-lightBlue {
  border-left-color: #5993d8;
}

.border-r-lightBlue {
  border-right-color: #5993d8;
}

.border-t-lightBlue {
  border-top-color: #5993d8;
}

.border-b-lightBlue {
  border-bottom-color: #5993d8;
}

.border-darkViolet {
  border-color: #392759;
}

.border-l-darkViolet {
  border-left-color: #392759;
}

.border-r-darkViolet {
  border-right-color: #392759;
}

.border-t-darkViolet {
  border-top-color: #392759;
}

.border-b-darkViolet {
  border-bottom-color: #392759;
}

.border-violet {
  border-color: #5662d9;
}

.border-l-violet {
  border-left-color: #5662d9;
}

.border-r-violet {
  border-right-color: #5662d9;
}

.border-t-violet {
  border-top-color: #5662d9;
}

.border-b-violet {
  border-bottom-color: #5662d9;
}

.border-lightViolet {
  border-color: #9290ed;
}

.border-l-lightViolet {
  border-left-color: #9290ed;
}

.border-r-lightViolet {
  border-right-color: #9290ed;
}

.border-t-lightViolet {
  border-top-color: #9290ed;
}

.border-b-lightViolet {
  border-bottom-color: #9290ed;
}

.border-darkPurple {
  border-color: #772140;
}

.border-l-darkPurple {
  border-left-color: #772140;
}

.border-r-darkPurple {
  border-right-color: #772140;
}

.border-t-darkPurple {
  border-top-color: #772140;
}

.border-b-darkPurple {
  border-bottom-color: #772140;
}

.border-purple {
  border-color: #d24f7e;
}

.border-l-purple {
  border-left-color: #d24f7e;
}

.border-r-purple {
  border-right-color: #d24f7e;
}

.border-t-purple {
  border-top-color: #d24f7e;
}

.border-b-purple {
  border-bottom-color: #d24f7e;
}

.border-lightPurple {
  border-color: #e2bebe;
}

.border-l-lightPurple {
  border-left-color: #e2bebe;
}

.border-r-lightPurple {
  border-right-color: #e2bebe;
}

.border-t-lightPurple {
  border-top-color: #e2bebe;
}

.border-b-lightPurple {
  border-bottom-color: #e2bebe;
}

.border-darkGreen {
  border-color: #265e54;
}

.border-l-darkGreen {
  border-left-color: #265e54;
}

.border-r-darkGreen {
  border-right-color: #265e54;
}

.border-t-darkGreen {
  border-top-color: #265e54;
}

.border-b-darkGreen {
  border-bottom-color: #265e54;
}

.border-green {
  border-color: #3fa8a2;
}

.border-l-green {
  border-left-color: #3fa8a2;
}

.border-r-green {
  border-right-color: #3fa8a2;
}

.border-t-green {
  border-top-color: #3fa8a2;
}

.border-b-green {
  border-bottom-color: #3fa8a2;
}

.border-lightGreen {
  border-color: #92e4d6;
}

.border-l-lightGreen {
  border-left-color: #92e4d6;
}

.border-r-lightGreen {
  border-right-color: #92e4d6;
}

.border-t-lightGreen {
  border-top-color: #92e4d6;
}

.border-b-lightGreen {
  border-bottom-color: #92e4d6;
}

.bg-primary-300 {
  background-color: #d6dfe8;
}

.bg-primary-400 {
  background-color: #b5c6d6;
}

.bg-primary-500 {
  background-color: #94adc4;
}

.bg-primary-600 {
  background-color: #567b9c;
}

.bg-primary-700 {
  background-color: #44617b;
}

.bg-primary-800 {
  background-color: #32475a;
}

.bg-primary-900 {
  background-color: #202d39;
}

.text-primary-300 {
  color: #d6dfe8;
}

.text-primary-400 {
  color: #b5c6d6;
}

.text-primary-500 {
  color: #94adc4;
}

.text-primary-600 {
  color: #567b9c;
}

.text-primary-700 {
  color: #44617b;
}

.text-primary-800 {
  color: #32475a;
}

.text-primary-900 {
  color: #202d39;
}

.fill-primary-300 {
  fill: #d6dfe8;
}

.fill-primary-400 {
  fill: #b5c6d6;
}

.fill-primary-500 {
  fill: #94adc4;
}

.fill-primary-600 {
  fill: #567b9c;
}

.fill-primary-700 {
  fill: #44617b;
}

.fill-primary-800 {
  fill: #32475a;
}

.fill-primary-900 {
  fill: #202d39;
}

.stroke-primary-300 {
  stroke: #d6dfe8;
}

.stroke-primary-400 {
  stroke: #b5c6d6;
}

.stroke-primary-500 {
  stroke: #94adc4;
}

.stroke-primary-600 {
  stroke: #567b9c;
}

.stroke-primary-700 {
  stroke: #44617b;
}

.stroke-primary-800 {
  stroke: #32475a;
}

.stroke-primary-900 {
  stroke: #202d39;
}

.bg-primary-300-important {
  background-color: #d6dfe8 !important;
}

.bg-primary-400-important {
  background-color: #b5c6d6 !important;
}

.bg-primary-500-important {
  background-color: #94adc4 !important;
}

.bg-primary-600-important {
  background-color: #567b9c !important;
}

.bg-primary-700-important {
  background-color: #44617b !important;
}

.bg-primary-800-important {
  background-color: #32475a !important;
}

.bg-primary-900-important {
  background-color: #202d39 !important;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-l-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-r-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-t-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-b-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-l-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-r-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-t-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-b-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-l-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-r-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-t-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-b-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-4 {
  border-width: 4px !important;
  border-style: solid;
}

.border-l-4 {
  border-left-width: 4px !important;
  border-left-style: solid;
}

.border-r-4 {
  border-right-width: 4px !important;
  border-right-style: solid;
}

.border-t-4 {
  border-top-width: 4px !important;
  border-top-style: solid;
}

.border-b-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid;
}

.border-5 {
  border-width: 5px !important;
  border-style: solid;
}

.border-l-5 {
  border-left-width: 5px !important;
  border-left-style: solid;
}

.border-r-5 {
  border-right-width: 5px !important;
  border-right-style: solid;
}

.border-t-5 {
  border-top-width: 5px !important;
  border-top-style: solid;
}

.border-b-5 {
  border-bottom-width: 5px !important;
  border-bottom-style: solid;
}

.border-6 {
  border-width: 6px !important;
  border-style: solid;
}

.border-l-6 {
  border-left-width: 6px !important;
  border-left-style: solid;
}

.border-r-6 {
  border-right-width: 6px !important;
  border-right-style: solid;
}

.border-t-6 {
  border-top-width: 6px !important;
  border-top-style: solid;
}

.border-b-6 {
  border-bottom-width: 6px !important;
  border-bottom-style: solid;
}

.border-7 {
  border-width: 7px !important;
  border-style: solid;
}

.border-l-7 {
  border-left-width: 7px !important;
  border-left-style: solid;
}

.border-r-7 {
  border-right-width: 7px !important;
  border-right-style: solid;
}

.border-t-7 {
  border-top-width: 7px !important;
  border-top-style: solid;
}

.border-b-7 {
  border-bottom-width: 7px !important;
  border-bottom-style: solid;
}

.border-8 {
  border-width: 8px !important;
  border-style: solid;
}

.border-l-8 {
  border-left-width: 8px !important;
  border-left-style: solid;
}

.border-r-8 {
  border-right-width: 8px !important;
  border-right-style: solid;
}

.border-t-8 {
  border-top-width: 8px !important;
  border-top-style: solid;
}

.border-b-8 {
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

.border-9 {
  border-width: 9px !important;
  border-style: solid;
}

.border-l-9 {
  border-left-width: 9px !important;
  border-left-style: solid;
}

.border-r-9 {
  border-right-width: 9px !important;
  border-right-style: solid;
}

.border-t-9 {
  border-top-width: 9px !important;
  border-top-style: solid;
}

.border-b-9 {
  border-bottom-width: 9px !important;
  border-bottom-style: solid;
}

.border-10 {
  border-width: 10px !important;
  border-style: solid;
}

.border-l-10 {
  border-left-width: 10px !important;
  border-left-style: solid;
}

.border-r-10 {
  border-right-width: 10px !important;
  border-right-style: solid;
}

.border-t-10 {
  border-top-width: 10px !important;
  border-top-style: solid;
}

.border-b-10 {
  border-bottom-width: 10px !important;
  border-bottom-style: solid;
}

.border-11 {
  border-width: 11px !important;
  border-style: solid;
}

.border-l-11 {
  border-left-width: 11px !important;
  border-left-style: solid;
}

.border-r-11 {
  border-right-width: 11px !important;
  border-right-style: solid;
}

.border-t-11 {
  border-top-width: 11px !important;
  border-top-style: solid;
}

.border-b-11 {
  border-bottom-width: 11px !important;
  border-bottom-style: solid;
}

.border-12 {
  border-width: 12px !important;
  border-style: solid;
}

.border-l-12 {
  border-left-width: 12px !important;
  border-left-style: solid;
}

.border-r-12 {
  border-right-width: 12px !important;
  border-right-style: solid;
}

.border-t-12 {
  border-top-width: 12px !important;
  border-top-style: solid;
}

.border-b-12 {
  border-bottom-width: 12px !important;
  border-bottom-style: solid;
}

.border-13 {
  border-width: 13px !important;
  border-style: solid;
}

.border-l-13 {
  border-left-width: 13px !important;
  border-left-style: solid;
}

.border-r-13 {
  border-right-width: 13px !important;
  border-right-style: solid;
}

.border-t-13 {
  border-top-width: 13px !important;
  border-top-style: solid;
}

.border-b-13 {
  border-bottom-width: 13px !important;
  border-bottom-style: solid;
}

.border-14 {
  border-width: 14px !important;
  border-style: solid;
}

.border-l-14 {
  border-left-width: 14px !important;
  border-left-style: solid;
}

.border-r-14 {
  border-right-width: 14px !important;
  border-right-style: solid;
}

.border-t-14 {
  border-top-width: 14px !important;
  border-top-style: solid;
}

.border-b-14 {
  border-bottom-width: 14px !important;
  border-bottom-style: solid;
}

.border-15 {
  border-width: 15px !important;
  border-style: solid;
}

.border-l-15 {
  border-left-width: 15px !important;
  border-left-style: solid;
}

.border-r-15 {
  border-right-width: 15px !important;
  border-right-style: solid;
}

.border-t-15 {
  border-top-width: 15px !important;
  border-top-style: solid;
}

.border-b-15 {
  border-bottom-width: 15px !important;
  border-bottom-style: solid;
}

.border-16 {
  border-width: 16px !important;
  border-style: solid;
}

.border-l-16 {
  border-left-width: 16px !important;
  border-left-style: solid;
}

.border-r-16 {
  border-right-width: 16px !important;
  border-right-style: solid;
}

.border-t-16 {
  border-top-width: 16px !important;
  border-top-style: solid;
}

.border-b-16 {
  border-bottom-width: 16px !important;
  border-bottom-style: solid;
}

.border-17 {
  border-width: 17px !important;
  border-style: solid;
}

.border-l-17 {
  border-left-width: 17px !important;
  border-left-style: solid;
}

.border-r-17 {
  border-right-width: 17px !important;
  border-right-style: solid;
}

.border-t-17 {
  border-top-width: 17px !important;
  border-top-style: solid;
}

.border-b-17 {
  border-bottom-width: 17px !important;
  border-bottom-style: solid;
}

.border-18 {
  border-width: 18px !important;
  border-style: solid;
}

.border-l-18 {
  border-left-width: 18px !important;
  border-left-style: solid;
}

.border-r-18 {
  border-right-width: 18px !important;
  border-right-style: solid;
}

.border-t-18 {
  border-top-width: 18px !important;
  border-top-style: solid;
}

.border-b-18 {
  border-bottom-width: 18px !important;
  border-bottom-style: solid;
}

.border-19 {
  border-width: 19px !important;
  border-style: solid;
}

.border-l-19 {
  border-left-width: 19px !important;
  border-left-style: solid;
}

.border-r-19 {
  border-right-width: 19px !important;
  border-right-style: solid;
}

.border-t-19 {
  border-top-width: 19px !important;
  border-top-style: solid;
}

.border-b-19 {
  border-bottom-width: 19px !important;
  border-bottom-style: solid;
}

.border-20 {
  border-width: 20px !important;
  border-style: solid;
}

.border-l-20 {
  border-left-width: 20px !important;
  border-left-style: solid;
}

.border-r-20 {
  border-right-width: 20px !important;
  border-right-style: solid;
}

.border-t-20 {
  border-top-width: 20px !important;
  border-top-style: solid;
}

.border-b-20 {
  border-bottom-width: 20px !important;
  border-bottom-style: solid;
}

.border-1\\/10-rem {
  border-width: calc(1rem)/10 !important;
  border-style: solid;
}

.border-l-1\\/10-rem {
  border-left-width: calc(1rem)/10 !important;
  border-left-style: solid;
}

.border-r-1\\/10-rem {
  border-right-width: calc(1rem)/10 !important;
  border-right-style: solid;
}

.border-t-1\\/10-rem {
  border-top-width: calc(1rem)/10 !important;
  border-top-style: solid;
}

.border-b-1\\/10-rem {
  border-bottom-width: calc(1rem)/10 !important;
  border-bottom-style: solid;
}

.border-2\\/10-rem {
  border-width: calc(2rem)/10 !important;
  border-style: solid;
}

.border-l-2\\/10-rem {
  border-left-width: calc(2rem)/10 !important;
  border-left-style: solid;
}

.border-r-2\\/10-rem {
  border-right-width: calc(2rem)/10 !important;
  border-right-style: solid;
}

.border-t-2\\/10-rem {
  border-top-width: calc(2rem)/10 !important;
  border-top-style: solid;
}

.border-b-2\\/10-rem {
  border-bottom-width: calc(2rem)/10 !important;
  border-bottom-style: solid;
}

.border-3\\/10-rem {
  border-width: calc(3rem)/10 !important;
  border-style: solid;
}

.border-l-3\\/10-rem {
  border-left-width: calc(3rem)/10 !important;
  border-left-style: solid;
}

.border-r-3\\/10-rem {
  border-right-width: calc(3rem)/10 !important;
  border-right-style: solid;
}

.border-t-3\\/10-rem {
  border-top-width: calc(3rem)/10 !important;
  border-top-style: solid;
}

.border-b-3\\/10-rem {
  border-bottom-width: calc(3rem)/10 !important;
  border-bottom-style: solid;
}

.border-4\\/10-rem {
  border-width: calc(4rem)/10 !important;
  border-style: solid;
}

.border-l-4\\/10-rem {
  border-left-width: calc(4rem)/10 !important;
  border-left-style: solid;
}

.border-r-4\\/10-rem {
  border-right-width: calc(4rem)/10 !important;
  border-right-style: solid;
}

.border-t-4\\/10-rem {
  border-top-width: calc(4rem)/10 !important;
  border-top-style: solid;
}

.border-b-4\\/10-rem {
  border-bottom-width: calc(4rem)/10 !important;
  border-bottom-style: solid;
}

.border-5\\/10-rem {
  border-width: calc(5rem)/10 !important;
  border-style: solid;
}

.border-l-5\\/10-rem {
  border-left-width: calc(5rem)/10 !important;
  border-left-style: solid;
}

.border-r-5\\/10-rem {
  border-right-width: calc(5rem)/10 !important;
  border-right-style: solid;
}

.border-t-5\\/10-rem {
  border-top-width: calc(5rem)/10 !important;
  border-top-style: solid;
}

.border-b-5\\/10-rem {
  border-bottom-width: calc(5rem)/10 !important;
  border-bottom-style: solid;
}

.border-6\\/10-rem {
  border-width: calc(6rem)/10 !important;
  border-style: solid;
}

.border-l-6\\/10-rem {
  border-left-width: calc(6rem)/10 !important;
  border-left-style: solid;
}

.border-r-6\\/10-rem {
  border-right-width: calc(6rem)/10 !important;
  border-right-style: solid;
}

.border-t-6\\/10-rem {
  border-top-width: calc(6rem)/10 !important;
  border-top-style: solid;
}

.border-b-6\\/10-rem {
  border-bottom-width: calc(6rem)/10 !important;
  border-bottom-style: solid;
}

.border-7\\/10-rem {
  border-width: calc(7rem)/10 !important;
  border-style: solid;
}

.border-l-7\\/10-rem {
  border-left-width: calc(7rem)/10 !important;
  border-left-style: solid;
}

.border-r-7\\/10-rem {
  border-right-width: calc(7rem)/10 !important;
  border-right-style: solid;
}

.border-t-7\\/10-rem {
  border-top-width: calc(7rem)/10 !important;
  border-top-style: solid;
}

.border-b-7\\/10-rem {
  border-bottom-width: calc(7rem)/10 !important;
  border-bottom-style: solid;
}

.border-8\\/10-rem {
  border-width: calc(8rem)/10 !important;
  border-style: solid;
}

.border-l-8\\/10-rem {
  border-left-width: calc(8rem)/10 !important;
  border-left-style: solid;
}

.border-r-8\\/10-rem {
  border-right-width: calc(8rem)/10 !important;
  border-right-style: solid;
}

.border-t-8\\/10-rem {
  border-top-width: calc(8rem)/10 !important;
  border-top-style: solid;
}

.border-b-8\\/10-rem {
  border-bottom-width: calc(8rem)/10 !important;
  border-bottom-style: solid;
}

.border-9\\/10-rem {
  border-width: calc(9rem)/10 !important;
  border-style: solid;
}

.border-l-9\\/10-rem {
  border-left-width: calc(9rem)/10 !important;
  border-left-style: solid;
}

.border-r-9\\/10-rem {
  border-right-width: calc(9rem)/10 !important;
  border-right-style: solid;
}

.border-t-9\\/10-rem {
  border-top-width: calc(9rem)/10 !important;
  border-top-style: solid;
}

.border-b-9\\/10-rem {
  border-bottom-width: calc(9rem)/10 !important;
  border-bottom-style: solid;
}

.border-10\\/10-rem {
  border-width: calc(10rem)/10 !important;
  border-style: solid;
}

.border-l-10\\/10-rem {
  border-left-width: calc(10rem)/10 !important;
  border-left-style: solid;
}

.border-r-10\\/10-rem {
  border-right-width: calc(10rem)/10 !important;
  border-right-style: solid;
}

.border-t-10\\/10-rem {
  border-top-width: calc(10rem)/10 !important;
  border-top-style: solid;
}

.border-b-10\\/10-rem {
  border-bottom-width: calc(10rem)/10 !important;
  border-bottom-style: solid;
}

.border-11\\/10-rem {
  border-width: calc(11rem)/10 !important;
  border-style: solid;
}

.border-l-11\\/10-rem {
  border-left-width: calc(11rem)/10 !important;
  border-left-style: solid;
}

.border-r-11\\/10-rem {
  border-right-width: calc(11rem)/10 !important;
  border-right-style: solid;
}

.border-t-11\\/10-rem {
  border-top-width: calc(11rem)/10 !important;
  border-top-style: solid;
}

.border-b-11\\/10-rem {
  border-bottom-width: calc(11rem)/10 !important;
  border-bottom-style: solid;
}

.border-12\\/10-rem {
  border-width: calc(12rem)/10 !important;
  border-style: solid;
}

.border-l-12\\/10-rem {
  border-left-width: calc(12rem)/10 !important;
  border-left-style: solid;
}

.border-r-12\\/10-rem {
  border-right-width: calc(12rem)/10 !important;
  border-right-style: solid;
}

.border-t-12\\/10-rem {
  border-top-width: calc(12rem)/10 !important;
  border-top-style: solid;
}

.border-b-12\\/10-rem {
  border-bottom-width: calc(12rem)/10 !important;
  border-bottom-style: solid;
}

.border-13\\/10-rem {
  border-width: calc(13rem)/10 !important;
  border-style: solid;
}

.border-l-13\\/10-rem {
  border-left-width: calc(13rem)/10 !important;
  border-left-style: solid;
}

.border-r-13\\/10-rem {
  border-right-width: calc(13rem)/10 !important;
  border-right-style: solid;
}

.border-t-13\\/10-rem {
  border-top-width: calc(13rem)/10 !important;
  border-top-style: solid;
}

.border-b-13\\/10-rem {
  border-bottom-width: calc(13rem)/10 !important;
  border-bottom-style: solid;
}

.border-14\\/10-rem {
  border-width: calc(14rem)/10 !important;
  border-style: solid;
}

.border-l-14\\/10-rem {
  border-left-width: calc(14rem)/10 !important;
  border-left-style: solid;
}

.border-r-14\\/10-rem {
  border-right-width: calc(14rem)/10 !important;
  border-right-style: solid;
}

.border-t-14\\/10-rem {
  border-top-width: calc(14rem)/10 !important;
  border-top-style: solid;
}

.border-b-14\\/10-rem {
  border-bottom-width: calc(14rem)/10 !important;
  border-bottom-style: solid;
}

.border-15\\/10-rem {
  border-width: calc(15rem)/10 !important;
  border-style: solid;
}

.border-l-15\\/10-rem {
  border-left-width: calc(15rem)/10 !important;
  border-left-style: solid;
}

.border-r-15\\/10-rem {
  border-right-width: calc(15rem)/10 !important;
  border-right-style: solid;
}

.border-t-15\\/10-rem {
  border-top-width: calc(15rem)/10 !important;
  border-top-style: solid;
}

.border-b-15\\/10-rem {
  border-bottom-width: calc(15rem)/10 !important;
  border-bottom-style: solid;
}

.border-16\\/10-rem {
  border-width: calc(16rem)/10 !important;
  border-style: solid;
}

.border-l-16\\/10-rem {
  border-left-width: calc(16rem)/10 !important;
  border-left-style: solid;
}

.border-r-16\\/10-rem {
  border-right-width: calc(16rem)/10 !important;
  border-right-style: solid;
}

.border-t-16\\/10-rem {
  border-top-width: calc(16rem)/10 !important;
  border-top-style: solid;
}

.border-b-16\\/10-rem {
  border-bottom-width: calc(16rem)/10 !important;
  border-bottom-style: solid;
}

.border-17\\/10-rem {
  border-width: calc(17rem)/10 !important;
  border-style: solid;
}

.border-l-17\\/10-rem {
  border-left-width: calc(17rem)/10 !important;
  border-left-style: solid;
}

.border-r-17\\/10-rem {
  border-right-width: calc(17rem)/10 !important;
  border-right-style: solid;
}

.border-t-17\\/10-rem {
  border-top-width: calc(17rem)/10 !important;
  border-top-style: solid;
}

.border-b-17\\/10-rem {
  border-bottom-width: calc(17rem)/10 !important;
  border-bottom-style: solid;
}

.border-18\\/10-rem {
  border-width: calc(18rem)/10 !important;
  border-style: solid;
}

.border-l-18\\/10-rem {
  border-left-width: calc(18rem)/10 !important;
  border-left-style: solid;
}

.border-r-18\\/10-rem {
  border-right-width: calc(18rem)/10 !important;
  border-right-style: solid;
}

.border-t-18\\/10-rem {
  border-top-width: calc(18rem)/10 !important;
  border-top-style: solid;
}

.border-b-18\\/10-rem {
  border-bottom-width: calc(18rem)/10 !important;
  border-bottom-style: solid;
}

.border-19\\/10-rem {
  border-width: calc(19rem)/10 !important;
  border-style: solid;
}

.border-l-19\\/10-rem {
  border-left-width: calc(19rem)/10 !important;
  border-left-style: solid;
}

.border-r-19\\/10-rem {
  border-right-width: calc(19rem)/10 !important;
  border-right-style: solid;
}

.border-t-19\\/10-rem {
  border-top-width: calc(19rem)/10 !important;
  border-top-style: solid;
}

.border-b-19\\/10-rem {
  border-bottom-width: calc(19rem)/10 !important;
  border-bottom-style: solid;
}

.border-20\\/10-rem {
  border-width: calc(20rem)/10 !important;
  border-style: solid;
}

.border-l-20\\/10-rem {
  border-left-width: calc(20rem)/10 !important;
  border-left-style: solid;
}

.border-r-20\\/10-rem {
  border-right-width: calc(20rem)/10 !important;
  border-right-style: solid;
}

.border-t-20\\/10-rem {
  border-top-width: calc(20rem)/10 !important;
  border-top-style: solid;
}

.border-b-20\\/10-rem {
  border-bottom-width: calc(20rem)/10 !important;
  border-bottom-style: solid;
}

.border-primary-300 {
  border-color: #d6dfe8;
}

.border-l-primary-300 {
  border-left-color: #d6dfe8;
}

.border-r-primary-300 {
  border-right-color: #d6dfe8;
}

.border-t-primary-300 {
  border-top-color: #d6dfe8;
}

.border-b-primary-300 {
  border-bottom-color: #d6dfe8;
}

.border-primary-400 {
  border-color: #b5c6d6;
}

.border-l-primary-400 {
  border-left-color: #b5c6d6;
}

.border-r-primary-400 {
  border-right-color: #b5c6d6;
}

.border-t-primary-400 {
  border-top-color: #b5c6d6;
}

.border-b-primary-400 {
  border-bottom-color: #b5c6d6;
}

.border-primary-500 {
  border-color: #94adc4;
}

.border-l-primary-500 {
  border-left-color: #94adc4;
}

.border-r-primary-500 {
  border-right-color: #94adc4;
}

.border-t-primary-500 {
  border-top-color: #94adc4;
}

.border-b-primary-500 {
  border-bottom-color: #94adc4;
}

.border-primary-600 {
  border-color: #567b9c;
}

.border-l-primary-600 {
  border-left-color: #567b9c;
}

.border-r-primary-600 {
  border-right-color: #567b9c;
}

.border-t-primary-600 {
  border-top-color: #567b9c;
}

.border-b-primary-600 {
  border-bottom-color: #567b9c;
}

.border-primary-700 {
  border-color: #44617b;
}

.border-l-primary-700 {
  border-left-color: #44617b;
}

.border-r-primary-700 {
  border-right-color: #44617b;
}

.border-t-primary-700 {
  border-top-color: #44617b;
}

.border-b-primary-700 {
  border-bottom-color: #44617b;
}

.border-primary-800 {
  border-color: #32475a;
}

.border-l-primary-800 {
  border-left-color: #32475a;
}

.border-r-primary-800 {
  border-right-color: #32475a;
}

.border-t-primary-800 {
  border-top-color: #32475a;
}

.border-b-primary-800 {
  border-bottom-color: #32475a;
}

.border-primary-900 {
  border-color: #202d39;
}

.border-l-primary-900 {
  border-left-color: #202d39;
}

.border-r-primary-900 {
  border-right-color: #202d39;
}

.border-t-primary-900 {
  border-top-color: #202d39;
}

.border-b-primary-900 {
  border-bottom-color: #202d39;
}

.bg-white {
  background-color: #fff;
}

.bg-white-500 {
  background-color: #fff;
}

.bg-white-50 {
  background-color: white;
}

.bg-white-70 {
  background-color: white;
}

.bg-white-80 {
  background-color: white;
}

.bg-white-100 {
  background-color: white;
}

.bg-white-200 {
  background-color: white;
}

.bg-white-300 {
  background-color: white;
}

.bg-white-400 {
  background-color: white;
}

.bg-white-600 {
  background-color: #e6e6e6;
}

.bg-white-700 {
  background-color: #cccccc;
}

.bg-white-800 {
  background-color: #b3b3b3;
}

.bg-white-900 {
  background-color: #999999;
}

.bg-black {
  background-color: #000;
}

.bg-black-500 {
  background-color: #000;
}

.bg-black-50 {
  background-color: #737373;
}

.bg-black-70 {
  background-color: #6e6e6e;
}

.bg-black-80 {
  background-color: #6b6b6b;
}

.bg-black-100 {
  background-color: #666666;
}

.bg-black-200 {
  background-color: #4d4d4d;
}

.bg-black-300 {
  background-color: #333333;
}

.bg-black-400 {
  background-color: #1a1a1a;
}

.bg-black-600 {
  background-color: black;
}

.bg-black-700 {
  background-color: black;
}

.bg-black-800 {
  background-color: black;
}

.bg-black-900 {
  background-color: black;
}

.bg-green {
  background-color: #1b6137;
}

.bg-green-500 {
  background-color: #1b6137;
}

.bg-green-50 {
  background-color: #85dda8;
}

.bg-green-70 {
  background-color: #7ddba2;
}

.bg-green-80 {
  background-color: #79da9f;
}

.bg-green-100 {
  background-color: #71d79a;
}

.bg-green-200 {
  background-color: #49cc7d;
}

.bg-green-300 {
  background-color: #31b164;
}

.bg-green-400 {
  background-color: #26894e;
}

.bg-green-600 {
  background-color: #103920;
}

.bg-green-700 {
  background-color: #05110a;
}

.bg-green-800 {
  background-color: black;
}

.bg-green-900 {
  background-color: black;
}

.bg-red {
  background-color: #e94966;
}

.bg-red-500 {
  background-color: #e94966;
}

.bg-red-50 {
  background-color: white;
}

.bg-red-70 {
  background-color: white;
}

.bg-red-80 {
  background-color: white;
}

.bg-red-100 {
  background-color: white;
}

.bg-red-200 {
  background-color: #fad2d9;
}

.bg-red-300 {
  background-color: #f4a4b3;
}

.bg-red-400 {
  background-color: #ef778c;
}

.bg-red-600 {
  background-color: #e41c40;
}

.bg-red-700 {
  background-color: #b61633;
}

.bg-red-800 {
  background-color: #891126;
}

.bg-red-900 {
  background-color: #5b0b1a;
}

.bg-gray {
  background-color: #808080;
}

.bg-gray-500 {
  background-color: #808080;
}

.bg-gray-50 {
  background-color: #f3f3f3;
}

.bg-gray-70 {
  background-color: #eeeeee;
}

.bg-gray-80 {
  background-color: #ebebeb;
}

.bg-gray-100 {
  background-color: #e6e6e6;
}

.bg-gray-200 {
  background-color: #cdcdcd;
}

.bg-gray-300 {
  background-color: #b3b3b3;
}

.bg-gray-400 {
  background-color: #9a9a9a;
}

.bg-gray-600 {
  background-color: #676767;
}

.bg-gray-700 {
  background-color: #4d4d4d;
}

.bg-gray-800 {
  background-color: #343434;
}

.bg-gray-900 {
  background-color: #1a1a1a;
}

.bg-orange {
  background-color: #e9a85d;
}

.bg-orange-500 {
  background-color: #e9a85d;
}

.bg-orange-50 {
  background-color: white;
}

.bg-orange-70 {
  background-color: white;
}

.bg-orange-80 {
  background-color: white;
}

.bg-orange-100 {
  background-color: white;
}

.bg-orange-200 {
  background-color: #fbf0e4;
}

.bg-orange-300 {
  background-color: #f5d8b7;
}

.bg-orange-400 {
  background-color: #efc08a;
}

.bg-orange-600 {
  background-color: #e39030;
}

.bg-orange-700 {
  background-color: #c5761b;
}

.bg-orange-800 {
  background-color: #985b15;
}

.bg-orange-900 {
  background-color: #6b400f;
}

.bg-basic-1 {
  background-color: #344256;
}

.bg-basic-1-500 {
  background-color: #344256;
}

.bg-basic-1-50 {
  background-color: #a6b5c9;
}

.bg-basic-1-70 {
  background-color: #a0afc5;
}

.bg-basic-1-80 {
  background-color: #9dadc4;
}

.bg-basic-1-100 {
  background-color: #96a7c0;
}

.bg-basic-1-200 {
  background-color: #778dac;
}

.bg-basic-1-300 {
  background-color: #5a7396;
}

.bg-basic-1-400 {
  background-color: #475a76;
}

.bg-basic-1-600 {
  background-color: #212a36;
}

.bg-basic-1-700 {
  background-color: #0e1116;
}

.bg-basic-1-800 {
  background-color: black;
}

.bg-basic-1-900 {
  background-color: black;
}

.bg-basic-2 {
  background-color: #32475a;
}

.bg-basic-2-500 {
  background-color: #32475a;
}

.bg-basic-2-50 {
  background-color: #a5bacd;
}

.bg-basic-2-70 {
  background-color: #9eb5c9;
}

.bg-basic-2-80 {
  background-color: #9bb2c7;
}

.bg-basic-2-100 {
  background-color: #94adc4;
}

.bg-basic-2-200 {
  background-color: #7494b2;
}

.bg-basic-2-300 {
  background-color: #567b9c;
}

.bg-basic-2-400 {
  background-color: #44617b;
}

.bg-basic-2-600 {
  background-color: #202d39;
}

.bg-basic-2-700 {
  background-color: #0e1318;
}

.bg-basic-2-800 {
  background-color: black;
}

.bg-basic-2-900 {
  background-color: black;
}

.bg-basic-3 {
  background-color: #6394c5;
}

.bg-basic-3-500 {
  background-color: #6394c5;
}

.bg-basic-3-50 {
  background-color: white;
}

.bg-basic-3-70 {
  background-color: white;
}

.bg-basic-3-80 {
  background-color: white;
}

.bg-basic-3-100 {
  background-color: #f8fafc;
}

.bg-basic-3-200 {
  background-color: #d3e1ee;
}

.bg-basic-3-300 {
  background-color: #adc7e1;
}

.bg-basic-3-400 {
  background-color: #88aed3;
}

.bg-basic-3-600 {
  background-color: #427bb3;
}

.bg-basic-3-700 {
  background-color: #35618d;
}

.bg-basic-3-800 {
  background-color: #274868;
}

.bg-basic-3-900 {
  background-color: #192e43;
}

.bg-primary-1 {
  background-color: #007cba;
}

.bg-primary-1-500 {
  background-color: #007cba;
}

.bg-primary-1-50 {
  background-color: #a1e0ff;
}

.bg-primary-1-70 {
  background-color: #96dcff;
}

.bg-primary-1-80 {
  background-color: #91daff;
}

.bg-primary-1-100 {
  background-color: #87d7ff;
}

.bg-primary-1-200 {
  background-color: #54c6ff;
}

.bg-primary-1-300 {
  background-color: #21b5ff;
}

.bg-primary-1-400 {
  background-color: #009eed;
}

.bg-primary-1-600 {
  background-color: #005a87;
}

.bg-primary-1-700 {
  background-color: #003854;
}

.bg-primary-1-800 {
  background-color: #001621;
}

.bg-primary-1-900 {
  background-color: black;
}

.bg-primary-2 {
  background-color: #7ec6ea;
}

.bg-primary-2-500 {
  background-color: #7ec6ea;
}

.bg-primary-2-50 {
  background-color: white;
}

.bg-primary-2-70 {
  background-color: white;
}

.bg-primary-2-80 {
  background-color: white;
}

.bg-primary-2-100 {
  background-color: white;
}

.bg-primary-2-200 {
  background-color: white;
}

.bg-primary-2-300 {
  background-color: #d6edf8;
}

.bg-primary-2-400 {
  background-color: #aad9f1;
}

.bg-primary-2-600 {
  background-color: #52b3e3;
}

.bg-primary-2-700 {
  background-color: #269fdc;
}

.bg-primary-2-800 {
  background-color: #1d80b2;
}

.bg-primary-2-900 {
  background-color: #166186;
}

.bg-secondary-1 {
  background-color: #b9d6e4;
}

.bg-secondary-1-500 {
  background-color: #b9d6e4;
}

.bg-secondary-1-50 {
  background-color: white;
}

.bg-secondary-1-70 {
  background-color: white;
}

.bg-secondary-1-80 {
  background-color: white;
}

.bg-secondary-1-100 {
  background-color: white;
}

.bg-secondary-1-200 {
  background-color: white;
}

.bg-secondary-1-300 {
  background-color: white;
}

.bg-secondary-1-400 {
  background-color: #deecf2;
}

.bg-secondary-1-600 {
  background-color: #94c0d6;
}

.bg-secondary-1-700 {
  background-color: #6fabc8;
}

.bg-secondary-1-800 {
  background-color: #4b95b9;
}

.bg-secondary-1-900 {
  background-color: #3a7997;
}

.bg-secondary-2 {
  background-color: #84bfdd;
}

.bg-secondary-2-500 {
  background-color: #84bfdd;
}

.bg-secondary-2-50 {
  background-color: white;
}

.bg-secondary-2-70 {
  background-color: white;
}

.bg-secondary-2-80 {
  background-color: white;
}

.bg-secondary-2-100 {
  background-color: white;
}

.bg-secondary-2-200 {
  background-color: #fcfdfe;
}

.bg-secondary-2-300 {
  background-color: #d4e9f3;
}

.bg-secondary-2-400 {
  background-color: #acd4e8;
}

.bg-secondary-2-600 {
  background-color: #5caad2;
}

.bg-secondary-2-700 {
  background-color: #3695c5;
}

.bg-secondary-2-800 {
  background-color: #2b769d;
}

.bg-secondary-2-900 {
  background-color: #205875;
}

.bg-secondary-3 {
  background-color: #e7f4ff;
}

.bg-secondary-3-500 {
  background-color: #e7f4ff;
}

.bg-secondary-3-50 {
  background-color: white;
}

.bg-secondary-3-70 {
  background-color: white;
}

.bg-secondary-3-80 {
  background-color: white;
}

.bg-secondary-3-100 {
  background-color: white;
}

.bg-secondary-3-200 {
  background-color: white;
}

.bg-secondary-3-300 {
  background-color: white;
}

.bg-secondary-3-400 {
  background-color: white;
}

.bg-secondary-3-600 {
  background-color: #b4ddff;
}

.bg-secondary-3-700 {
  background-color: #81c5ff;
}

.bg-secondary-3-800 {
  background-color: #4eaeff;
}

.bg-secondary-3-900 {
  background-color: #1b97ff;
}

.bg-background {
  background-color: #5d89b5;
}

.bg-background-500 {
  background-color: #5d89b5;
}

.bg-background-50 {
  background-color: #fbfcfd;
}

.bg-background-70 {
  background-color: #f4f7fa;
}

.bg-background-80 {
  background-color: #f0f4f8;
}

.bg-background-100 {
  background-color: #e9eff5;
}

.bg-background-200 {
  background-color: #c6d6e5;
}

.bg-background-300 {
  background-color: #a3bcd5;
}

.bg-background-400 {
  background-color: #80a3c5;
}

.bg-background-600 {
  background-color: #467099;
}

.bg-background-700 {
  background-color: #365676;
}

.bg-background-800 {
  background-color: #263d53;
}

.bg-background-900 {
  background-color: #162330;
}

.bg-background-1 {
  background-color: #f4f6f8;
}

.bg-background-1-500 {
  background-color: #f4f6f8;
}

.bg-background-1-50 {
  background-color: white;
}

.bg-background-1-70 {
  background-color: white;
}

.bg-background-1-80 {
  background-color: white;
}

.bg-background-1-100 {
  background-color: white;
}

.bg-background-1-200 {
  background-color: white;
}

.bg-background-1-300 {
  background-color: white;
}

.bg-background-1-400 {
  background-color: white;
}

.bg-background-1-600 {
  background-color: #d5dde4;
}

.bg-background-1-700 {
  background-color: #b6c3d0;
}

.bg-background-1-800 {
  background-color: #97aabd;
}

.bg-background-1-900 {
  background-color: #7790a9;
}

.bg-success {
  background-color: #2c9d82;
}

.bg-success-500 {
  background-color: #2c9d82;
}

.bg-success-50 {
  background-color: #c1eee3;
}

.bg-success-70 {
  background-color: #b9ebdf;
}

.bg-success-80 {
  background-color: #b5eade;
}

.bg-success-100 {
  background-color: #ade8da;
}

.bg-success-200 {
  background-color: #85ddc8;
}

.bg-success-300 {
  background-color: #5dd2b6;
}

.bg-success-400 {
  background-color: #37c5a3;
}

.bg-success-600 {
  background-color: #217561;
}

.bg-success-700 {
  background-color: #164d40;
}

.bg-success-800 {
  background-color: #0b251f;
}

.bg-success-900 {
  background-color: black;
}

.text-white {
  color: #fff;
}

.text-white-500 {
  color: #fff;
}

.text-white-50 {
  color: white;
}

.text-white-70 {
  color: white;
}

.text-white-80 {
  color: white;
}

.text-white-100 {
  color: white;
}

.text-white-200 {
  color: white;
}

.text-white-300 {
  color: white;
}

.text-white-400 {
  color: white;
}

.text-white-600 {
  color: #e6e6e6;
}

.text-white-700 {
  color: #cccccc;
}

.text-white-800 {
  color: #b3b3b3;
}

.text-white-900 {
  color: #999999;
}

.text-black {
  color: #000;
}

.text-black-500 {
  color: #000;
}

.text-black-50 {
  color: #737373;
}

.text-black-70 {
  color: #6e6e6e;
}

.text-black-80 {
  color: #6b6b6b;
}

.text-black-100 {
  color: #666666;
}

.text-black-200 {
  color: #4d4d4d;
}

.text-black-300 {
  color: #333333;
}

.text-black-400 {
  color: #1a1a1a;
}

.text-black-600 {
  color: black;
}

.text-black-700 {
  color: black;
}

.text-black-800 {
  color: black;
}

.text-black-900 {
  color: black;
}

.text-green {
  color: #1b6137;
}

.text-green-500 {
  color: #1b6137;
}

.text-green-50 {
  color: #85dda8;
}

.text-green-70 {
  color: #7ddba2;
}

.text-green-80 {
  color: #79da9f;
}

.text-green-100 {
  color: #71d79a;
}

.text-green-200 {
  color: #49cc7d;
}

.text-green-300 {
  color: #31b164;
}

.text-green-400 {
  color: #26894e;
}

.text-green-600 {
  color: #103920;
}

.text-green-700 {
  color: #05110a;
}

.text-green-800 {
  color: black;
}

.text-green-900 {
  color: black;
}

.text-red {
  color: #e94966;
}

.text-red-500 {
  color: #e94966;
}

.text-red-50 {
  color: white;
}

.text-red-70 {
  color: white;
}

.text-red-80 {
  color: white;
}

.text-red-100 {
  color: white;
}

.text-red-200 {
  color: #fad2d9;
}

.text-red-300 {
  color: #f4a4b3;
}

.text-red-400 {
  color: #ef778c;
}

.text-red-600 {
  color: #e41c40;
}

.text-red-700 {
  color: #b61633;
}

.text-red-800 {
  color: #891126;
}

.text-red-900 {
  color: #5b0b1a;
}

.text-gray {
  color: #808080;
}

.text-gray-500 {
  color: #808080;
}

.text-gray-50 {
  color: #f3f3f3;
}

.text-gray-70 {
  color: #eeeeee;
}

.text-gray-80 {
  color: #ebebeb;
}

.text-gray-100 {
  color: #e6e6e6;
}

.text-gray-200 {
  color: #cdcdcd;
}

.text-gray-300 {
  color: #b3b3b3;
}

.text-gray-400 {
  color: #9a9a9a;
}

.text-gray-600 {
  color: #676767;
}

.text-gray-700 {
  color: #4d4d4d;
}

.text-gray-800 {
  color: #343434;
}

.text-gray-900 {
  color: #1a1a1a;
}

.text-orange {
  color: #e9a85d;
}

.text-orange-500 {
  color: #e9a85d;
}

.text-orange-50 {
  color: white;
}

.text-orange-70 {
  color: white;
}

.text-orange-80 {
  color: white;
}

.text-orange-100 {
  color: white;
}

.text-orange-200 {
  color: #fbf0e4;
}

.text-orange-300 {
  color: #f5d8b7;
}

.text-orange-400 {
  color: #efc08a;
}

.text-orange-600 {
  color: #e39030;
}

.text-orange-700 {
  color: #c5761b;
}

.text-orange-800 {
  color: #985b15;
}

.text-orange-900 {
  color: #6b400f;
}

.text-basic-1 {
  color: #344256;
}

.text-basic-1-500 {
  color: #344256;
}

.text-basic-1-50 {
  color: #a6b5c9;
}

.text-basic-1-70 {
  color: #a0afc5;
}

.text-basic-1-80 {
  color: #9dadc4;
}

.text-basic-1-100 {
  color: #96a7c0;
}

.text-basic-1-200 {
  color: #778dac;
}

.text-basic-1-300 {
  color: #5a7396;
}

.text-basic-1-400 {
  color: #475a76;
}

.text-basic-1-600 {
  color: #212a36;
}

.text-basic-1-700 {
  color: #0e1116;
}

.text-basic-1-800 {
  color: black;
}

.text-basic-1-900 {
  color: black;
}

.text-basic-2 {
  color: #32475a;
}

.text-basic-2-500 {
  color: #32475a;
}

.text-basic-2-50 {
  color: #a5bacd;
}

.text-basic-2-70 {
  color: #9eb5c9;
}

.text-basic-2-80 {
  color: #9bb2c7;
}

.text-basic-2-100 {
  color: #94adc4;
}

.text-basic-2-200 {
  color: #7494b2;
}

.text-basic-2-300 {
  color: #567b9c;
}

.text-basic-2-400 {
  color: #44617b;
}

.text-basic-2-600 {
  color: #202d39;
}

.text-basic-2-700 {
  color: #0e1318;
}

.text-basic-2-800 {
  color: black;
}

.text-basic-2-900 {
  color: black;
}

.text-basic-3 {
  color: #6394c5;
}

.text-basic-3-500 {
  color: #6394c5;
}

.text-basic-3-50 {
  color: white;
}

.text-basic-3-70 {
  color: white;
}

.text-basic-3-80 {
  color: white;
}

.text-basic-3-100 {
  color: #f8fafc;
}

.text-basic-3-200 {
  color: #d3e1ee;
}

.text-basic-3-300 {
  color: #adc7e1;
}

.text-basic-3-400 {
  color: #88aed3;
}

.text-basic-3-600 {
  color: #427bb3;
}

.text-basic-3-700 {
  color: #35618d;
}

.text-basic-3-800 {
  color: #274868;
}

.text-basic-3-900 {
  color: #192e43;
}

.text-primary-1 {
  color: #007cba;
}

.text-primary-1-500 {
  color: #007cba;
}

.text-primary-1-50 {
  color: #a1e0ff;
}

.text-primary-1-70 {
  color: #96dcff;
}

.text-primary-1-80 {
  color: #91daff;
}

.text-primary-1-100 {
  color: #87d7ff;
}

.text-primary-1-200 {
  color: #54c6ff;
}

.text-primary-1-300 {
  color: #21b5ff;
}

.text-primary-1-400 {
  color: #009eed;
}

.text-primary-1-600 {
  color: #005a87;
}

.text-primary-1-700 {
  color: #003854;
}

.text-primary-1-800 {
  color: #001621;
}

.text-primary-1-900 {
  color: black;
}

.text-primary-2 {
  color: #7ec6ea;
}

.text-primary-2-500 {
  color: #7ec6ea;
}

.text-primary-2-50 {
  color: white;
}

.text-primary-2-70 {
  color: white;
}

.text-primary-2-80 {
  color: white;
}

.text-primary-2-100 {
  color: white;
}

.text-primary-2-200 {
  color: white;
}

.text-primary-2-300 {
  color: #d6edf8;
}

.text-primary-2-400 {
  color: #aad9f1;
}

.text-primary-2-600 {
  color: #52b3e3;
}

.text-primary-2-700 {
  color: #269fdc;
}

.text-primary-2-800 {
  color: #1d80b2;
}

.text-primary-2-900 {
  color: #166186;
}

.text-secondary-1 {
  color: #b9d6e4;
}

.text-secondary-1-500 {
  color: #b9d6e4;
}

.text-secondary-1-50 {
  color: white;
}

.text-secondary-1-70 {
  color: white;
}

.text-secondary-1-80 {
  color: white;
}

.text-secondary-1-100 {
  color: white;
}

.text-secondary-1-200 {
  color: white;
}

.text-secondary-1-300 {
  color: white;
}

.text-secondary-1-400 {
  color: #deecf2;
}

.text-secondary-1-600 {
  color: #94c0d6;
}

.text-secondary-1-700 {
  color: #6fabc8;
}

.text-secondary-1-800 {
  color: #4b95b9;
}

.text-secondary-1-900 {
  color: #3a7997;
}

.text-secondary-2 {
  color: #84bfdd;
}

.text-secondary-2-500 {
  color: #84bfdd;
}

.text-secondary-2-50 {
  color: white;
}

.text-secondary-2-70 {
  color: white;
}

.text-secondary-2-80 {
  color: white;
}

.text-secondary-2-100 {
  color: white;
}

.text-secondary-2-200 {
  color: #fcfdfe;
}

.text-secondary-2-300 {
  color: #d4e9f3;
}

.text-secondary-2-400 {
  color: #acd4e8;
}

.text-secondary-2-600 {
  color: #5caad2;
}

.text-secondary-2-700 {
  color: #3695c5;
}

.text-secondary-2-800 {
  color: #2b769d;
}

.text-secondary-2-900 {
  color: #205875;
}

.text-secondary-3 {
  color: #e7f4ff;
}

.text-secondary-3-500 {
  color: #e7f4ff;
}

.text-secondary-3-50 {
  color: white;
}

.text-secondary-3-70 {
  color: white;
}

.text-secondary-3-80 {
  color: white;
}

.text-secondary-3-100 {
  color: white;
}

.text-secondary-3-200 {
  color: white;
}

.text-secondary-3-300 {
  color: white;
}

.text-secondary-3-400 {
  color: white;
}

.text-secondary-3-600 {
  color: #b4ddff;
}

.text-secondary-3-700 {
  color: #81c5ff;
}

.text-secondary-3-800 {
  color: #4eaeff;
}

.text-secondary-3-900 {
  color: #1b97ff;
}

.text-background {
  color: #5d89b5;
}

.text-background-500 {
  color: #5d89b5;
}

.text-background-50 {
  color: #fbfcfd;
}

.text-background-70 {
  color: #f4f7fa;
}

.text-background-80 {
  color: #f0f4f8;
}

.text-background-100 {
  color: #e9eff5;
}

.text-background-200 {
  color: #c6d6e5;
}

.text-background-300 {
  color: #a3bcd5;
}

.text-background-400 {
  color: #80a3c5;
}

.text-background-600 {
  color: #467099;
}

.text-background-700 {
  color: #365676;
}

.text-background-800 {
  color: #263d53;
}

.text-background-900 {
  color: #162330;
}

.text-background-1 {
  color: #f4f6f8;
}

.text-background-1-500 {
  color: #f4f6f8;
}

.text-background-1-50 {
  color: white;
}

.text-background-1-70 {
  color: white;
}

.text-background-1-80 {
  color: white;
}

.text-background-1-100 {
  color: white;
}

.text-background-1-200 {
  color: white;
}

.text-background-1-300 {
  color: white;
}

.text-background-1-400 {
  color: white;
}

.text-background-1-600 {
  color: #d5dde4;
}

.text-background-1-700 {
  color: #b6c3d0;
}

.text-background-1-800 {
  color: #97aabd;
}

.text-background-1-900 {
  color: #7790a9;
}

.text-success {
  color: #2c9d82;
}

.text-success-500 {
  color: #2c9d82;
}

.text-success-50 {
  color: #c1eee3;
}

.text-success-70 {
  color: #b9ebdf;
}

.text-success-80 {
  color: #b5eade;
}

.text-success-100 {
  color: #ade8da;
}

.text-success-200 {
  color: #85ddc8;
}

.text-success-300 {
  color: #5dd2b6;
}

.text-success-400 {
  color: #37c5a3;
}

.text-success-600 {
  color: #217561;
}

.text-success-700 {
  color: #164d40;
}

.text-success-800 {
  color: #0b251f;
}

.text-success-900 {
  color: black;
}

.fill-white {
  fill: #fff;
}

.fill-white-500 {
  fill: #fff;
}

.fill-white-50 {
  fill: white;
}

.fill-white-70 {
  fill: white;
}

.fill-white-80 {
  fill: white;
}

.fill-white-100 {
  fill: white;
}

.fill-white-200 {
  fill: white;
}

.fill-white-300 {
  fill: white;
}

.fill-white-400 {
  fill: white;
}

.fill-white-600 {
  fill: #e6e6e6;
}

.fill-white-700 {
  fill: #cccccc;
}

.fill-white-800 {
  fill: #b3b3b3;
}

.fill-white-900 {
  fill: #999999;
}

.fill-black {
  fill: #000;
}

.fill-black-500 {
  fill: #000;
}

.fill-black-50 {
  fill: #737373;
}

.fill-black-70 {
  fill: #6e6e6e;
}

.fill-black-80 {
  fill: #6b6b6b;
}

.fill-black-100 {
  fill: #666666;
}

.fill-black-200 {
  fill: #4d4d4d;
}

.fill-black-300 {
  fill: #333333;
}

.fill-black-400 {
  fill: #1a1a1a;
}

.fill-black-600 {
  fill: black;
}

.fill-black-700 {
  fill: black;
}

.fill-black-800 {
  fill: black;
}

.fill-black-900 {
  fill: black;
}

.fill-green {
  fill: #1b6137;
}

.fill-green-500 {
  fill: #1b6137;
}

.fill-green-50 {
  fill: #85dda8;
}

.fill-green-70 {
  fill: #7ddba2;
}

.fill-green-80 {
  fill: #79da9f;
}

.fill-green-100 {
  fill: #71d79a;
}

.fill-green-200 {
  fill: #49cc7d;
}

.fill-green-300 {
  fill: #31b164;
}

.fill-green-400 {
  fill: #26894e;
}

.fill-green-600 {
  fill: #103920;
}

.fill-green-700 {
  fill: #05110a;
}

.fill-green-800 {
  fill: black;
}

.fill-green-900 {
  fill: black;
}

.fill-red {
  fill: #e94966;
}

.fill-red-500 {
  fill: #e94966;
}

.fill-red-50 {
  fill: white;
}

.fill-red-70 {
  fill: white;
}

.fill-red-80 {
  fill: white;
}

.fill-red-100 {
  fill: white;
}

.fill-red-200 {
  fill: #fad2d9;
}

.fill-red-300 {
  fill: #f4a4b3;
}

.fill-red-400 {
  fill: #ef778c;
}

.fill-red-600 {
  fill: #e41c40;
}

.fill-red-700 {
  fill: #b61633;
}

.fill-red-800 {
  fill: #891126;
}

.fill-red-900 {
  fill: #5b0b1a;
}

.fill-gray {
  fill: #808080;
}

.fill-gray-500 {
  fill: #808080;
}

.fill-gray-50 {
  fill: #f3f3f3;
}

.fill-gray-70 {
  fill: #eeeeee;
}

.fill-gray-80 {
  fill: #ebebeb;
}

.fill-gray-100 {
  fill: #e6e6e6;
}

.fill-gray-200 {
  fill: #cdcdcd;
}

.fill-gray-300 {
  fill: #b3b3b3;
}

.fill-gray-400 {
  fill: #9a9a9a;
}

.fill-gray-600 {
  fill: #676767;
}

.fill-gray-700 {
  fill: #4d4d4d;
}

.fill-gray-800 {
  fill: #343434;
}

.fill-gray-900 {
  fill: #1a1a1a;
}

.fill-orange {
  fill: #e9a85d;
}

.fill-orange-500 {
  fill: #e9a85d;
}

.fill-orange-50 {
  fill: white;
}

.fill-orange-70 {
  fill: white;
}

.fill-orange-80 {
  fill: white;
}

.fill-orange-100 {
  fill: white;
}

.fill-orange-200 {
  fill: #fbf0e4;
}

.fill-orange-300 {
  fill: #f5d8b7;
}

.fill-orange-400 {
  fill: #efc08a;
}

.fill-orange-600 {
  fill: #e39030;
}

.fill-orange-700 {
  fill: #c5761b;
}

.fill-orange-800 {
  fill: #985b15;
}

.fill-orange-900 {
  fill: #6b400f;
}

.fill-basic-1 {
  fill: #344256;
}

.fill-basic-1-500 {
  fill: #344256;
}

.fill-basic-1-50 {
  fill: #a6b5c9;
}

.fill-basic-1-70 {
  fill: #a0afc5;
}

.fill-basic-1-80 {
  fill: #9dadc4;
}

.fill-basic-1-100 {
  fill: #96a7c0;
}

.fill-basic-1-200 {
  fill: #778dac;
}

.fill-basic-1-300 {
  fill: #5a7396;
}

.fill-basic-1-400 {
  fill: #475a76;
}

.fill-basic-1-600 {
  fill: #212a36;
}

.fill-basic-1-700 {
  fill: #0e1116;
}

.fill-basic-1-800 {
  fill: black;
}

.fill-basic-1-900 {
  fill: black;
}

.fill-basic-2 {
  fill: #32475a;
}

.fill-basic-2-500 {
  fill: #32475a;
}

.fill-basic-2-50 {
  fill: #a5bacd;
}

.fill-basic-2-70 {
  fill: #9eb5c9;
}

.fill-basic-2-80 {
  fill: #9bb2c7;
}

.fill-basic-2-100 {
  fill: #94adc4;
}

.fill-basic-2-200 {
  fill: #7494b2;
}

.fill-basic-2-300 {
  fill: #567b9c;
}

.fill-basic-2-400 {
  fill: #44617b;
}

.fill-basic-2-600 {
  fill: #202d39;
}

.fill-basic-2-700 {
  fill: #0e1318;
}

.fill-basic-2-800 {
  fill: black;
}

.fill-basic-2-900 {
  fill: black;
}

.fill-basic-3 {
  fill: #6394c5;
}

.fill-basic-3-500 {
  fill: #6394c5;
}

.fill-basic-3-50 {
  fill: white;
}

.fill-basic-3-70 {
  fill: white;
}

.fill-basic-3-80 {
  fill: white;
}

.fill-basic-3-100 {
  fill: #f8fafc;
}

.fill-basic-3-200 {
  fill: #d3e1ee;
}

.fill-basic-3-300 {
  fill: #adc7e1;
}

.fill-basic-3-400 {
  fill: #88aed3;
}

.fill-basic-3-600 {
  fill: #427bb3;
}

.fill-basic-3-700 {
  fill: #35618d;
}

.fill-basic-3-800 {
  fill: #274868;
}

.fill-basic-3-900 {
  fill: #192e43;
}

.fill-primary-1 {
  fill: #007cba;
}

.fill-primary-1-500 {
  fill: #007cba;
}

.fill-primary-1-50 {
  fill: #a1e0ff;
}

.fill-primary-1-70 {
  fill: #96dcff;
}

.fill-primary-1-80 {
  fill: #91daff;
}

.fill-primary-1-100 {
  fill: #87d7ff;
}

.fill-primary-1-200 {
  fill: #54c6ff;
}

.fill-primary-1-300 {
  fill: #21b5ff;
}

.fill-primary-1-400 {
  fill: #009eed;
}

.fill-primary-1-600 {
  fill: #005a87;
}

.fill-primary-1-700 {
  fill: #003854;
}

.fill-primary-1-800 {
  fill: #001621;
}

.fill-primary-1-900 {
  fill: black;
}

.fill-primary-2 {
  fill: #7ec6ea;
}

.fill-primary-2-500 {
  fill: #7ec6ea;
}

.fill-primary-2-50 {
  fill: white;
}

.fill-primary-2-70 {
  fill: white;
}

.fill-primary-2-80 {
  fill: white;
}

.fill-primary-2-100 {
  fill: white;
}

.fill-primary-2-200 {
  fill: white;
}

.fill-primary-2-300 {
  fill: #d6edf8;
}

.fill-primary-2-400 {
  fill: #aad9f1;
}

.fill-primary-2-600 {
  fill: #52b3e3;
}

.fill-primary-2-700 {
  fill: #269fdc;
}

.fill-primary-2-800 {
  fill: #1d80b2;
}

.fill-primary-2-900 {
  fill: #166186;
}

.fill-secondary-1 {
  fill: #b9d6e4;
}

.fill-secondary-1-500 {
  fill: #b9d6e4;
}

.fill-secondary-1-50 {
  fill: white;
}

.fill-secondary-1-70 {
  fill: white;
}

.fill-secondary-1-80 {
  fill: white;
}

.fill-secondary-1-100 {
  fill: white;
}

.fill-secondary-1-200 {
  fill: white;
}

.fill-secondary-1-300 {
  fill: white;
}

.fill-secondary-1-400 {
  fill: #deecf2;
}

.fill-secondary-1-600 {
  fill: #94c0d6;
}

.fill-secondary-1-700 {
  fill: #6fabc8;
}

.fill-secondary-1-800 {
  fill: #4b95b9;
}

.fill-secondary-1-900 {
  fill: #3a7997;
}

.fill-secondary-2 {
  fill: #84bfdd;
}

.fill-secondary-2-500 {
  fill: #84bfdd;
}

.fill-secondary-2-50 {
  fill: white;
}

.fill-secondary-2-70 {
  fill: white;
}

.fill-secondary-2-80 {
  fill: white;
}

.fill-secondary-2-100 {
  fill: white;
}

.fill-secondary-2-200 {
  fill: #fcfdfe;
}

.fill-secondary-2-300 {
  fill: #d4e9f3;
}

.fill-secondary-2-400 {
  fill: #acd4e8;
}

.fill-secondary-2-600 {
  fill: #5caad2;
}

.fill-secondary-2-700 {
  fill: #3695c5;
}

.fill-secondary-2-800 {
  fill: #2b769d;
}

.fill-secondary-2-900 {
  fill: #205875;
}

.fill-secondary-3 {
  fill: #e7f4ff;
}

.fill-secondary-3-500 {
  fill: #e7f4ff;
}

.fill-secondary-3-50 {
  fill: white;
}

.fill-secondary-3-70 {
  fill: white;
}

.fill-secondary-3-80 {
  fill: white;
}

.fill-secondary-3-100 {
  fill: white;
}

.fill-secondary-3-200 {
  fill: white;
}

.fill-secondary-3-300 {
  fill: white;
}

.fill-secondary-3-400 {
  fill: white;
}

.fill-secondary-3-600 {
  fill: #b4ddff;
}

.fill-secondary-3-700 {
  fill: #81c5ff;
}

.fill-secondary-3-800 {
  fill: #4eaeff;
}

.fill-secondary-3-900 {
  fill: #1b97ff;
}

.fill-background {
  fill: #5d89b5;
}

.fill-background-500 {
  fill: #5d89b5;
}

.fill-background-50 {
  fill: #fbfcfd;
}

.fill-background-70 {
  fill: #f4f7fa;
}

.fill-background-80 {
  fill: #f0f4f8;
}

.fill-background-100 {
  fill: #e9eff5;
}

.fill-background-200 {
  fill: #c6d6e5;
}

.fill-background-300 {
  fill: #a3bcd5;
}

.fill-background-400 {
  fill: #80a3c5;
}

.fill-background-600 {
  fill: #467099;
}

.fill-background-700 {
  fill: #365676;
}

.fill-background-800 {
  fill: #263d53;
}

.fill-background-900 {
  fill: #162330;
}

.fill-background-1 {
  fill: #f4f6f8;
}

.fill-background-1-500 {
  fill: #f4f6f8;
}

.fill-background-1-50 {
  fill: white;
}

.fill-background-1-70 {
  fill: white;
}

.fill-background-1-80 {
  fill: white;
}

.fill-background-1-100 {
  fill: white;
}

.fill-background-1-200 {
  fill: white;
}

.fill-background-1-300 {
  fill: white;
}

.fill-background-1-400 {
  fill: white;
}

.fill-background-1-600 {
  fill: #d5dde4;
}

.fill-background-1-700 {
  fill: #b6c3d0;
}

.fill-background-1-800 {
  fill: #97aabd;
}

.fill-background-1-900 {
  fill: #7790a9;
}

.fill-success {
  fill: #2c9d82;
}

.fill-success-500 {
  fill: #2c9d82;
}

.fill-success-50 {
  fill: #c1eee3;
}

.fill-success-70 {
  fill: #b9ebdf;
}

.fill-success-80 {
  fill: #b5eade;
}

.fill-success-100 {
  fill: #ade8da;
}

.fill-success-200 {
  fill: #85ddc8;
}

.fill-success-300 {
  fill: #5dd2b6;
}

.fill-success-400 {
  fill: #37c5a3;
}

.fill-success-600 {
  fill: #217561;
}

.fill-success-700 {
  fill: #164d40;
}

.fill-success-800 {
  fill: #0b251f;
}

.fill-success-900 {
  fill: black;
}

.stroke-white {
  stroke: #fff;
}

.stroke-white-500 {
  stroke: #fff;
}

.stroke-white-50 {
  stroke: white;
}

.stroke-white-70 {
  stroke: white;
}

.stroke-white-80 {
  stroke: white;
}

.stroke-white-100 {
  stroke: white;
}

.stroke-white-200 {
  stroke: white;
}

.stroke-white-300 {
  stroke: white;
}

.stroke-white-400 {
  stroke: white;
}

.stroke-white-600 {
  stroke: #e6e6e6;
}

.stroke-white-700 {
  stroke: #cccccc;
}

.stroke-white-800 {
  stroke: #b3b3b3;
}

.stroke-white-900 {
  stroke: #999999;
}

.stroke-black {
  stroke: #000;
}

.stroke-black-500 {
  stroke: #000;
}

.stroke-black-50 {
  stroke: #737373;
}

.stroke-black-70 {
  stroke: #6e6e6e;
}

.stroke-black-80 {
  stroke: #6b6b6b;
}

.stroke-black-100 {
  stroke: #666666;
}

.stroke-black-200 {
  stroke: #4d4d4d;
}

.stroke-black-300 {
  stroke: #333333;
}

.stroke-black-400 {
  stroke: #1a1a1a;
}

.stroke-black-600 {
  stroke: black;
}

.stroke-black-700 {
  stroke: black;
}

.stroke-black-800 {
  stroke: black;
}

.stroke-black-900 {
  stroke: black;
}

.stroke-green {
  stroke: #1b6137;
}

.stroke-green-500 {
  stroke: #1b6137;
}

.stroke-green-50 {
  stroke: #85dda8;
}

.stroke-green-70 {
  stroke: #7ddba2;
}

.stroke-green-80 {
  stroke: #79da9f;
}

.stroke-green-100 {
  stroke: #71d79a;
}

.stroke-green-200 {
  stroke: #49cc7d;
}

.stroke-green-300 {
  stroke: #31b164;
}

.stroke-green-400 {
  stroke: #26894e;
}

.stroke-green-600 {
  stroke: #103920;
}

.stroke-green-700 {
  stroke: #05110a;
}

.stroke-green-800 {
  stroke: black;
}

.stroke-green-900 {
  stroke: black;
}

.stroke-red {
  stroke: #e94966;
}

.stroke-red-500 {
  stroke: #e94966;
}

.stroke-red-50 {
  stroke: white;
}

.stroke-red-70 {
  stroke: white;
}

.stroke-red-80 {
  stroke: white;
}

.stroke-red-100 {
  stroke: white;
}

.stroke-red-200 {
  stroke: #fad2d9;
}

.stroke-red-300 {
  stroke: #f4a4b3;
}

.stroke-red-400 {
  stroke: #ef778c;
}

.stroke-red-600 {
  stroke: #e41c40;
}

.stroke-red-700 {
  stroke: #b61633;
}

.stroke-red-800 {
  stroke: #891126;
}

.stroke-red-900 {
  stroke: #5b0b1a;
}

.stroke-gray {
  stroke: #808080;
}

.stroke-gray-500 {
  stroke: #808080;
}

.stroke-gray-50 {
  stroke: #f3f3f3;
}

.stroke-gray-70 {
  stroke: #eeeeee;
}

.stroke-gray-80 {
  stroke: #ebebeb;
}

.stroke-gray-100 {
  stroke: #e6e6e6;
}

.stroke-gray-200 {
  stroke: #cdcdcd;
}

.stroke-gray-300 {
  stroke: #b3b3b3;
}

.stroke-gray-400 {
  stroke: #9a9a9a;
}

.stroke-gray-600 {
  stroke: #676767;
}

.stroke-gray-700 {
  stroke: #4d4d4d;
}

.stroke-gray-800 {
  stroke: #343434;
}

.stroke-gray-900 {
  stroke: #1a1a1a;
}

.stroke-orange {
  stroke: #e9a85d;
}

.stroke-orange-500 {
  stroke: #e9a85d;
}

.stroke-orange-50 {
  stroke: white;
}

.stroke-orange-70 {
  stroke: white;
}

.stroke-orange-80 {
  stroke: white;
}

.stroke-orange-100 {
  stroke: white;
}

.stroke-orange-200 {
  stroke: #fbf0e4;
}

.stroke-orange-300 {
  stroke: #f5d8b7;
}

.stroke-orange-400 {
  stroke: #efc08a;
}

.stroke-orange-600 {
  stroke: #e39030;
}

.stroke-orange-700 {
  stroke: #c5761b;
}

.stroke-orange-800 {
  stroke: #985b15;
}

.stroke-orange-900 {
  stroke: #6b400f;
}

.stroke-basic-1 {
  stroke: #344256;
}

.stroke-basic-1-500 {
  stroke: #344256;
}

.stroke-basic-1-50 {
  stroke: #a6b5c9;
}

.stroke-basic-1-70 {
  stroke: #a0afc5;
}

.stroke-basic-1-80 {
  stroke: #9dadc4;
}

.stroke-basic-1-100 {
  stroke: #96a7c0;
}

.stroke-basic-1-200 {
  stroke: #778dac;
}

.stroke-basic-1-300 {
  stroke: #5a7396;
}

.stroke-basic-1-400 {
  stroke: #475a76;
}

.stroke-basic-1-600 {
  stroke: #212a36;
}

.stroke-basic-1-700 {
  stroke: #0e1116;
}

.stroke-basic-1-800 {
  stroke: black;
}

.stroke-basic-1-900 {
  stroke: black;
}

.stroke-basic-2 {
  stroke: #32475a;
}

.stroke-basic-2-500 {
  stroke: #32475a;
}

.stroke-basic-2-50 {
  stroke: #a5bacd;
}

.stroke-basic-2-70 {
  stroke: #9eb5c9;
}

.stroke-basic-2-80 {
  stroke: #9bb2c7;
}

.stroke-basic-2-100 {
  stroke: #94adc4;
}

.stroke-basic-2-200 {
  stroke: #7494b2;
}

.stroke-basic-2-300 {
  stroke: #567b9c;
}

.stroke-basic-2-400 {
  stroke: #44617b;
}

.stroke-basic-2-600 {
  stroke: #202d39;
}

.stroke-basic-2-700 {
  stroke: #0e1318;
}

.stroke-basic-2-800 {
  stroke: black;
}

.stroke-basic-2-900 {
  stroke: black;
}

.stroke-basic-3 {
  stroke: #6394c5;
}

.stroke-basic-3-500 {
  stroke: #6394c5;
}

.stroke-basic-3-50 {
  stroke: white;
}

.stroke-basic-3-70 {
  stroke: white;
}

.stroke-basic-3-80 {
  stroke: white;
}

.stroke-basic-3-100 {
  stroke: #f8fafc;
}

.stroke-basic-3-200 {
  stroke: #d3e1ee;
}

.stroke-basic-3-300 {
  stroke: #adc7e1;
}

.stroke-basic-3-400 {
  stroke: #88aed3;
}

.stroke-basic-3-600 {
  stroke: #427bb3;
}

.stroke-basic-3-700 {
  stroke: #35618d;
}

.stroke-basic-3-800 {
  stroke: #274868;
}

.stroke-basic-3-900 {
  stroke: #192e43;
}

.stroke-primary-1 {
  stroke: #007cba;
}

.stroke-primary-1-500 {
  stroke: #007cba;
}

.stroke-primary-1-50 {
  stroke: #a1e0ff;
}

.stroke-primary-1-70 {
  stroke: #96dcff;
}

.stroke-primary-1-80 {
  stroke: #91daff;
}

.stroke-primary-1-100 {
  stroke: #87d7ff;
}

.stroke-primary-1-200 {
  stroke: #54c6ff;
}

.stroke-primary-1-300 {
  stroke: #21b5ff;
}

.stroke-primary-1-400 {
  stroke: #009eed;
}

.stroke-primary-1-600 {
  stroke: #005a87;
}

.stroke-primary-1-700 {
  stroke: #003854;
}

.stroke-primary-1-800 {
  stroke: #001621;
}

.stroke-primary-1-900 {
  stroke: black;
}

.stroke-primary-2 {
  stroke: #7ec6ea;
}

.stroke-primary-2-500 {
  stroke: #7ec6ea;
}

.stroke-primary-2-50 {
  stroke: white;
}

.stroke-primary-2-70 {
  stroke: white;
}

.stroke-primary-2-80 {
  stroke: white;
}

.stroke-primary-2-100 {
  stroke: white;
}

.stroke-primary-2-200 {
  stroke: white;
}

.stroke-primary-2-300 {
  stroke: #d6edf8;
}

.stroke-primary-2-400 {
  stroke: #aad9f1;
}

.stroke-primary-2-600 {
  stroke: #52b3e3;
}

.stroke-primary-2-700 {
  stroke: #269fdc;
}

.stroke-primary-2-800 {
  stroke: #1d80b2;
}

.stroke-primary-2-900 {
  stroke: #166186;
}

.stroke-secondary-1 {
  stroke: #b9d6e4;
}

.stroke-secondary-1-500 {
  stroke: #b9d6e4;
}

.stroke-secondary-1-50 {
  stroke: white;
}

.stroke-secondary-1-70 {
  stroke: white;
}

.stroke-secondary-1-80 {
  stroke: white;
}

.stroke-secondary-1-100 {
  stroke: white;
}

.stroke-secondary-1-200 {
  stroke: white;
}

.stroke-secondary-1-300 {
  stroke: white;
}

.stroke-secondary-1-400 {
  stroke: #deecf2;
}

.stroke-secondary-1-600 {
  stroke: #94c0d6;
}

.stroke-secondary-1-700 {
  stroke: #6fabc8;
}

.stroke-secondary-1-800 {
  stroke: #4b95b9;
}

.stroke-secondary-1-900 {
  stroke: #3a7997;
}

.stroke-secondary-2 {
  stroke: #84bfdd;
}

.stroke-secondary-2-500 {
  stroke: #84bfdd;
}

.stroke-secondary-2-50 {
  stroke: white;
}

.stroke-secondary-2-70 {
  stroke: white;
}

.stroke-secondary-2-80 {
  stroke: white;
}

.stroke-secondary-2-100 {
  stroke: white;
}

.stroke-secondary-2-200 {
  stroke: #fcfdfe;
}

.stroke-secondary-2-300 {
  stroke: #d4e9f3;
}

.stroke-secondary-2-400 {
  stroke: #acd4e8;
}

.stroke-secondary-2-600 {
  stroke: #5caad2;
}

.stroke-secondary-2-700 {
  stroke: #3695c5;
}

.stroke-secondary-2-800 {
  stroke: #2b769d;
}

.stroke-secondary-2-900 {
  stroke: #205875;
}

.stroke-secondary-3 {
  stroke: #e7f4ff;
}

.stroke-secondary-3-500 {
  stroke: #e7f4ff;
}

.stroke-secondary-3-50 {
  stroke: white;
}

.stroke-secondary-3-70 {
  stroke: white;
}

.stroke-secondary-3-80 {
  stroke: white;
}

.stroke-secondary-3-100 {
  stroke: white;
}

.stroke-secondary-3-200 {
  stroke: white;
}

.stroke-secondary-3-300 {
  stroke: white;
}

.stroke-secondary-3-400 {
  stroke: white;
}

.stroke-secondary-3-600 {
  stroke: #b4ddff;
}

.stroke-secondary-3-700 {
  stroke: #81c5ff;
}

.stroke-secondary-3-800 {
  stroke: #4eaeff;
}

.stroke-secondary-3-900 {
  stroke: #1b97ff;
}

.stroke-background {
  stroke: #5d89b5;
}

.stroke-background-500 {
  stroke: #5d89b5;
}

.stroke-background-50 {
  stroke: #fbfcfd;
}

.stroke-background-70 {
  stroke: #f4f7fa;
}

.stroke-background-80 {
  stroke: #f0f4f8;
}

.stroke-background-100 {
  stroke: #e9eff5;
}

.stroke-background-200 {
  stroke: #c6d6e5;
}

.stroke-background-300 {
  stroke: #a3bcd5;
}

.stroke-background-400 {
  stroke: #80a3c5;
}

.stroke-background-600 {
  stroke: #467099;
}

.stroke-background-700 {
  stroke: #365676;
}

.stroke-background-800 {
  stroke: #263d53;
}

.stroke-background-900 {
  stroke: #162330;
}

.stroke-background-1 {
  stroke: #f4f6f8;
}

.stroke-background-1-500 {
  stroke: #f4f6f8;
}

.stroke-background-1-50 {
  stroke: white;
}

.stroke-background-1-70 {
  stroke: white;
}

.stroke-background-1-80 {
  stroke: white;
}

.stroke-background-1-100 {
  stroke: white;
}

.stroke-background-1-200 {
  stroke: white;
}

.stroke-background-1-300 {
  stroke: white;
}

.stroke-background-1-400 {
  stroke: white;
}

.stroke-background-1-600 {
  stroke: #d5dde4;
}

.stroke-background-1-700 {
  stroke: #b6c3d0;
}

.stroke-background-1-800 {
  stroke: #97aabd;
}

.stroke-background-1-900 {
  stroke: #7790a9;
}

.stroke-success {
  stroke: #2c9d82;
}

.stroke-success-500 {
  stroke: #2c9d82;
}

.stroke-success-50 {
  stroke: #c1eee3;
}

.stroke-success-70 {
  stroke: #b9ebdf;
}

.stroke-success-80 {
  stroke: #b5eade;
}

.stroke-success-100 {
  stroke: #ade8da;
}

.stroke-success-200 {
  stroke: #85ddc8;
}

.stroke-success-300 {
  stroke: #5dd2b6;
}

.stroke-success-400 {
  stroke: #37c5a3;
}

.stroke-success-600 {
  stroke: #217561;
}

.stroke-success-700 {
  stroke: #164d40;
}

.stroke-success-800 {
  stroke: #0b251f;
}

.stroke-success-900 {
  stroke: black;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-l-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-r-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-t-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-b-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-l-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-r-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-t-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-b-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-l-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-r-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-t-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-b-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-4 {
  border-width: 4px !important;
  border-style: solid;
}

.border-l-4 {
  border-left-width: 4px !important;
  border-left-style: solid;
}

.border-r-4 {
  border-right-width: 4px !important;
  border-right-style: solid;
}

.border-t-4 {
  border-top-width: 4px !important;
  border-top-style: solid;
}

.border-b-4 {
  border-bottom-width: 4px !important;
  border-bottom-style: solid;
}

.border-5 {
  border-width: 5px !important;
  border-style: solid;
}

.border-l-5 {
  border-left-width: 5px !important;
  border-left-style: solid;
}

.border-r-5 {
  border-right-width: 5px !important;
  border-right-style: solid;
}

.border-t-5 {
  border-top-width: 5px !important;
  border-top-style: solid;
}

.border-b-5 {
  border-bottom-width: 5px !important;
  border-bottom-style: solid;
}

.border-6 {
  border-width: 6px !important;
  border-style: solid;
}

.border-l-6 {
  border-left-width: 6px !important;
  border-left-style: solid;
}

.border-r-6 {
  border-right-width: 6px !important;
  border-right-style: solid;
}

.border-t-6 {
  border-top-width: 6px !important;
  border-top-style: solid;
}

.border-b-6 {
  border-bottom-width: 6px !important;
  border-bottom-style: solid;
}

.border-7 {
  border-width: 7px !important;
  border-style: solid;
}

.border-l-7 {
  border-left-width: 7px !important;
  border-left-style: solid;
}

.border-r-7 {
  border-right-width: 7px !important;
  border-right-style: solid;
}

.border-t-7 {
  border-top-width: 7px !important;
  border-top-style: solid;
}

.border-b-7 {
  border-bottom-width: 7px !important;
  border-bottom-style: solid;
}

.border-8 {
  border-width: 8px !important;
  border-style: solid;
}

.border-l-8 {
  border-left-width: 8px !important;
  border-left-style: solid;
}

.border-r-8 {
  border-right-width: 8px !important;
  border-right-style: solid;
}

.border-t-8 {
  border-top-width: 8px !important;
  border-top-style: solid;
}

.border-b-8 {
  border-bottom-width: 8px !important;
  border-bottom-style: solid;
}

.border-9 {
  border-width: 9px !important;
  border-style: solid;
}

.border-l-9 {
  border-left-width: 9px !important;
  border-left-style: solid;
}

.border-r-9 {
  border-right-width: 9px !important;
  border-right-style: solid;
}

.border-t-9 {
  border-top-width: 9px !important;
  border-top-style: solid;
}

.border-b-9 {
  border-bottom-width: 9px !important;
  border-bottom-style: solid;
}

.border-10 {
  border-width: 10px !important;
  border-style: solid;
}

.border-l-10 {
  border-left-width: 10px !important;
  border-left-style: solid;
}

.border-r-10 {
  border-right-width: 10px !important;
  border-right-style: solid;
}

.border-t-10 {
  border-top-width: 10px !important;
  border-top-style: solid;
}

.border-b-10 {
  border-bottom-width: 10px !important;
  border-bottom-style: solid;
}

.border-11 {
  border-width: 11px !important;
  border-style: solid;
}

.border-l-11 {
  border-left-width: 11px !important;
  border-left-style: solid;
}

.border-r-11 {
  border-right-width: 11px !important;
  border-right-style: solid;
}

.border-t-11 {
  border-top-width: 11px !important;
  border-top-style: solid;
}

.border-b-11 {
  border-bottom-width: 11px !important;
  border-bottom-style: solid;
}

.border-12 {
  border-width: 12px !important;
  border-style: solid;
}

.border-l-12 {
  border-left-width: 12px !important;
  border-left-style: solid;
}

.border-r-12 {
  border-right-width: 12px !important;
  border-right-style: solid;
}

.border-t-12 {
  border-top-width: 12px !important;
  border-top-style: solid;
}

.border-b-12 {
  border-bottom-width: 12px !important;
  border-bottom-style: solid;
}

.border-13 {
  border-width: 13px !important;
  border-style: solid;
}

.border-l-13 {
  border-left-width: 13px !important;
  border-left-style: solid;
}

.border-r-13 {
  border-right-width: 13px !important;
  border-right-style: solid;
}

.border-t-13 {
  border-top-width: 13px !important;
  border-top-style: solid;
}

.border-b-13 {
  border-bottom-width: 13px !important;
  border-bottom-style: solid;
}

.border-14 {
  border-width: 14px !important;
  border-style: solid;
}

.border-l-14 {
  border-left-width: 14px !important;
  border-left-style: solid;
}

.border-r-14 {
  border-right-width: 14px !important;
  border-right-style: solid;
}

.border-t-14 {
  border-top-width: 14px !important;
  border-top-style: solid;
}

.border-b-14 {
  border-bottom-width: 14px !important;
  border-bottom-style: solid;
}

.border-15 {
  border-width: 15px !important;
  border-style: solid;
}

.border-l-15 {
  border-left-width: 15px !important;
  border-left-style: solid;
}

.border-r-15 {
  border-right-width: 15px !important;
  border-right-style: solid;
}

.border-t-15 {
  border-top-width: 15px !important;
  border-top-style: solid;
}

.border-b-15 {
  border-bottom-width: 15px !important;
  border-bottom-style: solid;
}

.border-16 {
  border-width: 16px !important;
  border-style: solid;
}

.border-l-16 {
  border-left-width: 16px !important;
  border-left-style: solid;
}

.border-r-16 {
  border-right-width: 16px !important;
  border-right-style: solid;
}

.border-t-16 {
  border-top-width: 16px !important;
  border-top-style: solid;
}

.border-b-16 {
  border-bottom-width: 16px !important;
  border-bottom-style: solid;
}

.border-17 {
  border-width: 17px !important;
  border-style: solid;
}

.border-l-17 {
  border-left-width: 17px !important;
  border-left-style: solid;
}

.border-r-17 {
  border-right-width: 17px !important;
  border-right-style: solid;
}

.border-t-17 {
  border-top-width: 17px !important;
  border-top-style: solid;
}

.border-b-17 {
  border-bottom-width: 17px !important;
  border-bottom-style: solid;
}

.border-18 {
  border-width: 18px !important;
  border-style: solid;
}

.border-l-18 {
  border-left-width: 18px !important;
  border-left-style: solid;
}

.border-r-18 {
  border-right-width: 18px !important;
  border-right-style: solid;
}

.border-t-18 {
  border-top-width: 18px !important;
  border-top-style: solid;
}

.border-b-18 {
  border-bottom-width: 18px !important;
  border-bottom-style: solid;
}

.border-19 {
  border-width: 19px !important;
  border-style: solid;
}

.border-l-19 {
  border-left-width: 19px !important;
  border-left-style: solid;
}

.border-r-19 {
  border-right-width: 19px !important;
  border-right-style: solid;
}

.border-t-19 {
  border-top-width: 19px !important;
  border-top-style: solid;
}

.border-b-19 {
  border-bottom-width: 19px !important;
  border-bottom-style: solid;
}

.border-20 {
  border-width: 20px !important;
  border-style: solid;
}

.border-l-20 {
  border-left-width: 20px !important;
  border-left-style: solid;
}

.border-r-20 {
  border-right-width: 20px !important;
  border-right-style: solid;
}

.border-t-20 {
  border-top-width: 20px !important;
  border-top-style: solid;
}

.border-b-20 {
  border-bottom-width: 20px !important;
  border-bottom-style: solid;
}

.border-white {
  border-color: #fff;
}

.border-l-white {
  border-left-color: #fff;
}

.border-r-white {
  border-right-color: #fff;
}

.border-t-white {
  border-top-color: #fff;
}

.border-b-white {
  border-bottom-color: #fff;
}

.border-white-500 {
  border-color: #fff;
}

.border-l-white-500 {
  border-left-color: #fff;
}

.border-r-white-500 {
  border-right-color: #fff;
}

.border-t-white-500 {
  border-top-color: #fff;
}

.border-b-white-500 {
  border-bottom-color: #fff;
}

.border-white-50 {
  border-color: white;
}

.border-l-white-50 {
  border-left-color: white;
}

.border-r-white-50 {
  border-right-color: white;
}

.border-t-white-50 {
  border-top-color: white;
}

.border-b-white-50 {
  border-bottom-color: white;
}

.border-white-70 {
  border-color: white;
}

.border-l-white-70 {
  border-left-color: white;
}

.border-r-white-70 {
  border-right-color: white;
}

.border-t-white-70 {
  border-top-color: white;
}

.border-b-white-70 {
  border-bottom-color: white;
}

.border-white-80 {
  border-color: white;
}

.border-l-white-80 {
  border-left-color: white;
}

.border-r-white-80 {
  border-right-color: white;
}

.border-t-white-80 {
  border-top-color: white;
}

.border-b-white-80 {
  border-bottom-color: white;
}

.border-white-100 {
  border-color: white;
}

.border-l-white-100 {
  border-left-color: white;
}

.border-r-white-100 {
  border-right-color: white;
}

.border-t-white-100 {
  border-top-color: white;
}

.border-b-white-100 {
  border-bottom-color: white;
}

.border-white-200 {
  border-color: white;
}

.border-l-white-200 {
  border-left-color: white;
}

.border-r-white-200 {
  border-right-color: white;
}

.border-t-white-200 {
  border-top-color: white;
}

.border-b-white-200 {
  border-bottom-color: white;
}

.border-white-300 {
  border-color: white;
}

.border-l-white-300 {
  border-left-color: white;
}

.border-r-white-300 {
  border-right-color: white;
}

.border-t-white-300 {
  border-top-color: white;
}

.border-b-white-300 {
  border-bottom-color: white;
}

.border-white-400 {
  border-color: white;
}

.border-l-white-400 {
  border-left-color: white;
}

.border-r-white-400 {
  border-right-color: white;
}

.border-t-white-400 {
  border-top-color: white;
}

.border-b-white-400 {
  border-bottom-color: white;
}

.border-white-600 {
  border-color: #e6e6e6;
}

.border-l-white-600 {
  border-left-color: #e6e6e6;
}

.border-r-white-600 {
  border-right-color: #e6e6e6;
}

.border-t-white-600 {
  border-top-color: #e6e6e6;
}

.border-b-white-600 {
  border-bottom-color: #e6e6e6;
}

.border-white-700 {
  border-color: #cccccc;
}

.border-l-white-700 {
  border-left-color: #cccccc;
}

.border-r-white-700 {
  border-right-color: #cccccc;
}

.border-t-white-700 {
  border-top-color: #cccccc;
}

.border-b-white-700 {
  border-bottom-color: #cccccc;
}

.border-white-800 {
  border-color: #b3b3b3;
}

.border-l-white-800 {
  border-left-color: #b3b3b3;
}

.border-r-white-800 {
  border-right-color: #b3b3b3;
}

.border-t-white-800 {
  border-top-color: #b3b3b3;
}

.border-b-white-800 {
  border-bottom-color: #b3b3b3;
}

.border-white-900 {
  border-color: #999999;
}

.border-l-white-900 {
  border-left-color: #999999;
}

.border-r-white-900 {
  border-right-color: #999999;
}

.border-t-white-900 {
  border-top-color: #999999;
}

.border-b-white-900 {
  border-bottom-color: #999999;
}

.border-black {
  border-color: #000;
}

.border-l-black {
  border-left-color: #000;
}

.border-r-black {
  border-right-color: #000;
}

.border-t-black {
  border-top-color: #000;
}

.border-b-black {
  border-bottom-color: #000;
}

.border-black-500 {
  border-color: #000;
}

.border-l-black-500 {
  border-left-color: #000;
}

.border-r-black-500 {
  border-right-color: #000;
}

.border-t-black-500 {
  border-top-color: #000;
}

.border-b-black-500 {
  border-bottom-color: #000;
}

.border-black-50 {
  border-color: #737373;
}

.border-l-black-50 {
  border-left-color: #737373;
}

.border-r-black-50 {
  border-right-color: #737373;
}

.border-t-black-50 {
  border-top-color: #737373;
}

.border-b-black-50 {
  border-bottom-color: #737373;
}

.border-black-70 {
  border-color: #6e6e6e;
}

.border-l-black-70 {
  border-left-color: #6e6e6e;
}

.border-r-black-70 {
  border-right-color: #6e6e6e;
}

.border-t-black-70 {
  border-top-color: #6e6e6e;
}

.border-b-black-70 {
  border-bottom-color: #6e6e6e;
}

.border-black-80 {
  border-color: #6b6b6b;
}

.border-l-black-80 {
  border-left-color: #6b6b6b;
}

.border-r-black-80 {
  border-right-color: #6b6b6b;
}

.border-t-black-80 {
  border-top-color: #6b6b6b;
}

.border-b-black-80 {
  border-bottom-color: #6b6b6b;
}

.border-black-100 {
  border-color: #666666;
}

.border-l-black-100 {
  border-left-color: #666666;
}

.border-r-black-100 {
  border-right-color: #666666;
}

.border-t-black-100 {
  border-top-color: #666666;
}

.border-b-black-100 {
  border-bottom-color: #666666;
}

.border-black-200 {
  border-color: #4d4d4d;
}

.border-l-black-200 {
  border-left-color: #4d4d4d;
}

.border-r-black-200 {
  border-right-color: #4d4d4d;
}

.border-t-black-200 {
  border-top-color: #4d4d4d;
}

.border-b-black-200 {
  border-bottom-color: #4d4d4d;
}

.border-black-300 {
  border-color: #333333;
}

.border-l-black-300 {
  border-left-color: #333333;
}

.border-r-black-300 {
  border-right-color: #333333;
}

.border-t-black-300 {
  border-top-color: #333333;
}

.border-b-black-300 {
  border-bottom-color: #333333;
}

.border-black-400 {
  border-color: #1a1a1a;
}

.border-l-black-400 {
  border-left-color: #1a1a1a;
}

.border-r-black-400 {
  border-right-color: #1a1a1a;
}

.border-t-black-400 {
  border-top-color: #1a1a1a;
}

.border-b-black-400 {
  border-bottom-color: #1a1a1a;
}

.border-black-600 {
  border-color: black;
}

.border-l-black-600 {
  border-left-color: black;
}

.border-r-black-600 {
  border-right-color: black;
}

.border-t-black-600 {
  border-top-color: black;
}

.border-b-black-600 {
  border-bottom-color: black;
}

.border-black-700 {
  border-color: black;
}

.border-l-black-700 {
  border-left-color: black;
}

.border-r-black-700 {
  border-right-color: black;
}

.border-t-black-700 {
  border-top-color: black;
}

.border-b-black-700 {
  border-bottom-color: black;
}

.border-black-800 {
  border-color: black;
}

.border-l-black-800 {
  border-left-color: black;
}

.border-r-black-800 {
  border-right-color: black;
}

.border-t-black-800 {
  border-top-color: black;
}

.border-b-black-800 {
  border-bottom-color: black;
}

.border-black-900 {
  border-color: black;
}

.border-l-black-900 {
  border-left-color: black;
}

.border-r-black-900 {
  border-right-color: black;
}

.border-t-black-900 {
  border-top-color: black;
}

.border-b-black-900 {
  border-bottom-color: black;
}

.border-green {
  border-color: #1b6137;
}

.border-l-green {
  border-left-color: #1b6137;
}

.border-r-green {
  border-right-color: #1b6137;
}

.border-t-green {
  border-top-color: #1b6137;
}

.border-b-green {
  border-bottom-color: #1b6137;
}

.border-green-500 {
  border-color: #1b6137;
}

.border-l-green-500 {
  border-left-color: #1b6137;
}

.border-r-green-500 {
  border-right-color: #1b6137;
}

.border-t-green-500 {
  border-top-color: #1b6137;
}

.border-b-green-500 {
  border-bottom-color: #1b6137;
}

.border-green-50 {
  border-color: #85dda8;
}

.border-l-green-50 {
  border-left-color: #85dda8;
}

.border-r-green-50 {
  border-right-color: #85dda8;
}

.border-t-green-50 {
  border-top-color: #85dda8;
}

.border-b-green-50 {
  border-bottom-color: #85dda8;
}

.border-green-70 {
  border-color: #7ddba2;
}

.border-l-green-70 {
  border-left-color: #7ddba2;
}

.border-r-green-70 {
  border-right-color: #7ddba2;
}

.border-t-green-70 {
  border-top-color: #7ddba2;
}

.border-b-green-70 {
  border-bottom-color: #7ddba2;
}

.border-green-80 {
  border-color: #79da9f;
}

.border-l-green-80 {
  border-left-color: #79da9f;
}

.border-r-green-80 {
  border-right-color: #79da9f;
}

.border-t-green-80 {
  border-top-color: #79da9f;
}

.border-b-green-80 {
  border-bottom-color: #79da9f;
}

.border-green-100 {
  border-color: #71d79a;
}

.border-l-green-100 {
  border-left-color: #71d79a;
}

.border-r-green-100 {
  border-right-color: #71d79a;
}

.border-t-green-100 {
  border-top-color: #71d79a;
}

.border-b-green-100 {
  border-bottom-color: #71d79a;
}

.border-green-200 {
  border-color: #49cc7d;
}

.border-l-green-200 {
  border-left-color: #49cc7d;
}

.border-r-green-200 {
  border-right-color: #49cc7d;
}

.border-t-green-200 {
  border-top-color: #49cc7d;
}

.border-b-green-200 {
  border-bottom-color: #49cc7d;
}

.border-green-300 {
  border-color: #31b164;
}

.border-l-green-300 {
  border-left-color: #31b164;
}

.border-r-green-300 {
  border-right-color: #31b164;
}

.border-t-green-300 {
  border-top-color: #31b164;
}

.border-b-green-300 {
  border-bottom-color: #31b164;
}

.border-green-400 {
  border-color: #26894e;
}

.border-l-green-400 {
  border-left-color: #26894e;
}

.border-r-green-400 {
  border-right-color: #26894e;
}

.border-t-green-400 {
  border-top-color: #26894e;
}

.border-b-green-400 {
  border-bottom-color: #26894e;
}

.border-green-600 {
  border-color: #103920;
}

.border-l-green-600 {
  border-left-color: #103920;
}

.border-r-green-600 {
  border-right-color: #103920;
}

.border-t-green-600 {
  border-top-color: #103920;
}

.border-b-green-600 {
  border-bottom-color: #103920;
}

.border-green-700 {
  border-color: #05110a;
}

.border-l-green-700 {
  border-left-color: #05110a;
}

.border-r-green-700 {
  border-right-color: #05110a;
}

.border-t-green-700 {
  border-top-color: #05110a;
}

.border-b-green-700 {
  border-bottom-color: #05110a;
}

.border-green-800 {
  border-color: black;
}

.border-l-green-800 {
  border-left-color: black;
}

.border-r-green-800 {
  border-right-color: black;
}

.border-t-green-800 {
  border-top-color: black;
}

.border-b-green-800 {
  border-bottom-color: black;
}

.border-green-900 {
  border-color: black;
}

.border-l-green-900 {
  border-left-color: black;
}

.border-r-green-900 {
  border-right-color: black;
}

.border-t-green-900 {
  border-top-color: black;
}

.border-b-green-900 {
  border-bottom-color: black;
}

.border-red {
  border-color: #e94966;
}

.border-l-red {
  border-left-color: #e94966;
}

.border-r-red {
  border-right-color: #e94966;
}

.border-t-red {
  border-top-color: #e94966;
}

.border-b-red {
  border-bottom-color: #e94966;
}

.border-red-500 {
  border-color: #e94966;
}

.border-l-red-500 {
  border-left-color: #e94966;
}

.border-r-red-500 {
  border-right-color: #e94966;
}

.border-t-red-500 {
  border-top-color: #e94966;
}

.border-b-red-500 {
  border-bottom-color: #e94966;
}

.border-red-50 {
  border-color: white;
}

.border-l-red-50 {
  border-left-color: white;
}

.border-r-red-50 {
  border-right-color: white;
}

.border-t-red-50 {
  border-top-color: white;
}

.border-b-red-50 {
  border-bottom-color: white;
}

.border-red-70 {
  border-color: white;
}

.border-l-red-70 {
  border-left-color: white;
}

.border-r-red-70 {
  border-right-color: white;
}

.border-t-red-70 {
  border-top-color: white;
}

.border-b-red-70 {
  border-bottom-color: white;
}

.border-red-80 {
  border-color: white;
}

.border-l-red-80 {
  border-left-color: white;
}

.border-r-red-80 {
  border-right-color: white;
}

.border-t-red-80 {
  border-top-color: white;
}

.border-b-red-80 {
  border-bottom-color: white;
}

.border-red-100 {
  border-color: white;
}

.border-l-red-100 {
  border-left-color: white;
}

.border-r-red-100 {
  border-right-color: white;
}

.border-t-red-100 {
  border-top-color: white;
}

.border-b-red-100 {
  border-bottom-color: white;
}

.border-red-200 {
  border-color: #fad2d9;
}

.border-l-red-200 {
  border-left-color: #fad2d9;
}

.border-r-red-200 {
  border-right-color: #fad2d9;
}

.border-t-red-200 {
  border-top-color: #fad2d9;
}

.border-b-red-200 {
  border-bottom-color: #fad2d9;
}

.border-red-300 {
  border-color: #f4a4b3;
}

.border-l-red-300 {
  border-left-color: #f4a4b3;
}

.border-r-red-300 {
  border-right-color: #f4a4b3;
}

.border-t-red-300 {
  border-top-color: #f4a4b3;
}

.border-b-red-300 {
  border-bottom-color: #f4a4b3;
}

.border-red-400 {
  border-color: #ef778c;
}

.border-l-red-400 {
  border-left-color: #ef778c;
}

.border-r-red-400 {
  border-right-color: #ef778c;
}

.border-t-red-400 {
  border-top-color: #ef778c;
}

.border-b-red-400 {
  border-bottom-color: #ef778c;
}

.border-red-600 {
  border-color: #e41c40;
}

.border-l-red-600 {
  border-left-color: #e41c40;
}

.border-r-red-600 {
  border-right-color: #e41c40;
}

.border-t-red-600 {
  border-top-color: #e41c40;
}

.border-b-red-600 {
  border-bottom-color: #e41c40;
}

.border-red-700 {
  border-color: #b61633;
}

.border-l-red-700 {
  border-left-color: #b61633;
}

.border-r-red-700 {
  border-right-color: #b61633;
}

.border-t-red-700 {
  border-top-color: #b61633;
}

.border-b-red-700 {
  border-bottom-color: #b61633;
}

.border-red-800 {
  border-color: #891126;
}

.border-l-red-800 {
  border-left-color: #891126;
}

.border-r-red-800 {
  border-right-color: #891126;
}

.border-t-red-800 {
  border-top-color: #891126;
}

.border-b-red-800 {
  border-bottom-color: #891126;
}

.border-red-900 {
  border-color: #5b0b1a;
}

.border-l-red-900 {
  border-left-color: #5b0b1a;
}

.border-r-red-900 {
  border-right-color: #5b0b1a;
}

.border-t-red-900 {
  border-top-color: #5b0b1a;
}

.border-b-red-900 {
  border-bottom-color: #5b0b1a;
}

.border-gray {
  border-color: #808080;
}

.border-l-gray {
  border-left-color: #808080;
}

.border-r-gray {
  border-right-color: #808080;
}

.border-t-gray {
  border-top-color: #808080;
}

.border-b-gray {
  border-bottom-color: #808080;
}

.border-gray-500 {
  border-color: #808080;
}

.border-l-gray-500 {
  border-left-color: #808080;
}

.border-r-gray-500 {
  border-right-color: #808080;
}

.border-t-gray-500 {
  border-top-color: #808080;
}

.border-b-gray-500 {
  border-bottom-color: #808080;
}

.border-gray-50 {
  border-color: #f3f3f3;
}

.border-l-gray-50 {
  border-left-color: #f3f3f3;
}

.border-r-gray-50 {
  border-right-color: #f3f3f3;
}

.border-t-gray-50 {
  border-top-color: #f3f3f3;
}

.border-b-gray-50 {
  border-bottom-color: #f3f3f3;
}

.border-gray-70 {
  border-color: #eeeeee;
}

.border-l-gray-70 {
  border-left-color: #eeeeee;
}

.border-r-gray-70 {
  border-right-color: #eeeeee;
}

.border-t-gray-70 {
  border-top-color: #eeeeee;
}

.border-b-gray-70 {
  border-bottom-color: #eeeeee;
}

.border-gray-80 {
  border-color: #ebebeb;
}

.border-l-gray-80 {
  border-left-color: #ebebeb;
}

.border-r-gray-80 {
  border-right-color: #ebebeb;
}

.border-t-gray-80 {
  border-top-color: #ebebeb;
}

.border-b-gray-80 {
  border-bottom-color: #ebebeb;
}

.border-gray-100 {
  border-color: #e6e6e6;
}

.border-l-gray-100 {
  border-left-color: #e6e6e6;
}

.border-r-gray-100 {
  border-right-color: #e6e6e6;
}

.border-t-gray-100 {
  border-top-color: #e6e6e6;
}

.border-b-gray-100 {
  border-bottom-color: #e6e6e6;
}

.border-gray-200 {
  border-color: #cdcdcd;
}

.border-l-gray-200 {
  border-left-color: #cdcdcd;
}

.border-r-gray-200 {
  border-right-color: #cdcdcd;
}

.border-t-gray-200 {
  border-top-color: #cdcdcd;
}

.border-b-gray-200 {
  border-bottom-color: #cdcdcd;
}

.border-gray-300 {
  border-color: #b3b3b3;
}

.border-l-gray-300 {
  border-left-color: #b3b3b3;
}

.border-r-gray-300 {
  border-right-color: #b3b3b3;
}

.border-t-gray-300 {
  border-top-color: #b3b3b3;
}

.border-b-gray-300 {
  border-bottom-color: #b3b3b3;
}

.border-gray-400 {
  border-color: #9a9a9a;
}

.border-l-gray-400 {
  border-left-color: #9a9a9a;
}

.border-r-gray-400 {
  border-right-color: #9a9a9a;
}

.border-t-gray-400 {
  border-top-color: #9a9a9a;
}

.border-b-gray-400 {
  border-bottom-color: #9a9a9a;
}

.border-gray-600 {
  border-color: #676767;
}

.border-l-gray-600 {
  border-left-color: #676767;
}

.border-r-gray-600 {
  border-right-color: #676767;
}

.border-t-gray-600 {
  border-top-color: #676767;
}

.border-b-gray-600 {
  border-bottom-color: #676767;
}

.border-gray-700 {
  border-color: #4d4d4d;
}

.border-l-gray-700 {
  border-left-color: #4d4d4d;
}

.border-r-gray-700 {
  border-right-color: #4d4d4d;
}

.border-t-gray-700 {
  border-top-color: #4d4d4d;
}

.border-b-gray-700 {
  border-bottom-color: #4d4d4d;
}

.border-gray-800 {
  border-color: #343434;
}

.border-l-gray-800 {
  border-left-color: #343434;
}

.border-r-gray-800 {
  border-right-color: #343434;
}

.border-t-gray-800 {
  border-top-color: #343434;
}

.border-b-gray-800 {
  border-bottom-color: #343434;
}

.border-gray-900 {
  border-color: #1a1a1a;
}

.border-l-gray-900 {
  border-left-color: #1a1a1a;
}

.border-r-gray-900 {
  border-right-color: #1a1a1a;
}

.border-t-gray-900 {
  border-top-color: #1a1a1a;
}

.border-b-gray-900 {
  border-bottom-color: #1a1a1a;
}

.border-orange {
  border-color: #e9a85d;
}

.border-l-orange {
  border-left-color: #e9a85d;
}

.border-r-orange {
  border-right-color: #e9a85d;
}

.border-t-orange {
  border-top-color: #e9a85d;
}

.border-b-orange {
  border-bottom-color: #e9a85d;
}

.border-orange-500 {
  border-color: #e9a85d;
}

.border-l-orange-500 {
  border-left-color: #e9a85d;
}

.border-r-orange-500 {
  border-right-color: #e9a85d;
}

.border-t-orange-500 {
  border-top-color: #e9a85d;
}

.border-b-orange-500 {
  border-bottom-color: #e9a85d;
}

.border-orange-50 {
  border-color: white;
}

.border-l-orange-50 {
  border-left-color: white;
}

.border-r-orange-50 {
  border-right-color: white;
}

.border-t-orange-50 {
  border-top-color: white;
}

.border-b-orange-50 {
  border-bottom-color: white;
}

.border-orange-70 {
  border-color: white;
}

.border-l-orange-70 {
  border-left-color: white;
}

.border-r-orange-70 {
  border-right-color: white;
}

.border-t-orange-70 {
  border-top-color: white;
}

.border-b-orange-70 {
  border-bottom-color: white;
}

.border-orange-80 {
  border-color: white;
}

.border-l-orange-80 {
  border-left-color: white;
}

.border-r-orange-80 {
  border-right-color: white;
}

.border-t-orange-80 {
  border-top-color: white;
}

.border-b-orange-80 {
  border-bottom-color: white;
}

.border-orange-100 {
  border-color: white;
}

.border-l-orange-100 {
  border-left-color: white;
}

.border-r-orange-100 {
  border-right-color: white;
}

.border-t-orange-100 {
  border-top-color: white;
}

.border-b-orange-100 {
  border-bottom-color: white;
}

.border-orange-200 {
  border-color: #fbf0e4;
}

.border-l-orange-200 {
  border-left-color: #fbf0e4;
}

.border-r-orange-200 {
  border-right-color: #fbf0e4;
}

.border-t-orange-200 {
  border-top-color: #fbf0e4;
}

.border-b-orange-200 {
  border-bottom-color: #fbf0e4;
}

.border-orange-300 {
  border-color: #f5d8b7;
}

.border-l-orange-300 {
  border-left-color: #f5d8b7;
}

.border-r-orange-300 {
  border-right-color: #f5d8b7;
}

.border-t-orange-300 {
  border-top-color: #f5d8b7;
}

.border-b-orange-300 {
  border-bottom-color: #f5d8b7;
}

.border-orange-400 {
  border-color: #efc08a;
}

.border-l-orange-400 {
  border-left-color: #efc08a;
}

.border-r-orange-400 {
  border-right-color: #efc08a;
}

.border-t-orange-400 {
  border-top-color: #efc08a;
}

.border-b-orange-400 {
  border-bottom-color: #efc08a;
}

.border-orange-600 {
  border-color: #e39030;
}

.border-l-orange-600 {
  border-left-color: #e39030;
}

.border-r-orange-600 {
  border-right-color: #e39030;
}

.border-t-orange-600 {
  border-top-color: #e39030;
}

.border-b-orange-600 {
  border-bottom-color: #e39030;
}

.border-orange-700 {
  border-color: #c5761b;
}

.border-l-orange-700 {
  border-left-color: #c5761b;
}

.border-r-orange-700 {
  border-right-color: #c5761b;
}

.border-t-orange-700 {
  border-top-color: #c5761b;
}

.border-b-orange-700 {
  border-bottom-color: #c5761b;
}

.border-orange-800 {
  border-color: #985b15;
}

.border-l-orange-800 {
  border-left-color: #985b15;
}

.border-r-orange-800 {
  border-right-color: #985b15;
}

.border-t-orange-800 {
  border-top-color: #985b15;
}

.border-b-orange-800 {
  border-bottom-color: #985b15;
}

.border-orange-900 {
  border-color: #6b400f;
}

.border-l-orange-900 {
  border-left-color: #6b400f;
}

.border-r-orange-900 {
  border-right-color: #6b400f;
}

.border-t-orange-900 {
  border-top-color: #6b400f;
}

.border-b-orange-900 {
  border-bottom-color: #6b400f;
}

.border-basic-1 {
  border-color: #344256;
}

.border-l-basic-1 {
  border-left-color: #344256;
}

.border-r-basic-1 {
  border-right-color: #344256;
}

.border-t-basic-1 {
  border-top-color: #344256;
}

.border-b-basic-1 {
  border-bottom-color: #344256;
}

.border-basic-1-500 {
  border-color: #344256;
}

.border-l-basic-1-500 {
  border-left-color: #344256;
}

.border-r-basic-1-500 {
  border-right-color: #344256;
}

.border-t-basic-1-500 {
  border-top-color: #344256;
}

.border-b-basic-1-500 {
  border-bottom-color: #344256;
}

.border-basic-1-50 {
  border-color: #a6b5c9;
}

.border-l-basic-1-50 {
  border-left-color: #a6b5c9;
}

.border-r-basic-1-50 {
  border-right-color: #a6b5c9;
}

.border-t-basic-1-50 {
  border-top-color: #a6b5c9;
}

.border-b-basic-1-50 {
  border-bottom-color: #a6b5c9;
}

.border-basic-1-70 {
  border-color: #a0afc5;
}

.border-l-basic-1-70 {
  border-left-color: #a0afc5;
}

.border-r-basic-1-70 {
  border-right-color: #a0afc5;
}

.border-t-basic-1-70 {
  border-top-color: #a0afc5;
}

.border-b-basic-1-70 {
  border-bottom-color: #a0afc5;
}

.border-basic-1-80 {
  border-color: #9dadc4;
}

.border-l-basic-1-80 {
  border-left-color: #9dadc4;
}

.border-r-basic-1-80 {
  border-right-color: #9dadc4;
}

.border-t-basic-1-80 {
  border-top-color: #9dadc4;
}

.border-b-basic-1-80 {
  border-bottom-color: #9dadc4;
}

.border-basic-1-100 {
  border-color: #96a7c0;
}

.border-l-basic-1-100 {
  border-left-color: #96a7c0;
}

.border-r-basic-1-100 {
  border-right-color: #96a7c0;
}

.border-t-basic-1-100 {
  border-top-color: #96a7c0;
}

.border-b-basic-1-100 {
  border-bottom-color: #96a7c0;
}

.border-basic-1-200 {
  border-color: #778dac;
}

.border-l-basic-1-200 {
  border-left-color: #778dac;
}

.border-r-basic-1-200 {
  border-right-color: #778dac;
}

.border-t-basic-1-200 {
  border-top-color: #778dac;
}

.border-b-basic-1-200 {
  border-bottom-color: #778dac;
}

.border-basic-1-300 {
  border-color: #5a7396;
}

.border-l-basic-1-300 {
  border-left-color: #5a7396;
}

.border-r-basic-1-300 {
  border-right-color: #5a7396;
}

.border-t-basic-1-300 {
  border-top-color: #5a7396;
}

.border-b-basic-1-300 {
  border-bottom-color: #5a7396;
}

.border-basic-1-400 {
  border-color: #475a76;
}

.border-l-basic-1-400 {
  border-left-color: #475a76;
}

.border-r-basic-1-400 {
  border-right-color: #475a76;
}

.border-t-basic-1-400 {
  border-top-color: #475a76;
}

.border-b-basic-1-400 {
  border-bottom-color: #475a76;
}

.border-basic-1-600 {
  border-color: #212a36;
}

.border-l-basic-1-600 {
  border-left-color: #212a36;
}

.border-r-basic-1-600 {
  border-right-color: #212a36;
}

.border-t-basic-1-600 {
  border-top-color: #212a36;
}

.border-b-basic-1-600 {
  border-bottom-color: #212a36;
}

.border-basic-1-700 {
  border-color: #0e1116;
}

.border-l-basic-1-700 {
  border-left-color: #0e1116;
}

.border-r-basic-1-700 {
  border-right-color: #0e1116;
}

.border-t-basic-1-700 {
  border-top-color: #0e1116;
}

.border-b-basic-1-700 {
  border-bottom-color: #0e1116;
}

.border-basic-1-800 {
  border-color: black;
}

.border-l-basic-1-800 {
  border-left-color: black;
}

.border-r-basic-1-800 {
  border-right-color: black;
}

.border-t-basic-1-800 {
  border-top-color: black;
}

.border-b-basic-1-800 {
  border-bottom-color: black;
}

.border-basic-1-900 {
  border-color: black;
}

.border-l-basic-1-900 {
  border-left-color: black;
}

.border-r-basic-1-900 {
  border-right-color: black;
}

.border-t-basic-1-900 {
  border-top-color: black;
}

.border-b-basic-1-900 {
  border-bottom-color: black;
}

.border-basic-2 {
  border-color: #32475a;
}

.border-l-basic-2 {
  border-left-color: #32475a;
}

.border-r-basic-2 {
  border-right-color: #32475a;
}

.border-t-basic-2 {
  border-top-color: #32475a;
}

.border-b-basic-2 {
  border-bottom-color: #32475a;
}

.border-basic-2-500 {
  border-color: #32475a;
}

.border-l-basic-2-500 {
  border-left-color: #32475a;
}

.border-r-basic-2-500 {
  border-right-color: #32475a;
}

.border-t-basic-2-500 {
  border-top-color: #32475a;
}

.border-b-basic-2-500 {
  border-bottom-color: #32475a;
}

.border-basic-2-50 {
  border-color: #a5bacd;
}

.border-l-basic-2-50 {
  border-left-color: #a5bacd;
}

.border-r-basic-2-50 {
  border-right-color: #a5bacd;
}

.border-t-basic-2-50 {
  border-top-color: #a5bacd;
}

.border-b-basic-2-50 {
  border-bottom-color: #a5bacd;
}

.border-basic-2-70 {
  border-color: #9eb5c9;
}

.border-l-basic-2-70 {
  border-left-color: #9eb5c9;
}

.border-r-basic-2-70 {
  border-right-color: #9eb5c9;
}

.border-t-basic-2-70 {
  border-top-color: #9eb5c9;
}

.border-b-basic-2-70 {
  border-bottom-color: #9eb5c9;
}

.border-basic-2-80 {
  border-color: #9bb2c7;
}

.border-l-basic-2-80 {
  border-left-color: #9bb2c7;
}

.border-r-basic-2-80 {
  border-right-color: #9bb2c7;
}

.border-t-basic-2-80 {
  border-top-color: #9bb2c7;
}

.border-b-basic-2-80 {
  border-bottom-color: #9bb2c7;
}

.border-basic-2-100 {
  border-color: #94adc4;
}

.border-l-basic-2-100 {
  border-left-color: #94adc4;
}

.border-r-basic-2-100 {
  border-right-color: #94adc4;
}

.border-t-basic-2-100 {
  border-top-color: #94adc4;
}

.border-b-basic-2-100 {
  border-bottom-color: #94adc4;
}

.border-basic-2-200 {
  border-color: #7494b2;
}

.border-l-basic-2-200 {
  border-left-color: #7494b2;
}

.border-r-basic-2-200 {
  border-right-color: #7494b2;
}

.border-t-basic-2-200 {
  border-top-color: #7494b2;
}

.border-b-basic-2-200 {
  border-bottom-color: #7494b2;
}

.border-basic-2-300 {
  border-color: #567b9c;
}

.border-l-basic-2-300 {
  border-left-color: #567b9c;
}

.border-r-basic-2-300 {
  border-right-color: #567b9c;
}

.border-t-basic-2-300 {
  border-top-color: #567b9c;
}

.border-b-basic-2-300 {
  border-bottom-color: #567b9c;
}

.border-basic-2-400, .select-container .select-btn,
.select-container .option {
  border-color: #44617b;
}

.border-l-basic-2-400 {
  border-left-color: #44617b;
}

.border-r-basic-2-400 {
  border-right-color: #44617b;
}

.border-t-basic-2-400 {
  border-top-color: #44617b;
}

.border-b-basic-2-400 {
  border-bottom-color: #44617b;
}

.border-basic-2-600 {
  border-color: #202d39;
}

.border-l-basic-2-600 {
  border-left-color: #202d39;
}

.border-r-basic-2-600 {
  border-right-color: #202d39;
}

.border-t-basic-2-600 {
  border-top-color: #202d39;
}

.border-b-basic-2-600 {
  border-bottom-color: #202d39;
}

.border-basic-2-700 {
  border-color: #0e1318;
}

.border-l-basic-2-700 {
  border-left-color: #0e1318;
}

.border-r-basic-2-700 {
  border-right-color: #0e1318;
}

.border-t-basic-2-700 {
  border-top-color: #0e1318;
}

.border-b-basic-2-700 {
  border-bottom-color: #0e1318;
}

.border-basic-2-800 {
  border-color: black;
}

.border-l-basic-2-800 {
  border-left-color: black;
}

.border-r-basic-2-800 {
  border-right-color: black;
}

.border-t-basic-2-800 {
  border-top-color: black;
}

.border-b-basic-2-800 {
  border-bottom-color: black;
}

.border-basic-2-900 {
  border-color: black;
}

.border-l-basic-2-900 {
  border-left-color: black;
}

.border-r-basic-2-900 {
  border-right-color: black;
}

.border-t-basic-2-900 {
  border-top-color: black;
}

.border-b-basic-2-900 {
  border-bottom-color: black;
}

.border-basic-3 {
  border-color: #6394c5;
}

.border-l-basic-3 {
  border-left-color: #6394c5;
}

.border-r-basic-3 {
  border-right-color: #6394c5;
}

.border-t-basic-3 {
  border-top-color: #6394c5;
}

.border-b-basic-3 {
  border-bottom-color: #6394c5;
}

.border-basic-3-500 {
  border-color: #6394c5;
}

.border-l-basic-3-500 {
  border-left-color: #6394c5;
}

.border-r-basic-3-500 {
  border-right-color: #6394c5;
}

.border-t-basic-3-500 {
  border-top-color: #6394c5;
}

.border-b-basic-3-500 {
  border-bottom-color: #6394c5;
}

.border-basic-3-50 {
  border-color: white;
}

.border-l-basic-3-50 {
  border-left-color: white;
}

.border-r-basic-3-50 {
  border-right-color: white;
}

.border-t-basic-3-50 {
  border-top-color: white;
}

.border-b-basic-3-50 {
  border-bottom-color: white;
}

.border-basic-3-70 {
  border-color: white;
}

.border-l-basic-3-70 {
  border-left-color: white;
}

.border-r-basic-3-70 {
  border-right-color: white;
}

.border-t-basic-3-70 {
  border-top-color: white;
}

.border-b-basic-3-70 {
  border-bottom-color: white;
}

.border-basic-3-80 {
  border-color: white;
}

.border-l-basic-3-80 {
  border-left-color: white;
}

.border-r-basic-3-80 {
  border-right-color: white;
}

.border-t-basic-3-80 {
  border-top-color: white;
}

.border-b-basic-3-80 {
  border-bottom-color: white;
}

.border-basic-3-100 {
  border-color: #f8fafc;
}

.border-l-basic-3-100 {
  border-left-color: #f8fafc;
}

.border-r-basic-3-100 {
  border-right-color: #f8fafc;
}

.border-t-basic-3-100 {
  border-top-color: #f8fafc;
}

.border-b-basic-3-100 {
  border-bottom-color: #f8fafc;
}

.border-basic-3-200 {
  border-color: #d3e1ee;
}

.border-l-basic-3-200 {
  border-left-color: #d3e1ee;
}

.border-r-basic-3-200 {
  border-right-color: #d3e1ee;
}

.border-t-basic-3-200 {
  border-top-color: #d3e1ee;
}

.border-b-basic-3-200 {
  border-bottom-color: #d3e1ee;
}

.border-basic-3-300 {
  border-color: #adc7e1;
}

.border-l-basic-3-300 {
  border-left-color: #adc7e1;
}

.border-r-basic-3-300 {
  border-right-color: #adc7e1;
}

.border-t-basic-3-300 {
  border-top-color: #adc7e1;
}

.border-b-basic-3-300 {
  border-bottom-color: #adc7e1;
}

.border-basic-3-400 {
  border-color: #88aed3;
}

.border-l-basic-3-400 {
  border-left-color: #88aed3;
}

.border-r-basic-3-400 {
  border-right-color: #88aed3;
}

.border-t-basic-3-400 {
  border-top-color: #88aed3;
}

.border-b-basic-3-400 {
  border-bottom-color: #88aed3;
}

.border-basic-3-600 {
  border-color: #427bb3;
}

.border-l-basic-3-600 {
  border-left-color: #427bb3;
}

.border-r-basic-3-600 {
  border-right-color: #427bb3;
}

.border-t-basic-3-600 {
  border-top-color: #427bb3;
}

.border-b-basic-3-600 {
  border-bottom-color: #427bb3;
}

.border-basic-3-700 {
  border-color: #35618d;
}

.border-l-basic-3-700 {
  border-left-color: #35618d;
}

.border-r-basic-3-700 {
  border-right-color: #35618d;
}

.border-t-basic-3-700 {
  border-top-color: #35618d;
}

.border-b-basic-3-700 {
  border-bottom-color: #35618d;
}

.border-basic-3-800 {
  border-color: #274868;
}

.border-l-basic-3-800 {
  border-left-color: #274868;
}

.border-r-basic-3-800 {
  border-right-color: #274868;
}

.border-t-basic-3-800 {
  border-top-color: #274868;
}

.border-b-basic-3-800 {
  border-bottom-color: #274868;
}

.border-basic-3-900 {
  border-color: #192e43;
}

.border-l-basic-3-900 {
  border-left-color: #192e43;
}

.border-r-basic-3-900 {
  border-right-color: #192e43;
}

.border-t-basic-3-900 {
  border-top-color: #192e43;
}

.border-b-basic-3-900 {
  border-bottom-color: #192e43;
}

.border-primary-1 {
  border-color: #007cba;
}

.border-l-primary-1 {
  border-left-color: #007cba;
}

.border-r-primary-1 {
  border-right-color: #007cba;
}

.border-t-primary-1 {
  border-top-color: #007cba;
}

.border-b-primary-1 {
  border-bottom-color: #007cba;
}

.border-primary-1-500 {
  border-color: #007cba;
}

.border-l-primary-1-500 {
  border-left-color: #007cba;
}

.border-r-primary-1-500 {
  border-right-color: #007cba;
}

.border-t-primary-1-500 {
  border-top-color: #007cba;
}

.border-b-primary-1-500 {
  border-bottom-color: #007cba;
}

.border-primary-1-50 {
  border-color: #a1e0ff;
}

.border-l-primary-1-50 {
  border-left-color: #a1e0ff;
}

.border-r-primary-1-50 {
  border-right-color: #a1e0ff;
}

.border-t-primary-1-50 {
  border-top-color: #a1e0ff;
}

.border-b-primary-1-50 {
  border-bottom-color: #a1e0ff;
}

.border-primary-1-70 {
  border-color: #96dcff;
}

.border-l-primary-1-70 {
  border-left-color: #96dcff;
}

.border-r-primary-1-70 {
  border-right-color: #96dcff;
}

.border-t-primary-1-70 {
  border-top-color: #96dcff;
}

.border-b-primary-1-70 {
  border-bottom-color: #96dcff;
}

.border-primary-1-80 {
  border-color: #91daff;
}

.border-l-primary-1-80 {
  border-left-color: #91daff;
}

.border-r-primary-1-80 {
  border-right-color: #91daff;
}

.border-t-primary-1-80 {
  border-top-color: #91daff;
}

.border-b-primary-1-80 {
  border-bottom-color: #91daff;
}

.border-primary-1-100 {
  border-color: #87d7ff;
}

.border-l-primary-1-100 {
  border-left-color: #87d7ff;
}

.border-r-primary-1-100 {
  border-right-color: #87d7ff;
}

.border-t-primary-1-100 {
  border-top-color: #87d7ff;
}

.border-b-primary-1-100 {
  border-bottom-color: #87d7ff;
}

.border-primary-1-200 {
  border-color: #54c6ff;
}

.border-l-primary-1-200 {
  border-left-color: #54c6ff;
}

.border-r-primary-1-200 {
  border-right-color: #54c6ff;
}

.border-t-primary-1-200 {
  border-top-color: #54c6ff;
}

.border-b-primary-1-200 {
  border-bottom-color: #54c6ff;
}

.border-primary-1-300 {
  border-color: #21b5ff;
}

.border-l-primary-1-300 {
  border-left-color: #21b5ff;
}

.border-r-primary-1-300 {
  border-right-color: #21b5ff;
}

.border-t-primary-1-300 {
  border-top-color: #21b5ff;
}

.border-b-primary-1-300 {
  border-bottom-color: #21b5ff;
}

.border-primary-1-400 {
  border-color: #009eed;
}

.border-l-primary-1-400 {
  border-left-color: #009eed;
}

.border-r-primary-1-400 {
  border-right-color: #009eed;
}

.border-t-primary-1-400 {
  border-top-color: #009eed;
}

.border-b-primary-1-400 {
  border-bottom-color: #009eed;
}

.border-primary-1-600 {
  border-color: #005a87;
}

.border-l-primary-1-600 {
  border-left-color: #005a87;
}

.border-r-primary-1-600 {
  border-right-color: #005a87;
}

.border-t-primary-1-600 {
  border-top-color: #005a87;
}

.border-b-primary-1-600 {
  border-bottom-color: #005a87;
}

.border-primary-1-700 {
  border-color: #003854;
}

.border-l-primary-1-700 {
  border-left-color: #003854;
}

.border-r-primary-1-700 {
  border-right-color: #003854;
}

.border-t-primary-1-700 {
  border-top-color: #003854;
}

.border-b-primary-1-700 {
  border-bottom-color: #003854;
}

.border-primary-1-800 {
  border-color: #001621;
}

.border-l-primary-1-800 {
  border-left-color: #001621;
}

.border-r-primary-1-800 {
  border-right-color: #001621;
}

.border-t-primary-1-800 {
  border-top-color: #001621;
}

.border-b-primary-1-800 {
  border-bottom-color: #001621;
}

.border-primary-1-900 {
  border-color: black;
}

.border-l-primary-1-900 {
  border-left-color: black;
}

.border-r-primary-1-900 {
  border-right-color: black;
}

.border-t-primary-1-900 {
  border-top-color: black;
}

.border-b-primary-1-900 {
  border-bottom-color: black;
}

.border-primary-2 {
  border-color: #7ec6ea;
}

.border-l-primary-2 {
  border-left-color: #7ec6ea;
}

.border-r-primary-2 {
  border-right-color: #7ec6ea;
}

.border-t-primary-2 {
  border-top-color: #7ec6ea;
}

.border-b-primary-2 {
  border-bottom-color: #7ec6ea;
}

.border-primary-2-500 {
  border-color: #7ec6ea;
}

.border-l-primary-2-500 {
  border-left-color: #7ec6ea;
}

.border-r-primary-2-500 {
  border-right-color: #7ec6ea;
}

.border-t-primary-2-500 {
  border-top-color: #7ec6ea;
}

.border-b-primary-2-500 {
  border-bottom-color: #7ec6ea;
}

.border-primary-2-50 {
  border-color: white;
}

.border-l-primary-2-50 {
  border-left-color: white;
}

.border-r-primary-2-50 {
  border-right-color: white;
}

.border-t-primary-2-50 {
  border-top-color: white;
}

.border-b-primary-2-50 {
  border-bottom-color: white;
}

.border-primary-2-70 {
  border-color: white;
}

.border-l-primary-2-70 {
  border-left-color: white;
}

.border-r-primary-2-70 {
  border-right-color: white;
}

.border-t-primary-2-70 {
  border-top-color: white;
}

.border-b-primary-2-70 {
  border-bottom-color: white;
}

.border-primary-2-80 {
  border-color: white;
}

.border-l-primary-2-80 {
  border-left-color: white;
}

.border-r-primary-2-80 {
  border-right-color: white;
}

.border-t-primary-2-80 {
  border-top-color: white;
}

.border-b-primary-2-80 {
  border-bottom-color: white;
}

.border-primary-2-100 {
  border-color: white;
}

.border-l-primary-2-100 {
  border-left-color: white;
}

.border-r-primary-2-100 {
  border-right-color: white;
}

.border-t-primary-2-100 {
  border-top-color: white;
}

.border-b-primary-2-100 {
  border-bottom-color: white;
}

.border-primary-2-200 {
  border-color: white;
}

.border-l-primary-2-200 {
  border-left-color: white;
}

.border-r-primary-2-200 {
  border-right-color: white;
}

.border-t-primary-2-200 {
  border-top-color: white;
}

.border-b-primary-2-200 {
  border-bottom-color: white;
}

.border-primary-2-300 {
  border-color: #d6edf8;
}

.border-l-primary-2-300 {
  border-left-color: #d6edf8;
}

.border-r-primary-2-300 {
  border-right-color: #d6edf8;
}

.border-t-primary-2-300 {
  border-top-color: #d6edf8;
}

.border-b-primary-2-300 {
  border-bottom-color: #d6edf8;
}

.border-primary-2-400 {
  border-color: #aad9f1;
}

.border-l-primary-2-400 {
  border-left-color: #aad9f1;
}

.border-r-primary-2-400 {
  border-right-color: #aad9f1;
}

.border-t-primary-2-400 {
  border-top-color: #aad9f1;
}

.border-b-primary-2-400 {
  border-bottom-color: #aad9f1;
}

.border-primary-2-600 {
  border-color: #52b3e3;
}

.border-l-primary-2-600 {
  border-left-color: #52b3e3;
}

.border-r-primary-2-600 {
  border-right-color: #52b3e3;
}

.border-t-primary-2-600 {
  border-top-color: #52b3e3;
}

.border-b-primary-2-600 {
  border-bottom-color: #52b3e3;
}

.border-primary-2-700 {
  border-color: #269fdc;
}

.border-l-primary-2-700 {
  border-left-color: #269fdc;
}

.border-r-primary-2-700 {
  border-right-color: #269fdc;
}

.border-t-primary-2-700 {
  border-top-color: #269fdc;
}

.border-b-primary-2-700 {
  border-bottom-color: #269fdc;
}

.border-primary-2-800 {
  border-color: #1d80b2;
}

.border-l-primary-2-800 {
  border-left-color: #1d80b2;
}

.border-r-primary-2-800 {
  border-right-color: #1d80b2;
}

.border-t-primary-2-800 {
  border-top-color: #1d80b2;
}

.border-b-primary-2-800 {
  border-bottom-color: #1d80b2;
}

.border-primary-2-900 {
  border-color: #166186;
}

.border-l-primary-2-900 {
  border-left-color: #166186;
}

.border-r-primary-2-900 {
  border-right-color: #166186;
}

.border-t-primary-2-900 {
  border-top-color: #166186;
}

.border-b-primary-2-900 {
  border-bottom-color: #166186;
}

.border-secondary-1 {
  border-color: #b9d6e4;
}

.border-l-secondary-1 {
  border-left-color: #b9d6e4;
}

.border-r-secondary-1 {
  border-right-color: #b9d6e4;
}

.border-t-secondary-1 {
  border-top-color: #b9d6e4;
}

.border-b-secondary-1 {
  border-bottom-color: #b9d6e4;
}

.border-secondary-1-500 {
  border-color: #b9d6e4;
}

.border-l-secondary-1-500 {
  border-left-color: #b9d6e4;
}

.border-r-secondary-1-500 {
  border-right-color: #b9d6e4;
}

.border-t-secondary-1-500 {
  border-top-color: #b9d6e4;
}

.border-b-secondary-1-500 {
  border-bottom-color: #b9d6e4;
}

.border-secondary-1-50 {
  border-color: white;
}

.border-l-secondary-1-50 {
  border-left-color: white;
}

.border-r-secondary-1-50 {
  border-right-color: white;
}

.border-t-secondary-1-50 {
  border-top-color: white;
}

.border-b-secondary-1-50 {
  border-bottom-color: white;
}

.border-secondary-1-70 {
  border-color: white;
}

.border-l-secondary-1-70 {
  border-left-color: white;
}

.border-r-secondary-1-70 {
  border-right-color: white;
}

.border-t-secondary-1-70 {
  border-top-color: white;
}

.border-b-secondary-1-70 {
  border-bottom-color: white;
}

.border-secondary-1-80 {
  border-color: white;
}

.border-l-secondary-1-80 {
  border-left-color: white;
}

.border-r-secondary-1-80 {
  border-right-color: white;
}

.border-t-secondary-1-80 {
  border-top-color: white;
}

.border-b-secondary-1-80 {
  border-bottom-color: white;
}

.border-secondary-1-100 {
  border-color: white;
}

.border-l-secondary-1-100 {
  border-left-color: white;
}

.border-r-secondary-1-100 {
  border-right-color: white;
}

.border-t-secondary-1-100 {
  border-top-color: white;
}

.border-b-secondary-1-100 {
  border-bottom-color: white;
}

.border-secondary-1-200 {
  border-color: white;
}

.border-l-secondary-1-200 {
  border-left-color: white;
}

.border-r-secondary-1-200 {
  border-right-color: white;
}

.border-t-secondary-1-200 {
  border-top-color: white;
}

.border-b-secondary-1-200 {
  border-bottom-color: white;
}

.border-secondary-1-300 {
  border-color: white;
}

.border-l-secondary-1-300 {
  border-left-color: white;
}

.border-r-secondary-1-300 {
  border-right-color: white;
}

.border-t-secondary-1-300 {
  border-top-color: white;
}

.border-b-secondary-1-300 {
  border-bottom-color: white;
}

.border-secondary-1-400 {
  border-color: #deecf2;
}

.border-l-secondary-1-400 {
  border-left-color: #deecf2;
}

.border-r-secondary-1-400 {
  border-right-color: #deecf2;
}

.border-t-secondary-1-400 {
  border-top-color: #deecf2;
}

.border-b-secondary-1-400 {
  border-bottom-color: #deecf2;
}

.border-secondary-1-600 {
  border-color: #94c0d6;
}

.border-l-secondary-1-600 {
  border-left-color: #94c0d6;
}

.border-r-secondary-1-600 {
  border-right-color: #94c0d6;
}

.border-t-secondary-1-600 {
  border-top-color: #94c0d6;
}

.border-b-secondary-1-600 {
  border-bottom-color: #94c0d6;
}

.border-secondary-1-700 {
  border-color: #6fabc8;
}

.border-l-secondary-1-700 {
  border-left-color: #6fabc8;
}

.border-r-secondary-1-700 {
  border-right-color: #6fabc8;
}

.border-t-secondary-1-700 {
  border-top-color: #6fabc8;
}

.border-b-secondary-1-700 {
  border-bottom-color: #6fabc8;
}

.border-secondary-1-800 {
  border-color: #4b95b9;
}

.border-l-secondary-1-800 {
  border-left-color: #4b95b9;
}

.border-r-secondary-1-800 {
  border-right-color: #4b95b9;
}

.border-t-secondary-1-800 {
  border-top-color: #4b95b9;
}

.border-b-secondary-1-800 {
  border-bottom-color: #4b95b9;
}

.border-secondary-1-900 {
  border-color: #3a7997;
}

.border-l-secondary-1-900 {
  border-left-color: #3a7997;
}

.border-r-secondary-1-900 {
  border-right-color: #3a7997;
}

.border-t-secondary-1-900 {
  border-top-color: #3a7997;
}

.border-b-secondary-1-900 {
  border-bottom-color: #3a7997;
}

.border-secondary-2 {
  border-color: #84bfdd;
}

.border-l-secondary-2 {
  border-left-color: #84bfdd;
}

.border-r-secondary-2 {
  border-right-color: #84bfdd;
}

.border-t-secondary-2 {
  border-top-color: #84bfdd;
}

.border-b-secondary-2 {
  border-bottom-color: #84bfdd;
}

.border-secondary-2-500 {
  border-color: #84bfdd;
}

.border-l-secondary-2-500 {
  border-left-color: #84bfdd;
}

.border-r-secondary-2-500 {
  border-right-color: #84bfdd;
}

.border-t-secondary-2-500 {
  border-top-color: #84bfdd;
}

.border-b-secondary-2-500 {
  border-bottom-color: #84bfdd;
}

.border-secondary-2-50 {
  border-color: white;
}

.border-l-secondary-2-50 {
  border-left-color: white;
}

.border-r-secondary-2-50 {
  border-right-color: white;
}

.border-t-secondary-2-50 {
  border-top-color: white;
}

.border-b-secondary-2-50 {
  border-bottom-color: white;
}

.border-secondary-2-70 {
  border-color: white;
}

.border-l-secondary-2-70 {
  border-left-color: white;
}

.border-r-secondary-2-70 {
  border-right-color: white;
}

.border-t-secondary-2-70 {
  border-top-color: white;
}

.border-b-secondary-2-70 {
  border-bottom-color: white;
}

.border-secondary-2-80 {
  border-color: white;
}

.border-l-secondary-2-80 {
  border-left-color: white;
}

.border-r-secondary-2-80 {
  border-right-color: white;
}

.border-t-secondary-2-80 {
  border-top-color: white;
}

.border-b-secondary-2-80 {
  border-bottom-color: white;
}

.border-secondary-2-100 {
  border-color: white;
}

.border-l-secondary-2-100 {
  border-left-color: white;
}

.border-r-secondary-2-100 {
  border-right-color: white;
}

.border-t-secondary-2-100 {
  border-top-color: white;
}

.border-b-secondary-2-100 {
  border-bottom-color: white;
}

.border-secondary-2-200 {
  border-color: #fcfdfe;
}

.border-l-secondary-2-200 {
  border-left-color: #fcfdfe;
}

.border-r-secondary-2-200 {
  border-right-color: #fcfdfe;
}

.border-t-secondary-2-200 {
  border-top-color: #fcfdfe;
}

.border-b-secondary-2-200 {
  border-bottom-color: #fcfdfe;
}

.border-secondary-2-300 {
  border-color: #d4e9f3;
}

.border-l-secondary-2-300 {
  border-left-color: #d4e9f3;
}

.border-r-secondary-2-300 {
  border-right-color: #d4e9f3;
}

.border-t-secondary-2-300 {
  border-top-color: #d4e9f3;
}

.border-b-secondary-2-300 {
  border-bottom-color: #d4e9f3;
}

.border-secondary-2-400 {
  border-color: #acd4e8;
}

.border-l-secondary-2-400 {
  border-left-color: #acd4e8;
}

.border-r-secondary-2-400 {
  border-right-color: #acd4e8;
}

.border-t-secondary-2-400 {
  border-top-color: #acd4e8;
}

.border-b-secondary-2-400 {
  border-bottom-color: #acd4e8;
}

.border-secondary-2-600 {
  border-color: #5caad2;
}

.border-l-secondary-2-600 {
  border-left-color: #5caad2;
}

.border-r-secondary-2-600 {
  border-right-color: #5caad2;
}

.border-t-secondary-2-600 {
  border-top-color: #5caad2;
}

.border-b-secondary-2-600 {
  border-bottom-color: #5caad2;
}

.border-secondary-2-700 {
  border-color: #3695c5;
}

.border-l-secondary-2-700 {
  border-left-color: #3695c5;
}

.border-r-secondary-2-700 {
  border-right-color: #3695c5;
}

.border-t-secondary-2-700 {
  border-top-color: #3695c5;
}

.border-b-secondary-2-700 {
  border-bottom-color: #3695c5;
}

.border-secondary-2-800 {
  border-color: #2b769d;
}

.border-l-secondary-2-800 {
  border-left-color: #2b769d;
}

.border-r-secondary-2-800 {
  border-right-color: #2b769d;
}

.border-t-secondary-2-800 {
  border-top-color: #2b769d;
}

.border-b-secondary-2-800 {
  border-bottom-color: #2b769d;
}

.border-secondary-2-900 {
  border-color: #205875;
}

.border-l-secondary-2-900 {
  border-left-color: #205875;
}

.border-r-secondary-2-900 {
  border-right-color: #205875;
}

.border-t-secondary-2-900 {
  border-top-color: #205875;
}

.border-b-secondary-2-900 {
  border-bottom-color: #205875;
}

.border-secondary-3 {
  border-color: #e7f4ff;
}

.border-l-secondary-3 {
  border-left-color: #e7f4ff;
}

.border-r-secondary-3 {
  border-right-color: #e7f4ff;
}

.border-t-secondary-3 {
  border-top-color: #e7f4ff;
}

.border-b-secondary-3 {
  border-bottom-color: #e7f4ff;
}

.border-secondary-3-500 {
  border-color: #e7f4ff;
}

.border-l-secondary-3-500 {
  border-left-color: #e7f4ff;
}

.border-r-secondary-3-500 {
  border-right-color: #e7f4ff;
}

.border-t-secondary-3-500 {
  border-top-color: #e7f4ff;
}

.border-b-secondary-3-500 {
  border-bottom-color: #e7f4ff;
}

.border-secondary-3-50 {
  border-color: white;
}

.border-l-secondary-3-50 {
  border-left-color: white;
}

.border-r-secondary-3-50 {
  border-right-color: white;
}

.border-t-secondary-3-50 {
  border-top-color: white;
}

.border-b-secondary-3-50 {
  border-bottom-color: white;
}

.border-secondary-3-70 {
  border-color: white;
}

.border-l-secondary-3-70 {
  border-left-color: white;
}

.border-r-secondary-3-70 {
  border-right-color: white;
}

.border-t-secondary-3-70 {
  border-top-color: white;
}

.border-b-secondary-3-70 {
  border-bottom-color: white;
}

.border-secondary-3-80 {
  border-color: white;
}

.border-l-secondary-3-80 {
  border-left-color: white;
}

.border-r-secondary-3-80 {
  border-right-color: white;
}

.border-t-secondary-3-80 {
  border-top-color: white;
}

.border-b-secondary-3-80 {
  border-bottom-color: white;
}

.border-secondary-3-100 {
  border-color: white;
}

.border-l-secondary-3-100 {
  border-left-color: white;
}

.border-r-secondary-3-100 {
  border-right-color: white;
}

.border-t-secondary-3-100 {
  border-top-color: white;
}

.border-b-secondary-3-100 {
  border-bottom-color: white;
}

.border-secondary-3-200 {
  border-color: white;
}

.border-l-secondary-3-200 {
  border-left-color: white;
}

.border-r-secondary-3-200 {
  border-right-color: white;
}

.border-t-secondary-3-200 {
  border-top-color: white;
}

.border-b-secondary-3-200 {
  border-bottom-color: white;
}

.border-secondary-3-300 {
  border-color: white;
}

.border-l-secondary-3-300 {
  border-left-color: white;
}

.border-r-secondary-3-300 {
  border-right-color: white;
}

.border-t-secondary-3-300 {
  border-top-color: white;
}

.border-b-secondary-3-300 {
  border-bottom-color: white;
}

.border-secondary-3-400 {
  border-color: white;
}

.border-l-secondary-3-400 {
  border-left-color: white;
}

.border-r-secondary-3-400 {
  border-right-color: white;
}

.border-t-secondary-3-400 {
  border-top-color: white;
}

.border-b-secondary-3-400 {
  border-bottom-color: white;
}

.border-secondary-3-600 {
  border-color: #b4ddff;
}

.border-l-secondary-3-600 {
  border-left-color: #b4ddff;
}

.border-r-secondary-3-600 {
  border-right-color: #b4ddff;
}

.border-t-secondary-3-600 {
  border-top-color: #b4ddff;
}

.border-b-secondary-3-600 {
  border-bottom-color: #b4ddff;
}

.border-secondary-3-700 {
  border-color: #81c5ff;
}

.border-l-secondary-3-700 {
  border-left-color: #81c5ff;
}

.border-r-secondary-3-700 {
  border-right-color: #81c5ff;
}

.border-t-secondary-3-700 {
  border-top-color: #81c5ff;
}

.border-b-secondary-3-700 {
  border-bottom-color: #81c5ff;
}

.border-secondary-3-800 {
  border-color: #4eaeff;
}

.border-l-secondary-3-800 {
  border-left-color: #4eaeff;
}

.border-r-secondary-3-800 {
  border-right-color: #4eaeff;
}

.border-t-secondary-3-800 {
  border-top-color: #4eaeff;
}

.border-b-secondary-3-800 {
  border-bottom-color: #4eaeff;
}

.border-secondary-3-900 {
  border-color: #1b97ff;
}

.border-l-secondary-3-900 {
  border-left-color: #1b97ff;
}

.border-r-secondary-3-900 {
  border-right-color: #1b97ff;
}

.border-t-secondary-3-900 {
  border-top-color: #1b97ff;
}

.border-b-secondary-3-900 {
  border-bottom-color: #1b97ff;
}

.border-background {
  border-color: #5d89b5;
}

.border-l-background {
  border-left-color: #5d89b5;
}

.border-r-background {
  border-right-color: #5d89b5;
}

.border-t-background {
  border-top-color: #5d89b5;
}

.border-b-background {
  border-bottom-color: #5d89b5;
}

.border-background-500 {
  border-color: #5d89b5;
}

.border-l-background-500 {
  border-left-color: #5d89b5;
}

.border-r-background-500 {
  border-right-color: #5d89b5;
}

.border-t-background-500 {
  border-top-color: #5d89b5;
}

.border-b-background-500 {
  border-bottom-color: #5d89b5;
}

.border-background-50 {
  border-color: #fbfcfd;
}

.border-l-background-50 {
  border-left-color: #fbfcfd;
}

.border-r-background-50 {
  border-right-color: #fbfcfd;
}

.border-t-background-50 {
  border-top-color: #fbfcfd;
}

.border-b-background-50 {
  border-bottom-color: #fbfcfd;
}

.border-background-70 {
  border-color: #f4f7fa;
}

.border-l-background-70 {
  border-left-color: #f4f7fa;
}

.border-r-background-70 {
  border-right-color: #f4f7fa;
}

.border-t-background-70 {
  border-top-color: #f4f7fa;
}

.border-b-background-70 {
  border-bottom-color: #f4f7fa;
}

.border-background-80 {
  border-color: #f0f4f8;
}

.border-l-background-80 {
  border-left-color: #f0f4f8;
}

.border-r-background-80 {
  border-right-color: #f0f4f8;
}

.border-t-background-80 {
  border-top-color: #f0f4f8;
}

.border-b-background-80 {
  border-bottom-color: #f0f4f8;
}

.border-background-100 {
  border-color: #e9eff5;
}

.border-l-background-100 {
  border-left-color: #e9eff5;
}

.border-r-background-100 {
  border-right-color: #e9eff5;
}

.border-t-background-100 {
  border-top-color: #e9eff5;
}

.border-b-background-100 {
  border-bottom-color: #e9eff5;
}

.border-background-200 {
  border-color: #c6d6e5;
}

.border-l-background-200 {
  border-left-color: #c6d6e5;
}

.border-r-background-200 {
  border-right-color: #c6d6e5;
}

.border-t-background-200 {
  border-top-color: #c6d6e5;
}

.border-b-background-200 {
  border-bottom-color: #c6d6e5;
}

.border-background-300 {
  border-color: #a3bcd5;
}

.border-l-background-300 {
  border-left-color: #a3bcd5;
}

.border-r-background-300 {
  border-right-color: #a3bcd5;
}

.border-t-background-300 {
  border-top-color: #a3bcd5;
}

.border-b-background-300 {
  border-bottom-color: #a3bcd5;
}

.border-background-400 {
  border-color: #80a3c5;
}

.border-l-background-400 {
  border-left-color: #80a3c5;
}

.border-r-background-400 {
  border-right-color: #80a3c5;
}

.border-t-background-400 {
  border-top-color: #80a3c5;
}

.border-b-background-400 {
  border-bottom-color: #80a3c5;
}

.border-background-600 {
  border-color: #467099;
}

.border-l-background-600 {
  border-left-color: #467099;
}

.border-r-background-600 {
  border-right-color: #467099;
}

.border-t-background-600 {
  border-top-color: #467099;
}

.border-b-background-600 {
  border-bottom-color: #467099;
}

.border-background-700 {
  border-color: #365676;
}

.border-l-background-700 {
  border-left-color: #365676;
}

.border-r-background-700 {
  border-right-color: #365676;
}

.border-t-background-700 {
  border-top-color: #365676;
}

.border-b-background-700 {
  border-bottom-color: #365676;
}

.border-background-800 {
  border-color: #263d53;
}

.border-l-background-800 {
  border-left-color: #263d53;
}

.border-r-background-800 {
  border-right-color: #263d53;
}

.border-t-background-800 {
  border-top-color: #263d53;
}

.border-b-background-800 {
  border-bottom-color: #263d53;
}

.border-background-900 {
  border-color: #162330;
}

.border-l-background-900 {
  border-left-color: #162330;
}

.border-r-background-900 {
  border-right-color: #162330;
}

.border-t-background-900 {
  border-top-color: #162330;
}

.border-b-background-900 {
  border-bottom-color: #162330;
}

.border-background-1 {
  border-color: #f4f6f8;
}

.border-l-background-1 {
  border-left-color: #f4f6f8;
}

.border-r-background-1 {
  border-right-color: #f4f6f8;
}

.border-t-background-1 {
  border-top-color: #f4f6f8;
}

.border-b-background-1 {
  border-bottom-color: #f4f6f8;
}

.border-background-1-500 {
  border-color: #f4f6f8;
}

.border-l-background-1-500 {
  border-left-color: #f4f6f8;
}

.border-r-background-1-500 {
  border-right-color: #f4f6f8;
}

.border-t-background-1-500 {
  border-top-color: #f4f6f8;
}

.border-b-background-1-500 {
  border-bottom-color: #f4f6f8;
}

.border-background-1-50 {
  border-color: white;
}

.border-l-background-1-50 {
  border-left-color: white;
}

.border-r-background-1-50 {
  border-right-color: white;
}

.border-t-background-1-50 {
  border-top-color: white;
}

.border-b-background-1-50 {
  border-bottom-color: white;
}

.border-background-1-70 {
  border-color: white;
}

.border-l-background-1-70 {
  border-left-color: white;
}

.border-r-background-1-70 {
  border-right-color: white;
}

.border-t-background-1-70 {
  border-top-color: white;
}

.border-b-background-1-70 {
  border-bottom-color: white;
}

.border-background-1-80 {
  border-color: white;
}

.border-l-background-1-80 {
  border-left-color: white;
}

.border-r-background-1-80 {
  border-right-color: white;
}

.border-t-background-1-80 {
  border-top-color: white;
}

.border-b-background-1-80 {
  border-bottom-color: white;
}

.border-background-1-100 {
  border-color: white;
}

.border-l-background-1-100 {
  border-left-color: white;
}

.border-r-background-1-100 {
  border-right-color: white;
}

.border-t-background-1-100 {
  border-top-color: white;
}

.border-b-background-1-100 {
  border-bottom-color: white;
}

.border-background-1-200 {
  border-color: white;
}

.border-l-background-1-200 {
  border-left-color: white;
}

.border-r-background-1-200 {
  border-right-color: white;
}

.border-t-background-1-200 {
  border-top-color: white;
}

.border-b-background-1-200 {
  border-bottom-color: white;
}

.border-background-1-300 {
  border-color: white;
}

.border-l-background-1-300 {
  border-left-color: white;
}

.border-r-background-1-300 {
  border-right-color: white;
}

.border-t-background-1-300 {
  border-top-color: white;
}

.border-b-background-1-300 {
  border-bottom-color: white;
}

.border-background-1-400 {
  border-color: white;
}

.border-l-background-1-400 {
  border-left-color: white;
}

.border-r-background-1-400 {
  border-right-color: white;
}

.border-t-background-1-400 {
  border-top-color: white;
}

.border-b-background-1-400 {
  border-bottom-color: white;
}

.border-background-1-600 {
  border-color: #d5dde4;
}

.border-l-background-1-600 {
  border-left-color: #d5dde4;
}

.border-r-background-1-600 {
  border-right-color: #d5dde4;
}

.border-t-background-1-600 {
  border-top-color: #d5dde4;
}

.border-b-background-1-600 {
  border-bottom-color: #d5dde4;
}

.border-background-1-700 {
  border-color: #b6c3d0;
}

.border-l-background-1-700 {
  border-left-color: #b6c3d0;
}

.border-r-background-1-700 {
  border-right-color: #b6c3d0;
}

.border-t-background-1-700 {
  border-top-color: #b6c3d0;
}

.border-b-background-1-700 {
  border-bottom-color: #b6c3d0;
}

.border-background-1-800 {
  border-color: #97aabd;
}

.border-l-background-1-800 {
  border-left-color: #97aabd;
}

.border-r-background-1-800 {
  border-right-color: #97aabd;
}

.border-t-background-1-800 {
  border-top-color: #97aabd;
}

.border-b-background-1-800 {
  border-bottom-color: #97aabd;
}

.border-background-1-900 {
  border-color: #7790a9;
}

.border-l-background-1-900 {
  border-left-color: #7790a9;
}

.border-r-background-1-900 {
  border-right-color: #7790a9;
}

.border-t-background-1-900 {
  border-top-color: #7790a9;
}

.border-b-background-1-900 {
  border-bottom-color: #7790a9;
}

.border-success {
  border-color: #2c9d82;
}

.border-l-success {
  border-left-color: #2c9d82;
}

.border-r-success {
  border-right-color: #2c9d82;
}

.border-t-success {
  border-top-color: #2c9d82;
}

.border-b-success {
  border-bottom-color: #2c9d82;
}

.border-success-500 {
  border-color: #2c9d82;
}

.border-l-success-500 {
  border-left-color: #2c9d82;
}

.border-r-success-500 {
  border-right-color: #2c9d82;
}

.border-t-success-500 {
  border-top-color: #2c9d82;
}

.border-b-success-500 {
  border-bottom-color: #2c9d82;
}

.border-success-50 {
  border-color: #c1eee3;
}

.border-l-success-50 {
  border-left-color: #c1eee3;
}

.border-r-success-50 {
  border-right-color: #c1eee3;
}

.border-t-success-50 {
  border-top-color: #c1eee3;
}

.border-b-success-50 {
  border-bottom-color: #c1eee3;
}

.border-success-70 {
  border-color: #b9ebdf;
}

.border-l-success-70 {
  border-left-color: #b9ebdf;
}

.border-r-success-70 {
  border-right-color: #b9ebdf;
}

.border-t-success-70 {
  border-top-color: #b9ebdf;
}

.border-b-success-70 {
  border-bottom-color: #b9ebdf;
}

.border-success-80 {
  border-color: #b5eade;
}

.border-l-success-80 {
  border-left-color: #b5eade;
}

.border-r-success-80 {
  border-right-color: #b5eade;
}

.border-t-success-80 {
  border-top-color: #b5eade;
}

.border-b-success-80 {
  border-bottom-color: #b5eade;
}

.border-success-100 {
  border-color: #ade8da;
}

.border-l-success-100 {
  border-left-color: #ade8da;
}

.border-r-success-100 {
  border-right-color: #ade8da;
}

.border-t-success-100 {
  border-top-color: #ade8da;
}

.border-b-success-100 {
  border-bottom-color: #ade8da;
}

.border-success-200 {
  border-color: #85ddc8;
}

.border-l-success-200 {
  border-left-color: #85ddc8;
}

.border-r-success-200 {
  border-right-color: #85ddc8;
}

.border-t-success-200 {
  border-top-color: #85ddc8;
}

.border-b-success-200 {
  border-bottom-color: #85ddc8;
}

.border-success-300 {
  border-color: #5dd2b6;
}

.border-l-success-300 {
  border-left-color: #5dd2b6;
}

.border-r-success-300 {
  border-right-color: #5dd2b6;
}

.border-t-success-300 {
  border-top-color: #5dd2b6;
}

.border-b-success-300 {
  border-bottom-color: #5dd2b6;
}

.border-success-400 {
  border-color: #37c5a3;
}

.border-l-success-400 {
  border-left-color: #37c5a3;
}

.border-r-success-400 {
  border-right-color: #37c5a3;
}

.border-t-success-400 {
  border-top-color: #37c5a3;
}

.border-b-success-400 {
  border-bottom-color: #37c5a3;
}

.border-success-600 {
  border-color: #217561;
}

.border-l-success-600 {
  border-left-color: #217561;
}

.border-r-success-600 {
  border-right-color: #217561;
}

.border-t-success-600 {
  border-top-color: #217561;
}

.border-b-success-600 {
  border-bottom-color: #217561;
}

.border-success-700 {
  border-color: #164d40;
}

.border-l-success-700 {
  border-left-color: #164d40;
}

.border-r-success-700 {
  border-right-color: #164d40;
}

.border-t-success-700 {
  border-top-color: #164d40;
}

.border-b-success-700 {
  border-bottom-color: #164d40;
}

.border-success-800 {
  border-color: #0b251f;
}

.border-l-success-800 {
  border-left-color: #0b251f;
}

.border-r-success-800 {
  border-right-color: #0b251f;
}

.border-t-success-800 {
  border-top-color: #0b251f;
}

.border-b-success-800 {
  border-bottom-color: #0b251f;
}

.border-success-900 {
  border-color: black;
}

.border-l-success-900 {
  border-left-color: black;
}

.border-r-success-900 {
  border-right-color: black;
}

.border-t-success-900 {
  border-top-color: black;
}

.border-b-success-900 {
  border-bottom-color: black;
}

:host {
  display: block;
}

.select-container {
  width: 180px;
}
.interval-date-picker__submit-btn {
  height: 2.4em;
}

@media (max-width: 1020px) {
  .vertical-line {
    margin: 0 30px 0 30px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
