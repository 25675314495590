import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent implements OnInit, OnChanges {
  @Input() public accuracy = 4;

  @Input() public format = '%';

  @Input() public value = 0;

  @Input() public points = false;

  public processedValue = 0;
  public styleClass = '';

  ngOnInit() {
    this.initValue();
  }

  ngOnChanges(): void {
    this.initValue();
  }

  private initValue() {
    // Little trick to prevent display of error when loading the page (also smoothier)
    if (this.value !== undefined) {
      this.processedValue = Number(this.value.toFixed(this.accuracy));
    }
    this.styleClass = this.getProgressStyle();
  }

  private getProgressStyle() {
    if (this.processedValue < 0) {
      return 'negative-value';
    } else if (this.processedValue === 0) {
      return 'neutral-value';
    } else {
      return 'positive-value';
    }
  }
}
