import { ApplicationModules, Roles } from '@shared';

export const DEFAULT_PERMISSIONS_CONFIG = [
  {
    name: ApplicationModules.pastPerf,
    permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
    icon: 'leaderboard',
    children: [
      {
        name: ApplicationModules.focusRoi,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'monetization-on'
      },
      {
        name: ApplicationModules.focusEffectiveness,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'ads-click'
      },
      {
        name: ApplicationModules.nestedRoi,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'nested-roi'
      },
      {
        name: ApplicationModules.haloEffects,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'halo'
      },
      {
        name: ApplicationModules.bubbleChart,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'bubble_chart'
      },
      {
        name: ApplicationModules.optimalWeeklyInvestments,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'call_made'
      }
    ]
  },
  {
    name: ApplicationModules.scenarios,
    permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
    icon: 'insights',
    children: [
      {
        name: ApplicationModules.createSimulation,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'perm-data-setting'
      },
      {
        name: ApplicationModules.createOptimization,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'settings-suggest'
      },
      {
        name: ApplicationModules.scenarioManualUpload,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'excel'
      },
      {
        name: ApplicationModules.performanceBreakdownScenario,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'multiline-chart'
      },
      {
        name: ApplicationModules.costPerMetric,
        permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
        icon: 'query-stats'
      }
    ]
  },
  {
    name: ApplicationModules.gapAnalysis,
    permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
    icon: 'compare-arrows'
  },
  {
    name: ApplicationModules.reports,
    permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
    icon: 'input'
  },
  {
    name: ApplicationModules.configuration,
    permittedRoles: [Roles.admin, Roles.consultant, Roles.client, Roles.appManager],
    icon: 'settings'
  }
];
