import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { TypedSimpleChange } from '~/types/changes';

interface IDirectiveChanges extends SimpleChanges {
  icon: TypedSimpleChange<string>;
  size: TypedSimpleChange<string>;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'i.icon'
})
export class IconDirective implements OnChanges {
  @Input()
  icon?: string;

  @Input()
  size?: string;

  @Input()
  type: 'material' | 'custom' = 'material';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: IDirectiveChanges): void {
    const element = this.el.nativeElement;

    if (changes.icon) {
      const name = changes.icon.currentValue.toLowerCase().replace(/-/g, '_');
      const url = `assets/icons/${this.type}/${name}_24px.svg`;

      this.renderer.setStyle(element, '-webkit-mask-image', `url("${url}")`);
      this.renderer.setStyle(element, 'mask-image', `url("${url}")`);
    }

    if (changes.size) {
      this.renderer.setStyle(element, 'width', changes.size.currentValue);
      this.renderer.setStyle(element, 'height', changes.size.currentValue);
    }
  }
}
