import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { IDate, IDateRange, IDateTimeRange } from '~/interfaces/date.interface';
import { ICouple } from '@shared';

// TODO: unify all this s*** backend side
export function dataRangeToDateCouple(dates: IDateRange | IDateTimeRange): ICouple<IDate> {
  return [dates.from, dates.to];
}

// Ensure that the provided IDate (aka Date | DateTime) is a DateTime
export function ensureDateTime(date: IDate): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  return DateTime.fromJSDate(date);
}

// Ensure that the provided IDate (aka Date | DateTime) is a Date
export function ensureDate(date: IDate): Date {
  if (date instanceof Date) {
    return date;
  }

  return date.toJSDate();
}

export function dateToNgDate(date: string | Date): NgbDateStruct {
  date = date.toString();

  return {
    year: DateTime.fromISO(date).year,
    month: DateTime.fromISO(date).month,
    day: DateTime.fromISO(date).day
  };
}

class AppDateClass {
  public fromJsDate(date: Date = new Date()) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }
}

export function getPreviousMonth(date: Date): Date {
  const day = date.getDate();
  date.setDate(date.getDate() - day);
  return date;
}

export const AppDate = new AppDateClass();
