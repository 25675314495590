import { IAggregationStructure, IMMOModel, isNested, isPooled, MMOModelUnitType } from '@shared';
export namespace AggregationStructureBusinessLogic {
  export function isLeafAggregationStructure(aggregationStructure: IAggregationStructure): boolean {
    return !aggregationStructure.childrenIds.length;
  }

  export function getModelWithUnitType(aggregationStructure: IAggregationStructure, type: MMOModelUnitType): IMMOModel | undefined {
    return aggregationStructure.models?.find((model) => model.unitType === type);
  }

  export function hasPooledModel(aggregationStructure: IAggregationStructure, type: MMOModelUnitType): boolean {
    const model = getModelWithUnitType(aggregationStructure, type);
    return !!model && isPooled(model);
  }

  export function hasNestedModel(aggregationStructure: IAggregationStructure, type: MMOModelUnitType): boolean {
    const model = getModelWithUnitType(aggregationStructure, type);
    return !!model && isNested(model);
  }

  export function getDepth(aggregationStructure: IAggregationStructure): number {
    return aggregationStructure.ancestorsIds.length;
  }
}
