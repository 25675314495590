export enum AggregationAccess {
  fullAccess = 'fullAccess',
  standardAccess = 'standardAccess',
  noAccess = 'noAccess'
}

export enum ModelAccess {
  canEdit = 'canEdit',
  canView = 'canView',
  noAccess = 'noAccess'
}
