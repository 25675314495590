import { Injectable } from '@angular/core';
import { Endpoint, ICreateLog, ILog } from '@shared';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private api: ApiService) {}

  public createLog(log: ICreateLog): void {
    this.api.post<ILog, ICreateLog>(Endpoint.LOG, log).subscribe();
  }
}
