import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const eurostarConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Eurostar',
  moduleAccess: {
    investment: true,
    modelTypeSwitch: true,
    examples: true,
    gapAnalysis: true,
    displayAvm: false,
    configurationAsClient: true,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    turnover: 'Revenue'
  }
};
