import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '@front/environments/environment';

@Component({
  selector: 'app-headway-widget',
  templateUrl: './headway-widget.component.html',
  styleUrls: ['./headway-widget.component.scss']
})
export class HeadwayWidgetComponent implements OnInit {
  @ViewChild('widget') widget!: ElementRef;

  ngOnInit(): void {
    if (!!environment.headwayAccount) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://cdn.headwayapp.co/widget.js';
      document.head.appendChild(script);
      const config = {
        selector: '#headway-badge',
        account: environment.headwayAccount,
        trigger: '#headway-badge'
      };
      script.onload = () => {
        (window as any).Headway.init(config);
      };
    }
  }
}
