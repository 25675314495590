import { Inject, Injectable } from '@angular/core';
import { Endpoint, IConfig } from '@shared';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiService } from '@front/services/api.service';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  readonly endpoint = Endpoint.CONFIG;
  config: ReplaySubject<IConfig[]> | null = null;
  clientConfig: IClientConfig;

  constructor(readonly apiService: ApiService, @Inject(ENUMS) enums: IClientConfig) {
    this.clientConfig = enums;
  }

  getAll(queryParams: any = {}): Observable<IConfig[]> {
    return this.apiService.getAll<IConfig>(this.endpoint, queryParams);
  }

  getCurrent(wordings: string[] = this.clientConfig.aggregationWording): Observable<IConfig> {
    return this.apiService.get<IConfig>(`${this.endpoint}/current`, { wordings });
  }

  updateConfig(id: string, newConfig: Partial<IConfig>): Observable<IConfig> {
    return this.apiService.put<IConfig, Partial<IConfig>>(`${this.endpoint}/${id}`, newConfig);
  }

  clearCache(): Observable<{ success: boolean }> {
    return this.apiService.get<{ success: boolean }>(`${this.endpoint}/clear-cache`);
  }
}
