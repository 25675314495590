import { IClientConfig } from '@front/interfaces/client-config.interface';
import { MMOModelUnitType } from '@shared';
import { defaultConfig } from './default.config';

export const gskConfig: IClientConfig = {
  ...defaultConfig,
  name: 'Haleon',
  platformName: 'Platform',
  logo: 'assets/images/logo-gsk.svg',
  moduleAccess: {
    investment: true,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: false,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: true,
    scenarios: true
  },
  wording: {
    ...defaultConfig.wording,
    turnover: 'Sales Value (Local Currency)',
    brand: 'Brand',
    model: 'Model',
    volume: 'Sales Volume',
    profit: 'Sales Value (GBP)',
    'other invests': 'Masterbrand'
  },
  aggregationWording: ['Area', 'Country', 'Category', 'Brand', 'Sub-Brand'],
  GATrackingID: 'UA-124520099-11',
  modelUnitTypes: [MMOModelUnitType.turnover, MMOModelUnitType.volume, MMOModelUnitType.profit],
  variableUnits: ['EUR', 'GBP', 'JPY', 'USD', 'Volume', 'CNY', 'ZAR', 'PLN', 'SEK', 'AUD', 'CAD', 'COP'],
  powerBiSendBMC: true,
  variableMetrics: ['grp', 'clicks', 'impressions', 'sample', 'calls', 'circulations', 'views', 'reach', 'none'],
  pooledModeling: true
};
