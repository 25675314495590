import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './generic-file-upload.component.html',
  styleUrls: ['./generic-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent {
  @Input()
  public shape: string;

  @Input()
  public description: string;

  @Input()
  public name = 'file';

  @Input()
  public accept = '*/*';

  @Input()
  public buttonContent: string;

  // Two-way binding to "file"
  @Input() file: File | null;
  @Output() fileChange = new EventEmitter<File | null>();

  @Input()
  public streamChanges: boolean | 'true' = false;

  public disabled = false;
  public focused = false;

  constructor() {
    this.shape = 'normal';
    this.description = 'Information about the file : format, size...';
    this.buttonContent = 'DROP YOUR FILE HERE';
    this.file = null;
  }

  handleFileInput(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      this.file = files[0];
    }
    this.fileChange.emit(this.file);

    this.innerValue = this.file;
    this.onChange();
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: File | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  /* CVA */
  writeValue(value: File | null): void {
    if (value === null) {
      this.file = null;

      this.fileChange.emit(this.file);
      this.innerValue = this.file;

      this.onChange();
    } else {
      this.innerValue = value;
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
