import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SelectComponent } from './generic-select.component';
import { NgVarDirectiveModule } from '@front/app/modules/shared-global/directives/ng-var/ng-var-directive-module';
import { SharedIconModule } from '../../shared-icon/shared-icon.module';
import { IconDirectiveModule } from '@front/app/modules/shared-global/directives/icon/icon.directive.module';

const MODULES = [CommonModule, RouterModule, FormsModule, NgVarDirectiveModule, SharedIconModule, IconDirectiveModule];

const COMPONENTS = [SelectComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class SelectModule {}
