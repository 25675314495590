import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum SnackBarTypes {
  success = 'success',
  error = 'error',
  warning = 'warning'
}

export type SnackBar = {
  type: SnackBarTypes;
  title: string;
  message: string;
  order: number;
};

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private snackbarsSubject: BehaviorSubject<SnackBar[]> = new BehaviorSubject<SnackBar[]>([]);

  public snackBars$: Observable<SnackBar[]> = this.snackbarsSubject.asObservable();

  get snackBars(): SnackBar[] {
    return this.snackbarsSubject.value;
  }

  showSuccess(title: string, message: string, timeout: number = 3000) {
    const snackBar = this._newSnackBar(title, message, SnackBarTypes.success);
    this._showSnackBar(snackBar, timeout);
  }

  showError(title: string, message: string, timeout: number = 4000) {
    const snackBar = this._newSnackBar(title, message, SnackBarTypes.error);
    this._showSnackBar(snackBar, timeout);
  }

  showWarning(title: string, message: string, timeout: number = 3000) {
    const snackBar = this._newSnackBar(title, message, SnackBarTypes.warning);
    this._showSnackBar(snackBar, timeout);
  }

  private _newSnackBar(title: string, message: string, type: SnackBarTypes): SnackBar {
    const orderArray = this.snackBars.map((snackBar) => snackBar.order);
    const order = orderArray.length > 0 ? Math.max(...orderArray) + 1 : 0;

    return { message: message, order: order, type: type, title: title };
  }

  private _showSnackBar(snackBar: SnackBar, timeOut: number) {
    let snackBars = [...this.snackBars, snackBar];
    this.snackbarsSubject.next(snackBars);
    setTimeout(() => {
      this._removeSnackBar(snackBar);
    }, timeOut);
  }

  private _removeSnackBar(snackBarToDelete: SnackBar) {
    let snackBars = this.snackBars.filter((snackBar) => snackBar.order !== snackBarToDelete.order);
    this.snackbarsSubject.next(snackBars);
  }
}
