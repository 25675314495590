import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() height = '300px';
  @Input() hasBackground = false;
  @Input() isOngoing: boolean = false; // TODO: refactor the specific use case using properties like height, width, hasBackground, etc.
}
