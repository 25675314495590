import { BusinessObject } from './business.interface';

export enum PlatformDeploymentState {
  QUEUED = 'queued',
  ONGOING = 'ongoing',
  DONE = 'done',
  ERROR = 'error'
}

export enum PlatformDeploymentScope {
  MODELING_ENVIRONMENT = 'modelingEnvironment',
  PUBLIC_API = 'publicApi',
  DATABRICKS = 'databricks',
  ACCOUNT = 'account',
  MANUAL_UPLOAD = 'manualUpload'
}

export interface IPlatformDeployment extends BusinessObject {
  state: PlatformDeploymentState;
  createdAt: Date;
  updatedAt: Date;
  isCurrent: boolean;
  initiator: {
    email: string;
    name: string;
  };
  scope: PlatformDeploymentScope;
  scopeId?: string; // in the case of 'modelingEnvironment' scope it will be a modelingEnvironmentId
  error?: string;
}
