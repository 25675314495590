import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconDirective } from './icon.directive';

const MODULES = [CommonModule, RouterModule, FormsModule];

const COMPONENTS = [IconDirective];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS]
})
export class IconDirectiveModule {}
