import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IRouteData } from '~/interfaces/route-data.interface';
import { Title } from '@angular/platform-browser';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';

@Injectable({ providedIn: 'root' })
export class RouteDataService {
  public readonly data$: Observable<IRouteData>;

  constructor(router: Router, activatedRoute: ActivatedRoute, title: Title, @Inject(ENUMS) enums: IClientConfig) {
    this.data$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      shareReplay(1)
    );

    this.data$.subscribe((data) => {
      /*if (data.title && data.businessLine) {
        title.setTitle(`${data.businessLine.name} - ${data.title}`);
      } else*/ if (data.title) {
        if (data.title === 'Default') {
          title.setTitle(`${enums.platformName}`);
        } else {
          title.setTitle(data.title);
        }
      }
    });
  }
}
