export enum OptimizationSettingsProblemType {
  reachTarget = 'reach_target',
  optimizeBudget = 'optimize_budget'
}

export enum OptimizationObjective {
  maximizeAdditional = 'maximize_additional',
  minimizeInvestment = 'minimize_investment'
}

export enum OptimizationSettingsOptimizationType {
  crossDimension = 'cross_dimension',
  simple = 'simple'
}

export enum OptimizationSettingsMode {
  sellOut = 'sellOut',
  budget = 'budget'
}

export enum ScenarioType {
  initial = 'initial',
  manual = 'manual',
  optimized = 'optimized'
}

export enum ScenarioStatus {
  draft = 'draft',
  ongoing = 'ongoing',
  incomplete = 'incomplete',
  uploadOngoing = 'uploadOngoing',
  done = 'done',
  archived = 'archived'
}

export enum ScenarioSimulationStatus {
  draft = 'draft',
  ongoing = 'ongoing',
  done = 'done',
  error = 'error'
}

export enum ScenarioOptimizationStatus {
  ongoing = 'ongoing',
  done = 'done',
  error = 'error',
  draft = 'draft'
}

export enum CheckState {
  fullUnchecked = 'full-unchecked',
  fullChecked = 'full-checked',
  partiallyChecked = 'partially-checked'
}

export const SCENARIO_COLORS: { [key in ScenarioType]: string } = {
  initial: '#A9425B',
  manual: '#B49935',
  optimized: '#A9425B'
};

export const SCENARIO_TYPES_MAP: { [key in ScenarioType]: string } = {
  [ScenarioType.initial]: 'Initial',
  [ScenarioType.manual]: 'Simulation',
  [ScenarioType.optimized]: 'Optimization'
};

export const SCENARIO_ICONS: { [key in ScenarioType]: string } = {
  initial: 'settings_suggest',
  manual: 'perm_data_setting',
  optimized: 'settings_suggest'
};

export enum SimulationTypes {
  media = 'media',
  media_actionnables = 'media_actionnables',
  complete = 'complete',
  custom = 'custom'
}

export enum ScenarioSourceType {
  model = 'model',
  scenario = 'scenario',
  blank = 'blank'
}

export const SIMULATION_TYPES: { [key in SimulationTypes]: string } = {
  [SimulationTypes.complete]: 'Complete',
  [SimulationTypes.media]: 'Media',
  [SimulationTypes.media_actionnables]: 'Media + Actionnables',
  [SimulationTypes.custom]: 'Custom'
};

export const SCENARIO_TYPES_MAP_VERBS: { [key in ScenarioType]: string } = {
  [ScenarioType.initial]: 'Simulate',
  [ScenarioType.manual]: 'Simulate',
  [ScenarioType.optimized]: 'Optimize'
};

export enum Periods {
  // 12 months
  'YP-1' = 'YP-1',
  'Y-2' = 'Y-2',
  'Y-1' = 'Y-1',
  'Y0' = 'Y0',
  'Y+1' = 'Y+1',
  'YN+1' = 'YN+1',

  // 6 months
  'HP-3' = 'HP-3',
  'HP-1' = 'HP-1',
  'H-2' = 'H-2',
  'H-1' = 'H-1',
  'H0' = 'H0',
  'H+1' = 'H+1',
  'H+2' = 'H+2',
  'HN+1' = 'HN+1',

  // 3 months
  'QP-5' = 'QP-5',
  'QP-4' = 'QP-4',
  'QP-3' = 'QP-3',
  'QP-2' = 'QP-2',
  'QP-1' = 'QP-1',
  'Q-4' = 'Q-4',
  'Q-3' = 'Q-3',
  'Q-2' = 'Q-2',
  'Q-1' = 'Q-1',
  'Q0' = 'Q0',
  'Q+1' = 'Q+1',
  'Q+2' = 'Q+2',
  'Q+3' = 'Q+3',
  'Q+4' = 'Q+4',
  'QN+1' = 'QN+1',

  'CUSTOM' = 'CUSTOM'
}

const FORECAST_PERIODS = [
  Periods.Y0,
  Periods['Y+1'],
  Periods['YN+1'],
  Periods.H0,
  Periods['H+1'],
  Periods['H+2'],
  Periods['HN+1'],
  Periods.Q0,
  Periods['Q+1'],
  Periods['Q+2'],
  Periods['Q+3'],
  Periods['Q+4'],
  Periods['QN+1'],
  Periods['CUSTOM']
] as const;
export type ForecastPeriodsType = typeof FORECAST_PERIODS[number];

export enum ScenarioEndpoint {
  SimulationResults = 'simulation-result',
  SimulationInteropResults = 'simulation-interop-result'
}
