export enum TaskQueues {
  GenerateLegacyModel = 'generate',
  GenerateAggregatedModel = 'generateAggregatedModel',
  ImportModel = 'import',
  UpdateModel = 'update',
  PooledModel = 'pooled',
  InvestmentImportFile = 'investment import file',
  UpdatePooledFile = 'update pooled file',
  UpdatePooledModel = 'update pooled',
  InteroperabilityPublishModel = '[interoperability]PublishModel',
  SimulationSendRequest = '[simulation]SendRequest',
  SimulationComplete = '[simulation]Complete',
  OptimizationSendRequest = '[optimization]SendRequest',
  OptimizationComplete = '[optimization]Complete',
  ScenarioCreate = '[scenario]Create',
  PostEval = 'posteval',
  PreEval = 'preeval',
  Competitors = 'competitors',
  Images = 'images'
}
