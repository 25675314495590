import { IMMOModel, IMMOModelAggregationOrigin } from '../interfaces';

export function isPooled(model: IMMOModel): boolean {
  return !!model.crossSections?.length;
}

export function isNested(model: IMMOModel): boolean {
  return !!model.nestedParentsIds?.length;
}

export function isAggregated(model: IMMOModel): model is IMMOModel & { aggregationOrigins: IMMOModelAggregationOrigin[] } {
  return !!model.aggregationOrigins?.length;
}
