import { ScenarioType } from '../enums';
import { IScenario } from '../interfaces';
import { DateTime } from 'luxon';

export namespace ScenarioBusinessLogic {
  export function mustPerformOptimization(scenario: IScenario): boolean {
    return [ScenarioType.optimized].includes(scenario.type);
  }

  export function getInvestmentUpdateWindow(scenario: IScenario): { start: Date; end: Date } {
    return {
      start: scenario.startDate,
      end: scenario.endDate
    };
  }

  export function getOptimizationInvestmentWindow(scenario: IScenario): { start: Date; end: Date } {
    return {
      start: scenario.referenceStartDate ?? scenario.startDate,
      end: scenario.referenceEndDate ?? scenario.endDate
    };
  }

  export function getSimulationWindow(scenario: IScenario): { start: Date; end: Date } {
    return { start: scenario.startDate, end: scenario.endDate };
  }

  export function getLastPeriod(scenario: IScenario): { start: Date; end: Date } {
    const lastPeriodStart = DateTime.fromJSDate(scenario.startDate).minus({ year: 1 }).toJSDate();
    return { start: lastPeriodStart, end: scenario.startDate };
  }

  export function getLastOptimizationPeriod(scenario: IScenario): { start: Date; end: Date } {
    const optimizationPeriod = getInvestmentUpdateWindow(scenario);
    const lastPeriodStart = DateTime.fromJSDate(optimizationPeriod.start).minus({ year: 1 }).toJSDate();
    const lastPeriodEnd = DateTime.fromJSDate(optimizationPeriod.end).minus({ year: 1 }).toJSDate();
    return { start: lastPeriodStart, end: lastPeriodEnd };
  }

  /***
   * Computes the corresponding history period for a given simulation period
   * The current code use the assumption that the history is 2 full years and the simulation period is always 1 full year starting from the end of the history
   * TODO: Adapt the computation when the simulation and history periods will be custom
   * @param scenario
   * @param startDateWithinForecast
   * @param endDateWithinForecast
   */
  export function getCorrespondingHistoryPeriod(startDateWithinForecast: Date, endDateWithinForecast: Date): { start: Date; end: Date } {
    const subtractAYear = (date: Date): Date => DateTime.fromJSDate(date).minus({ year: 1 }).toJSDate();
    return { start: subtractAYear(startDateWithinForecast), end: subtractAYear(endDateWithinForecast) };
  }
}
