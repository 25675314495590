import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigResolver } from '@front/resolvers/config.resolver';
import { IRouteData } from '@front/interfaces/route-data.interface';
import { AuthGuard } from '@front/services/guards/auth.guard';
import { CanLoadPromo } from './modules/promo/guards/canLoadPromo';
import { CanLoadDebugToolsGuard } from './modules/debug-tools/guards/can-load-debug-tools.guard';
import { AdminAccessGuard } from '@front/services/guards/access.guard';
import { PermissionGuard } from '@front/services/guards/permission.guard';
import { ApplicationModules } from '@shared';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        data: { displayMainNav: false, displayLeftNav: false } as IRouteData,
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'home/:id',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: '',
        data: { displayMainNav: true, displayLeftNav: false } as IRouteData,
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'promo',
        loadChildren: () => import('./modules/promo/promo.module').then((m) => m.PromoModule),
        canLoad: [CanLoadPromo],
        canActivate: [AuthGuard]
      },
      {
        path: 'examples',
        loadChildren: () => import('./modules/examples/examples.module').then((m) => m.ExamplesModule),
        canActivate: [AdminAccessGuard]
      },
      {
        path: 'debug/:id',
        loadChildren: () => import('./modules/debug-tools/debug-tools.module').then((m) => m.DebugToolsModule),
        canActivate: [AuthGuard],
        canLoad: [CanLoadDebugToolsGuard]
      },
      {
        path: 'reports/:id',
        data: { title: ApplicationModules.reports },
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuard, PermissionGuard]
      },
      {
        path: 'configuration',
        data: { displayMainNav: false, displayLeftNav: true, title: ApplicationModules.configuration } as IRouteData,
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule),
        canActivate: [AuthGuard, PermissionGuard]
      },
      {
        path: 'help',
        loadChildren: () => import('./modules/help/help.module').then((m) => m.HelpModule),
        canActivate: [AuthGuard]
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
  providers: [AuthGuard, CanLoadDebugToolsGuard, ConfigResolver]
})
export class AppRoutingModule {}
