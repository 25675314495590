import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { AggregationStructureService } from '@front/services/api/aggregation-structure.service';
import { AppStateService } from '@front/services/state/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService implements OnDestroy {
  private subs = new SubSink();

  constructor(private aggregationStructureService: AggregationStructureService, private appState: AppStateService) {}

  reset(): void {
    this.appState.resetAggregationStructures();
    this.appState.resetSelectedAggregationStructure();
    this.appState.resetScenarios();
    this.appState.resetSelectedScenario();
    this.aggregationStructureService.resetRootAggregationStructures();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
