export function roundToPrecision(value: number, precision = 0): number {
  const factor = Math.pow(10, precision);
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function max(values: number[]): number {
  return Math.max(...values);
}

export function min(values: number[]): number {
  return Math.min.apply(null, values);
}

export function sum(values: number[]): number {
  return values.reduce((a, b) => a + b, 0);
}

export function computeROI(contribution: number, investment: number): number | null {
  return investment && contribution ? contribution / investment : null;
}

export function computeProgress(pastValue: number, foreCastValue: number): number | null {
  if (!pastValue) {
    return null;
  }
  return (foreCastValue - pastValue) / Math.abs(pastValue);
}

export function computeProgressInPoints(pastValue: number, foreCastValue: number): number | null {
  if (!pastValue) {
    return null;
  }
  return foreCastValue - pastValue;
}
