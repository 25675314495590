import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IToggleOption } from '~/interfaces/generic-toggle-interface';

@Component({
  selector: 'app-toggle',
  templateUrl: './generic-toggle.component.html',
  styleUrls: ['./generic-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ]
})
export class ToggleComponent implements ControlValueAccessor {
  @Output() public currentToggleEvent = new EventEmitter<string>();

  @Input() public currentToggle = 0;

  @Input() public buttonNames: IToggleOption[] = [];

  @Input() public streamChanges: boolean | 'true' = false;

  @Input() public disabled = false;

  @Input() public disabledValue: string = '';

  @Input() fullWidth = true;

  @Input() id?: string;

  @Input() isSpread: boolean = false;

  public focused = false;

  onClickToggle(index: number): void {
    if (index !== this.currentToggle && !this.disabled && this.buttonNames[index].value !== this.disabledValue) {
      this.currentToggle = index;
      this.currentToggleEvent.emit(this.buttonNames[index].value);

      this.innerValue = this.buttonNames[this.currentToggle].value;
      this.onChange();
    }
  }

  // eslint-disable-next-line
  /* CVA */ innerValue: string | null = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  public isDisabled(optionValue: string): boolean {
    if (this.disabledValue !== '') {
      return this.disabledValue === optionValue;
    }
    return this.disabled;
  }

  /* CVA */
  writeValue(value: string): void {
    if (typeof value === 'string') {
      this.innerValue = value;
      const newIndex = this.buttonNames.findIndex((e) => e.value === value);
      if (newIndex !== -1) {
        this.onClickToggle(newIndex);
      } else {
        console.warn('Unexpected value', value);
      }
    } else if (value === null || value === undefined) {
      this.innerValue = null;
      this.currentToggle = -1;
    } else {
      console.warn('Unexpected input', value);
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
