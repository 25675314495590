import { IClientConfig } from '@front/interfaces/client-config.interface';
import { defaultConfig } from './default.config';

export const sandboxConfig: IClientConfig = {
  ...defaultConfig,
  moduleAccess: {
    investment: true,
    modelTypeSwitch: true,
    examples: false,
    gapAnalysis: true,
    displayAvm: false,
    configurationAsClient: false,
    digitalFocus: false,
    effectiveness: true,
    channelPerformance: false,
    scenarios: true
  }
};
