import { IApiDataConverter } from '@front/services/api-data-converters/api-data-converter.interface';

export class DateApiDataConverter implements IApiDataConverter<string, Date> {
  fromApi(apiData: string): Date {
    return new Date(apiData);
  }

  toApi(bo: Date): string {
    return bo.toString();
  }
}
