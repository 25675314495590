import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ENUMS } from '@front/app/configs/configs-injector/configs-injector.module';
import { IClientConfig } from '@front/interfaces/client-config.interface';
import { Roles } from '@shared';
import { AuthService } from '@front/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessGuard {
  clientConfig: IClientConfig;
  constructor(private router: Router, @Inject(ENUMS) enums: IClientConfig, private readonly authService: AuthService) {
    this.clientConfig = enums;
  }

  canActivate(): boolean {
    const currentUserRole = this.authService.getCurrentUser().role;
    if (currentUserRole === Roles.admin || currentUserRole === Roles.appManager) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
