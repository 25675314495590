import { Injectable } from '@angular/core';
import { NotificationApiService } from '@front/services/api/notification.api.service';
import { AuthService } from '@front/services/auth.service';
import { INotification } from '@shared';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  interval = interval(10000);
  pooler$: Subscription | null = null;

  constructor(private notificationApiService: NotificationApiService, private authService: AuthService) {}

  private notificationsSubject: BehaviorSubject<INotification[]> = new BehaviorSubject<INotification[]>([]);

  public notifications$: Observable<INotification[]> = this.notificationsSubject.asObservable();

  get notifications(): INotification[] {
    return this.notificationsSubject.value;
  }

  get unreadNotificationsCount(): number {
    return this.notifications.filter((notification) => !notification.isRead).length;
  }

  public updateNotifications() {
    if (this.authService.isAuthenticated()) {
      this.notificationApiService.getAll().subscribe((notifications) => {
        this.notificationsSubject.next(notifications);
      });
    } else {
      this.stopNotificationAutomaticPooler();
    }
  }

  public addNotification(notification: INotification) {
    this.notificationApiService.create(notification).subscribe(() => {
      this.updateNotifications();
    });
  }

  public readNotification(notification: INotification) {
    notification.isRead = true;
    this.notificationApiService.update(notification.id, notification).subscribe(() => {
      this.updateNotifications();
    });
  }

  public launchNotificationAutomaticPooler() {
    this.updateNotifications();
    this.pooler$ = this.interval.subscribe(() => {
      this.updateNotifications();
    });
  }

  public stopNotificationAutomaticPooler() {
    this.pooler$?.unsubscribe();
  }
}
