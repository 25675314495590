import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRadioOption } from '@front/interfaces/generic-radio.interface';

@Component({
  selector: 'app-radio',
  templateUrl: './generic-radio.component.html',
  styleUrls: ['./generic-radio.component.scss']
})
export class RadioComponent {
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
  @Input() public options: IRadioOption[] = [];
  @Input() public checkedButton?: string;
  @Output() clicked = new EventEmitter<IRadioOption>();

  onClick(event: IRadioOption) {
    this.clicked.emit(event);
  }
}
