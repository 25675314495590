import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './generic-textarea.component.html',
  styleUrls: ['./generic-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor {
  @Input()
  public label = '';

  @Input()
  public optionalText = '';

  @Input()
  public placeholder = '';

  @Input()
  public streamChanges: boolean | 'true' = false;

  @Input()
  public minlength = '0';

  @Input()
  public maxlength = '8192';

  @Input()
  public resize = false;

  public disabled = false;
  public focused = false;

  /* CVA */ innerValue: string | number | null = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateChange: (...args: any[]) => any = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  /* CVA */ propagateTouched: (...args: any[]) => any = () => undefined;

  public onChange(): void {
    this.propagateChange(this.innerValue);
  }

  public onKeyUp(): void {
    if (this.streamChanges === true || this.streamChanges === 'true') {
      this.propagateChange(this.innerValue);
    }
  }

  public onFocus(): void {
    this.focused = true;
  }

  public onBlur(): void {
    this.focused = false;
    this.propagateTouched();
  }

  /* CVA */
  writeValue(value: string): void {
    if (typeof value === 'string') {
      this.innerValue = value;
    }
    //
    else if (value === null) {
      this.innerValue = null;
    }
    //
    else {
      console.warn('Unexpected input', value);
    }
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnChange(fn: any): void {
    this.propagateChange = fn;
  } // eslint-disable-next-line @typescript-eslint/no-explicit-any

  /* CVA */ registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  /* CVA */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
