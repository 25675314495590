import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

// https://stackoverflow.com/a/43172992/4111143

/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngVar]' })
export class VarDirective {
  @Input()
  set ngVar(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  context: any = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  private updateView(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
