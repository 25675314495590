import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SnackBar, SnackbarService } from '@front/app/modules/shared-global/snackbar/snackbar.service';
import { Observable } from 'rxjs';
import { fadeInAnimation } from '~/app/modules/shared/animations/fade-in.animation';
import { fadeOutAnimation } from '~/app/modules/shared/animations/fade-out.animation';

@Component({
  selector: 'app-snackbar-container',
  templateUrl: './snackbar-container.component.html',
  styleUrls: ['./snackbar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation('500ms'), fadeOutAnimation('500ms')]
})
export class SnackbarContainerComponent implements OnInit {
  public snackBars$: Observable<SnackBar[] | null>;

  constructor(private snackBarService: SnackbarService) {
    this.snackBars$ = this.snackBarService.snackBars$;
  }

  ngOnInit(): void {}
}
