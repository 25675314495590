import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@front/services/api/user.service';
import { IUser } from '@shared';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-tally-widget',
  templateUrl: './tally-widget.component.html',
  styleUrls: ['./tally-widget.component.scss']
})
export class TallyWidgetComponent implements OnInit {
  @Input() formId: string | undefined = '';
  @Input() width: number | undefined = 500;
  @Input() user!: IUser;

  constructor(private readonly userService: UserService) {}

  /**
   * You have a variable in base
   * User Collection -> answeredPoll (boolean)
   * True if the user submitted his answer, else False
   *
   * To pause or remove the tally poll from the app
   *  you can remove tallyConfig from the database Configs Collection
   *  or simply remove the formId
   */

  ngOnInit(): void {
    const Tally = (window as any).Tally;
    Tally.openPopup(this.formId, {
      width: this.width,
      emoji: {
        text: '👋',
        animation: 'wave'
      },
      onSubmit: () => {
        this.userService
          .getById(this.user.id)
          .pipe(switchMap((user) => this.userService.update(user.id, { ...user, answeredPoll: true })))
          .subscribe(() => {
            Tally.closePopup(this.formId);
          });
      }
    });
  }
}
