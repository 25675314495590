import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '@front/services/token.service';
import { catchError } from 'rxjs/operators';
import { HttpStatusCode } from '@shared';
import { SnackbarService } from '~/app/modules/shared-global/snackbar/snackbar.service';
import { AuthService } from '@front/services/auth.service';

/**
 * This interceptor retrieves the token from local Storage and add it to every request.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private snackbarService: SnackbarService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();

    if (token) {
      const headers = request.headers.set('Authorization', 'Bearer ' + token);

      request = request.clone({ headers });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === HttpStatusCode.UNAUTHORIZED) {
          if (this.authService.isAuthenticated()) {
            this.snackbarService.showError('Access Denied', 'You will be disconnected...');
            this.authService.disconnect();
          } else {
            this.snackbarService.showError('Access Denied', 'Wrong credentials');
          }
        }
        return throwError(err);
      })
    );
  }
}
